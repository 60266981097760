import { query } from './query';
import {
  About_PageBody,
  Blog_Navigation,
  Careers_PageBody,
  Emergency_Message,
  First_Home_HomepageBody,
  First_Home_StateBody,
  Header,
  HomepageBody,
  Important_Document_Page,
  Important_Document_PageBody,
  Important_Documents,
  Important_DocumentsBody,
  Learn_Categories_Page,
  Learn_Homepage,
  News_Article,
  News_ArticleBody,
  News_HomepageBody,
  Nova_HomepageBody,
  Privacy_PolicyBody,
  Super_First_Home_SaverBody,
  Super_HomepageBody,
  Super_PortfolioBody,
  Voyager_Boosts_PageBody,
  Voyager_HomepageBody,
  Voyager_PortfolioBody,
  World_Map,
} from './types';

interface NodeResult<T> {
  edges: Array<{ node: T }>;
}

interface NodeBodyResult<T> {
  edges: Array<{ node: { body: Array<T> } }>;
}

interface NodeMetaUIDResult {
  edges: Array<{ node: { _meta: { uid: string } } }>;
}

const getFirstNode = <T>(result: NodeResult<T>): T | undefined =>
  result.edges.slice(0, 1).pop()?.node;

const getFirstNodeBody = <T>(result: NodeBodyResult<T>): T[] | undefined =>
  getFirstNode(result)?.body;

const getAllNodes = <T>(result: NodeResult<T>): T[] | undefined =>
  result.edges.map((edge) => edge.node);

const getAllUIDs = (result: NodeMetaUIDResult): string[] | undefined =>
  result.edges?.map((edge) => edge.node?._meta?.uid);

type FunctionsConstraint = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [__typename: string]: (slice: any) => any;
};
type SliceConstraint<TypeName extends string> = { __typename?: TypeName };
type Result<Functions extends FunctionsConstraint> = {
  [__typename in keyof Functions]?: ReturnType<Functions[__typename]>;
};

export const mapSlicesToProps = <
  TypeName extends string,
  Slice extends SliceConstraint<TypeName>,
  Functions extends FunctionsConstraint,
>(
  slices: Slice[],
  functions: Functions,
): Result<Functions> => {
  const props: Result<Functions> = {};

  for (const slice of slices) {
    const typeName = slice.__typename;

    const fn = typeName && functions[typeName];
    if (typeName && fn) {
      props[typeName] = fn(slice);
    }
  }

  return props;
};

const metadataFields = `
  __typename
  primary {
    title
    description
    og_image {
      ... on _ImageLink {
        url
      }
    }
    og_title
    og_description
    twitter_image {
      ... on _ImageLink {
        url
      }
    }
    twitter_title
    twitter_description
    robots
  }
`;

export const getHeader = async (): Promise<Header> => {
  const response = await query(`{
    allHeaders(id:"ZPkUqREAACQAntmA") {
      edges {
        node {
          logintext
          loginurl
          loginopennewtab
          signuptext
          signupurl
          signupopennewtab
          body {
            ... on HeaderBodyNavigation_item {
              primary {
                text
                url
                opennewtab
              }
              fields {
                icon
                text
                url
                opennewtab
                richdescription
              }
            }
          }
        }
      }
    }
  }`);
  const node = getFirstNode<Header>(response.allHeaders);

  if (!node) {
    throw new Error('Failed to get header');
  }

  return node;
};

export const getHomePage = async (): Promise<HomepageBody[]> => {
  const response = await query(`{
    allHomepages(id: "XpkjIxEAACQAhJP2") {
      edges {
        node {
          body {
            ... on HomepageBodyMetadata {
              ${metadataFields}
            }
            ... on HomepageBodyHero {
              __typename
              primary {
                title
                description
              }
            }
            ... on HomepageBodyFeatures {
              __typename
              fields {
                text
                icon
              }
            }
            ... on HomepageBodyProducts {
              __typename
              fields {
                name
                title
                richdescription
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
              }
            }
          	... on HomepageBodyReviews {
              __typename
              fields {
                name
                date
                review
              }
            }
            ... on HomepageBodyLearn {
              __typename
              primary {
                title
                description
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<HomepageBody>(response.allHomepages);

  if (!body) {
    throw new Error('Failed to get homepage');
  }
  return body;
};

export const getEmergencyMessage = async (): Promise<
  Emergency_Message | undefined
> => {
  const response = await query(`{
    allEmergency_messages (where: {
      active: true
    }) {
      edges {
        node {
          content
        }
      }
    }
  }`);
  const firstNode = getFirstNode<Emergency_Message>(
    response.allEmergency_messages,
  );

  if (!firstNode) {
    return undefined;
  }

  return firstNode;
};

export const getLearnHomepage = async (): Promise<Learn_Homepage> => {
  const response = await query(`{
    allLearn_homepages {
      edges {
        node {
          title
          description
          body {
            ... on Learn_homepageBodyMetadata {
              ${metadataFields}
            }
          }
        }
      }
    }
  }`);

  const firstNode = getFirstNode<Learn_Homepage>(response.allLearn_homepages);

  if (!firstNode) {
    throw new Error('Failed to get learn homepage data');
  }

  return firstNode;
};

export const getLearnCategoriesPage =
  async (): Promise<Learn_Categories_Page> => {
    const response = await query(`{
    allLearn_categories_pages(id: "XsHLOBEAAPql7RLq") {
      edges {
        node {
          title
          body {
            ... on Learn_categories_pageBodyMetadata {
              ${metadataFields}
            }
          }
        }
      }
    }
  }`);

    const firstNode = getFirstNode<Learn_Categories_Page>(
      response.allLearn_categories_pages,
    );

    if (!firstNode) {
      throw new Error('Failed to get learn categories page data');
    }

    return firstNode;
  };

export const getBlogNavigation = async (): Promise<Blog_Navigation> => {
  const response = await query(`{
    allBlog_navigations(id: "Xq-1vRAAACQAh5_2") {
      edges {
        node {
          items {
            title
            slug
          }
        }
      }
    }
  }`);

  const firstNode = getFirstNode<Blog_Navigation>(response.allBlog_navigations);

  if (!firstNode) {
    throw new Error('Failed to get blog navigation data');
  }

  return firstNode;
};

export const getVoyagerHomepage = async (): Promise<Voyager_HomepageBody[]> => {
  const response = await query(`{
    allVoyager_homepages(id: "XtWl9hEAACsAaHu9") {
      edges {
        node {
          body {
            ... on Voyager_homepageBodyMetadata {
              ${metadataFields}
            }
            ... on Voyager_homepageBodyHero {
              __typename
              primary {
                product
                title
                richtagline
              }
            }
            ... on Voyager_homepageBodyJoin1 {
              __typename
              primary {
                title
                tagline
              }
              fields {
                description
              }
            }
            ... on Voyager_homepageBodyPortfolios {
              __typename
              primary {
                title
                tagline
              }
              fields {
                type
                description
              }
            }
            ... on Voyager_homepageBodyFees {
              __typename
              primary {
                title
                richtagline
                fee_table_title
                richdisclaimer
              }
              fields {
                fee_type
                fee_description
                universe
                origin
                earth
              }
            }
            ... on Voyager_homepageBodyPerformance {
              __typename
              primary {
                title
                richdisclaimer
              }
              fields {
                type
              }
            }
            ... on Voyager_homepageBodyFeatures {
              __typename
              fields {
                type
                title
                tagline
              }
            }
            ... on Voyager_homepageBodyPotential_outcome {
              __typename
              primary {
                title
                tagline
                calculator_title
                calculator_disclaimer
                extended_calculator_disclaimer
              }
            }
            ... on Voyager_homepageBodyJoin2 {
              __typename
              primary {
                title
                tagline
              }
            }
            ... on Voyager_homepageBodyRisks {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
              fields {
                title
                description
                icon_img
              }
            }
            ... on Voyager_homepageBodyImportant_documents {
              __typename
              primary {
                title
                tagline
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Voyager_HomepageBody>(
    response.allVoyager_homepages,
  );

  if (!body) {
    throw new Error('Failed to get voyager homepage');
  }
  return body;
};

export const getSuperHomepage = async (): Promise<Super_HomepageBody[]> => {
  const response = await query(`{
    allSuper_homepages(id: "Xs8qIRIAACQAYTae") {
      edges {
        node {
          body {
            ... on Super_homepageBodyMetadata {
              ${metadataFields}
            }
            ... on Super_homepageBodyHero {
              __typename
              primary {
                title
                richtagline
                product
              }
            }
            ... on Super_homepageBodyJoin1 {
              __typename
              primary {
                title
                tagline
              }
              fields {
                step
                stepIcon
              }
            }
            ... on Super_homepageBodyRating {
              __typename
              primary {
                title
                description
                image
              }
            }
            ... on Super_homepageBodyPortfolios {
              __typename
              type
              primary {
                title
                tagline
              }
              fields {
                type
                description
              }
            }
            ... on Super_homepageBodyGrowthx_asset_allocation {
              __typename
              type
              primary {
                title
              }
              fields {
                name
                percentage
              }
            }
            ... on Super_homepageBodyGlobal_index_asset_allocation {
              __typename
              type
              primary {
                title
              }
              fields {
                name
                percentage
              }
            }
            ... on Super_homepageBodyFees {
              __typename
              primary {
                title
                richdisclaimer
                admin_fee_waiver_copy
              }
              fields {
                fee_type
                fee_description
                growthx_portfolio_fee
                growthx_portfolio_fee_note
                index_portfolio_fee
                index_portfolio_fee_note
              }
            }
            ... on Super_homepageBodyPerformance {
              __typename
              primary {
                title
                richdisclaimer
              }
              fields {
                type
              }
            }
            ... on Super_homepageBodyBenefits {
              __typename
              fields {
                type
                title
                tagline
              }
            }
            ... on Super_homepageBodyImportant_documents {
              __typename
              primary {
                title
                summary
              }
            }
            ... on Super_homepageBodySupersignup {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Super_HomepageBody>(
    response.allSuper_homepages,
  );

  if (!body) {
    throw new Error('Failed to get super homepage');
  }
  return body;
};

export const getNovaHomepage = async (): Promise<Nova_HomepageBody[]> => {
  const response = await query(`{
    allNova_homepages(id: "ZO7MHREAACQAcgy4") {
      edges {
        node {
          body {
            ... on Nova_homepageBodyMetadata {
              ${metadataFields}
            }
            ... on Nova_homepageBodyHero {
              __typename
              primary {
                product
                title
                richtagline
                richfootnote
                tag
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
              }
            }
            ... on Nova_homepageBodyIntroduction {
              __typename
              primary {
                title
                richtagline
                video
              }
            }
            ... on Nova_homepageBodyExplore {
              __typename
              primary {
                title
                richtagline
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
              }
              fields {
                richdescription
              }
            }
            ... on Nova_homepageBodyInvestment_options {
              __typename
              primary {
                title
                richtagline
              }
              fields {
                type
                richdescription
              }
            }
            ... on Nova_homepageBodyFees {
              __typename
              primary {
                title
                richtagline
                richdisclaimer
              }
            }
            ... on Nova_homepageBodyFeatures {
              __typename
              fields {
                feature
                title
                richtagline
              }
            }
            ... on Nova_homepageBodyRisks {
              __typename
              primary {
                title
                richtagline
                richdisclaimer
              }
              fields {
                title
                richdescription
                iconimg
              }
            }
            ... on Nova_homepageBodyImportant_documents {
              __typename
              primary {
                title
                richtagline
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Nova_HomepageBody>(response.allNova_homepages);

  if (!body) {
    throw new Error('Failed to get nova homepage');
  }
  return body;
};

export const getSuperFirstHomeSavers = async (): Promise<
  Super_First_Home_SaverBody[]
> => {
  const response = await query(`{
    allSuper_first_home_savers(id: "Y3r8WxAAACMAoP-H") {
      edges {
        node {
          body {
            ... on Super_first_home_saverBodyMetadata {
              ${metadataFields}
            }
            ... on Super_first_home_saverBodyHero {
              __typename
              primary {
                title
                richtagline
                product
              }
            }
            ... on Super_first_home_saverBodyValue_propositions {
              __typename
              type
              primary {
                title
                tagline
              }
              fields {
                type
                name
                title
                description
              }
            }
            ... on Super_first_home_saverBodyApps_download {
              __typename
              primary {
                title
              }
            }
            ... on Super_first_home_saverBodyLearn {
              __typename
              primary {
                title
                description
              }
            }
            ... on Super_first_home_saverBodyFaq {
              __typename
              type
              primary {
                title
                summary
              }
              fields {
                question
                answer
              }
            }
            ... on Super_first_home_saverBodyImportant_documents {
              __typename
              primary {
                title
                summary
              }
            }
            ... on Super_first_home_saverBodySign_up {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Super_First_Home_SaverBody>(
    response.allSuper_first_home_savers,
  );

  if (!body) {
    throw new Error('Failed to get super first home saver');
  }
  return body;
};

export const getWorldMap = async (): Promise<World_Map> => {
  const response = await query(`{
    allWorld_maps{
      edges {
        node {
          countries {
            key
            display_name
            x
            y
          }
        }
      }
    }
  }`);

  const firstNode = getFirstNode<World_Map>(response.allWorld_maps);

  if (!firstNode) {
    throw new Error('Failed to get world map data');
  }

  return firstNode;
};

export const getVoyagerPortfolio = async (
  portfolioId: string,
): Promise<Voyager_PortfolioBody[]> => {
  const response = await query(`{
    allVoyager_portfolios(id: "${portfolioId}") {
      edges {
        node {
          body {
            ... on Voyager_portfolioBodyMetadata {
              ${metadataFields}
            }
            ... on Voyager_portfolioBodyHero {
              __typename
              primary {
                title
                product
                tagline
              }
            }
            ... on Voyager_portfolioBodyReturns {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
            }
            ... on Voyager_portfolioBodyDescription {
              __typename
              primary {
                richtitle
                richtagline
              }
            }
            ... on Voyager_portfolioBodyExcluded_companies {
              __typename
              primary {
                title
                richtagline
                richdisclaimer
              }
              fields {
                icon
                exclude_description
              }
            }
            ... on Voyager_portfolioBodyHave_your_say {
              __typename
              primary {
                title
                richtagline
              }
            }
            ... on Voyager_portfolioBodyCompanylocations {
              __typename
              primary {
                title
                disclaimer
              }
              fields {
                country_key
                percentage
              }
            }
            ... on Voyager_portfolioBodyCompany_prices {
              __typename
              primary {
                title
                disclaimer
              }
            }
            ... on Voyager_portfolioBodyFees {
              __typename
              primary {
                title
                richtagline
                fee_table_title
                richdisclaimer
              }
              fields {
                fee_type
                fee_description
                fee_cost
              }
            }
            ... on Voyager_portfolioBodyRisks {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
              fields {
                title
                description
                icon_img
              }
            }
            ... on Voyager_portfolioBodyImportant_documents {
              __typename
              primary {
                title
                summary
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Voyager_PortfolioBody>(
    response.allVoyager_portfolios,
  );

  if (!body) {
    throw new Error('Failed to get voyager portfolio data');
  }

  return body;
};

export const getSuperPortfolio = async (
  portfolioId: string,
): Promise<Super_PortfolioBody[]> => {
  const response = await query(`{
    allSuper_portfolios(id: "${portfolioId}") {
      edges {
        node {
          body {
            ... on Super_portfolioBodyMetadata {
              ${metadataFields}
            }
            ... on Super_portfolioBodyHero {
              __typename
              primary {
                product
                title
                tagline
              }
            }
            ... on Super_portfolioBodyReturns {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
            }
            ... on Super_portfolioBodyWhere {
              __typename
              primary {
                title
                sector_title
                asset_title
                disclaimer
              }
              fields {
                name
                description
                percentage
              }
            }
            ... on Super_portfolioBodyFees {
              __typename
              primary {
                title
                fee_table_title
                example_title
                example_subtitle
                richdisclaimer
                rich_example
              }
              fields {
                fee_type
                fee
                fee_note
                third_party_fee_note
                example
              }
            }
            ... on Super_portfolioBodyRisks {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
              fields {
                title
                description
              }
            }
            ... on Super_portfolioBodyImportant_documents {
              __typename
              primary {
                title
                summary
              }
            }
            ... on Super_portfolioBodySupersignup {
              __typename
              primary {
                title
                tagline
                richdisclaimer
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Super_PortfolioBody>(
    response.allSuper_portfolios,
  );

  if (!body) {
    throw new Error('Failed to get super portfolio data');
  }

  return body;
};

export const getAboutPage = async (): Promise<About_PageBody[]> => {
  const response = await query(`{
    allAbout_pages(id: "XuCMMRIAAB4AfcMS") {
      edges {
        node {
          body {
            ... on About_pageBodyMetadata {
              ${metadataFields}
            }
            ... on About_pageBodyHero {
              __typename
              primary {
                title
                welcome
              }
            }
            ... on About_pageBodyCeo_bio {
              __typename
              primary {
                title
                tagline
                profile_picture
              }
            }
            ... on About_pageBodyValues {
              __typename
              primary {
                title
              }
              fields {
                title
                tagline
                icon
                summary
              }
            }
            ... on About_pageBodyInvestors {
              __typename
              primary {
                 title
              }
              fields {
                logo
                investor_name
              }
            }
            ... on About_pageBodyHiring {
              __typename
              primary {
                title
                tagline
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<About_PageBody>(response.allAbout_pages);

  if (!body) {
    throw new Error('Failed to get super portfolio data');
  }

  return body;
};

export const getPrivacyUIDs = async (): Promise<string[]> => {
  const response = await query(`{
    allPrivacy_policys {
      edges {
        node {
          _meta {
            uid
          }
        }
      }
    }
  }`);

  const uids = getAllUIDs(response.allPrivacy_policys);

  if (!uids || !uids.length) {
    throw new Error("Failed to get privacy pages' uids");
  }

  return uids;
};

export const getPrivacyPolicy = async (
  slug: string,
): Promise<Privacy_PolicyBody[]> => {
  const response = await query(`{
    allPrivacy_policys(uid: "${slug}") {
      edges {
        node {
          body {
            ... on Privacy_policyBodyMetadata {
              ${metadataFields}
            }
            ... on Privacy_policyBodyPage {
              __typename
              primary {
                title
                content
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Privacy_PolicyBody>(
    response.allPrivacy_policys,
  );

  if (!body) {
    throw new Error('Failed to get privacy policy page');
  }
  return body;
};

export const getNewsHomepage = async (): Promise<News_HomepageBody[]> => {
  const response = await query(`{
    allNews_homepages(id: "XuL1_xIAACMAh8lp") {
      edges {
        node {
          body {
            ... on News_homepageBodyMetadata {
              ${metadataFields}
            }
            ... on News_homepageBodyHero {
              __typename
              primary {
                title
                richtagline
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<News_HomepageBody>(response.allNews_homepages);

  if (!body) {
    throw new Error('Failed to get news homepage');
  }
  return body;
};

export const getNewsArticleSlugs = async (): Promise<string[]> => {
  const response = await query(`{
    allNews_articles(first: 1000) {
      edges {
        node {
          _meta {
            uid
          }
        }
      }
    }
  }`);

  const slugs = getAllUIDs(response.allNews_articles);

  if (!slugs || !slugs.length) {
    throw new Error('Failed to get news article slugs');
  }

  return slugs;
};

export interface GetNewsArticlesResponse {
  nodes: News_Article[];
  lastCursor: string;
  hasNextPage: boolean;
}

export const getNewsArticles = async ({
  limit,
  afterCursor,
}: {
  limit: number;
  afterCursor?: string;
}): Promise<GetNewsArticlesResponse> => {
  const afterParam = afterCursor ? `after: "${afterCursor}"` : '';
  const response = await query(`{
    allNews_articles(sortBy: published_date_DESC, first: ${limit}, ${afterParam}) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          _meta {
            uid
          }
          title
          published_date
        }
        cursor
      }
    }
  }`);

  const articles = response.allNews_articles;
  const nodes = getAllNodes<News_Article>(articles);
  const lastCursor = articles.edges.slice(-1)[0].cursor;
  const hasNextPage = articles.pageInfo.hasNextPage;

  if (!nodes || !lastCursor) {
    throw new Error('Failed to get news articles');
  }

  return {
    nodes,
    lastCursor,
    hasNextPage,
  };
};

export interface GetNewsArticleResponse
  extends Omit<News_Article, 'body' | '_meta'> {
  body: News_ArticleBody[];
}

export const getNewsArticle = async (
  articleSlug: string,
): Promise<GetNewsArticleResponse> => {
  const response = await query(`{
    allNews_articles(uid: "${articleSlug}") {
      edges {
        node {
          title
          published_date
          pdf {
            ... on _FileLink {
              url
            }
          }
          content
          footnotes
          body {
            ... on News_articleBodyMetadata {
              ${metadataFields}
            }
          }
        }
      }
    }
  }`);

  const firstNode = getFirstNode<News_Article>(response.allNews_articles);
  const body = getFirstNodeBody<News_ArticleBody>(response.allNews_articles);

  if (
    !body ||
    !firstNode?.title ||
    !firstNode?.published_date ||
    !firstNode?.content
  ) {
    throw new Error('Failed to get news article page');
  }
  return {
    body,
    title: firstNode.title,
    published_date: firstNode.published_date,
    pdf: firstNode.pdf,
    content: firstNode.content,
    footnotes: firstNode.footnotes,
  };
};

export const getImportantDocuments = async (): Promise<{
  title: string;
  voyagerSubsectionTitle: string;
  superSubsectionTitle: string;
  novaSubsectionTitle: string;
  body: Important_DocumentsBody[];
}> => {
  const response = await query(`{
    allImportant_documentss {
      edges {
        node {
          title
          voyager_subsection_title
          super_subsection_title
          nova_subsection_title
          body {
            ... on Important_documentsBodyMetadata {
              ${metadataFields}
            }
          }
        }
      }
    }
  }`);

  const node = getFirstNode<Important_Documents>(
    response.allImportant_documentss,
  );

  const body = getFirstNodeBody<Important_DocumentsBody>(
    response.allImportant_documentss,
  );

  if (!node?.title || !body) {
    throw new Error('Failed to get important documents data');
  }

  return {
    title: node.title,
    voyagerSubsectionTitle: node.voyager_subsection_title || '',
    superSubsectionTitle: node.super_subsection_title || '',
    novaSubsectionTitle: node.nova_subsection_title || '',
    body,
  };
};

export const getImportantDocumentPages = async (): Promise<
  Important_Document_Page[]
> => {
  const response = await query(`{
    allImportant_document_pages(sortBy: meta_firstPublicationDate_DESC) {
      edges {
        node {
          _meta {
            uid
          }
          product
          title
        }
      }
    }
  }`);

  const nodes = getAllNodes<Important_Document_Page>(
    response.allImportant_document_pages,
  );

  if (!nodes) {
    throw new Error('Failed to get important document pages');
  }

  return nodes;
};

export const getImportantDocumentPage = async (
  pageSlug: string,
): Promise<{
  title: string;
  publishedDate?: string;
  content: string;
  body: Important_Document_PageBody[];
}> => {
  const response = await query(`{
    allImportant_document_pages(uid: "${pageSlug}") {
      edges {
        node {
          title
          published_date
          content
          body {
            ... on Important_document_pageBodyMetadata {
              ${metadataFields}
            }
          }
        }
      }
    }
  }`);

  const node = getFirstNode<Important_Document_Page>(
    response.allImportant_document_pages,
  );

  const body = getFirstNodeBody<Important_Document_PageBody>(
    response.allImportant_document_pages,
  );

  if (!body || !node?.title || !node?.content) {
    throw new Error('Failed to get important document page');
  }

  return {
    title: node.title,
    publishedDate: node.published_date,
    content: node.content,
    body,
  };
};

export const getCareersPage = async (): Promise<Careers_PageBody[]> => {
  const response = await query(`{
    allCareers_pages(uid: "careers") {
      edges {
        node {
          body {
            ... on Careers_pageBodyMetadata {
              ${metadataFields}
            }
            ... on Careers_pageBodyHero {
              __typename
              primary {
                title
                richdescription
              }
              fields {
                image
                image_webp
              }
            }
            ... on Careers_pageBodyWorking_at_spaceship {
              __typename
              primary {
                title
                richdescription
                learn_more_link {
                  ... on _ExternalLink {
                    __typename
                    url
                  }
                }
              }
            }
            ... on Careers_pageBodyBenefits {
              __typename
              primary {
                title
              }
              fields {
                icon
                benefit_description
              }
            }
            ... on Careers_pageBodyJob_openings {
              __typename
              primary {
                title
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Careers_PageBody>(response.allCareers_pages);

  if (!body) {
    throw new Error('Failed to get careers page');
  }
  return body;
};

export const getVoyagerBoostsPage = async (): Promise<
  Voyager_Boosts_PageBody[]
> => {
  const response = await query(`{
    allVoyager_boosts_pages(id: "YUfV6BAAACQAzLQi") {
      edges {
        node {
          body {
            ... on Voyager_boosts_pageBodyMetadata {
              ${metadataFields}
            }
            ... on Voyager_boosts_pageBodyHero {
              __typename
              primary {
                title
                tagline
                product
              }
            }
            ... on Voyager_boosts_pageBodyFeatures {
              __typename
              primary {
                title
              }
              fields {
                title
                tagline
                type
              }
            }
            ... on Voyager_boosts_pageBodyApp_store_links {
              __typename
              primary {
                title
              }
            }
            ... on Voyager_boosts_pageBodyImportant_documents {
              __typename
              primary {
                title
                tagline
              }
            }
            ... on Voyager_boosts_pageBodyJoin3 {
              __typename
              primary {
                title
                tagline
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<Voyager_Boosts_PageBody>(
    response.allVoyager_boosts_pages,
  );

  if (!body) {
    throw new Error('Failed to get voyager boosts page');
  }
  return body;
};

export const getFirstHomeHomepage = async (): Promise<
  First_Home_HomepageBody[]
> => {
  const response = await query(`{
    allFirst_home_homepages(id: "ZTC8EhIAACAAs6eG") {
      edges {
        node {
          body {
            ... on First_home_homepageBodyMetadata {
              ${metadataFields}
            }
            ... on First_home_homepageBodyHero {
              __typename
              primary {
                product
                title
                richtagline
                richfootnote
              }
            }
            ... on First_home_homepageBodyIntroduction {
              __typename
              primary {
                title
                richtagline
              }
            }
            ... on First_home_homepageBodyHomebuyer_programs {
              __typename
              primary {
                title
                richtagline
              }
              fields{
                type
                title
                richdescription
                learnmorebuttontext
                learnmorebuttonlink
              }
            }
            ... on First_home_homepageBodyStates {
              __typename
              primary {
                title
                richtagline
              }
              fields {
                state
                title
                richdescription
              }
            }
            ... on First_home_homepageBodyFaq {
              __typename
              primary {
                id
                title
                richtagline
              }
              fields {
                title
                richdescription
              }
            }
            ... on First_home_homepageBodyApps_download {
              __typename
              primary {
                title
              }
            }
            ... on First_home_homepageBodySimple_sign_up {
              __typename
              primary {
                title
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
              }
            }
            ... on First_home_homepageBodyLower_deposit {
              __typename
              primary {
                id
                title
                richtagline
                faqtitle
                richfaqdescription
                statestitle
              }
              fields {
                state
              }
            }
            ... on First_home_homepageBodySign_up {
              __typename
              primary {
                title
                richtagline
                richdisclaimer
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
              }
            }
            ... on First_home_homepageBodyLearn {
              __typename
              primary {
                title
              }
              fields{
                postslug
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<First_Home_HomepageBody>(
    response.allFirst_home_homepages,
  );

  if (!body) {
    throw new Error('Failed to get First Home homepage');
  }
  return body;
};

export const getFirstHomeStatePage = async (
  stateId: string,
): Promise<First_Home_StateBody[]> => {
  const response = await query(`{
    allFirst_home_states(id: "${stateId}") {
      edges {
        node {
          body {
            ... on First_home_stateBodyMetadata {
              ${metadataFields}
            }
            ... on First_home_stateBodyHero {
              __typename
              primary {
                product
                title
                richtagline
                richfootnote
              }
            }
            ... on First_home_stateBodyHelp {
              __typename
              primary {
                title
                richtagline
              }
              fields {
                image
                title
                richdescription
                learnmorebuttontext
                learnmorebuttonlink
              }
            }
            ... on First_home_stateBodyExample {
              __typename
              primary {
                title
                richtagline
                signuptitle
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
              }
            }
            ... on First_home_stateBodyFaq {
              __typename
              primary {
                id
                title
              }
              fields {
                title
                richdescription
              }
            }
            ... on First_home_stateBodySign_up {
              __typename
              primary {
                title
                richtagline
                signupbuttontext
                signupbuttonurl {
                  ... on _ExternalLink {
                    __typename
                    url
                    target
                  }
                }
                richdisclaimer
              }
            }
            ... on First_home_stateBodyFirst_home_guarantee {
              __typename
              primary {
                id
                title
              }
              fields {
                title
                richdescription
              }
            }
            ... on First_home_stateBodyStates {
              __typename
              primary {
                title
                richtagline
              }
              fields {
                state
                title
                richdescription
              }
            }
          }
        }
      }
    }
  }`);

  const body = getFirstNodeBody<First_Home_StateBody>(
    response.allFirst_home_states,
  );

  if (!body) {
    throw new Error('Failed to get first home state page data');
  }

  return body;
};
