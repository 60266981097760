import {
  Box,
  Columns,
  Container,
  Heading,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import Link from 'next/link';
import React from 'react';

import { PanelSection } from '../panel-section';
import proxyVotingImage from './img-proxy-voting.svg';

export interface ProxyVotingPanelProps {
  title: string;
  tagline: string | React.ReactNode;
}

export const ProxyVotingPanel: React.FC<
  React.PropsWithChildren<ProxyVotingPanelProps>
> = ({ title, tagline }) => (
  <TrackingCategory category="Proxy Voting Panel">
    <PanelSection>
      <Container>
        <Columns
          alignY="center"
          spaceX={{ xs: 'md', md: 'xxl' }}
          spaceY={{ xs: 'lg', md: 'none' }}
        >
          <Columns.Column
            width={{ xs: 1, md: 1 / 2, lg: 5 / 12 }}
            offset={{ xs: 0, lg: 1 / 12 }}
          >
            <Stack spaceY="md">
              <Heading component="h2" variant={2}>
                {title}
              </Heading>
              <Text variant={1} color="neutral.085">
                {tagline}
              </Text>
              <Link href="#" as="#" passHref={true} legacyBehavior>
                <PresentationLink size="md" icon="arrow" color="indigo.070">
                  Learn more
                </PresentationLink>
              </Link>
            </Stack>
          </Columns.Column>

          <Columns.Column width={{ xs: 1, md: 1 / 2, lg: 6 / 12 }}>
            <Box
              display="flex"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              alignItems="center"
            >
              <img src={proxyVotingImage} alt="Proxy voting" />
            </Box>
          </Columns.Column>
        </Columns>
      </Container>
    </PanelSection>
  </TrackingCategory>
);
