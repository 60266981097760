import { useEffect, useRef } from 'react';

interface Position {
  x: number;
  y: number;
}

function getScrollPosition(): Position {
  if (typeof window === 'undefined') return { x: 0, y: 0 };
  return { x: window.scrollX, y: window.scrollY };
}

type EffectCallback = ({
  prev,
  curr,
}: {
  prev: Position;
  curr: Position;
}) => void;

export function useScrollPosition(
  effect: EffectCallback,
  deps: React.DependencyList,
  wait = 10,
): void {
  const position = useRef(getScrollPosition());
  const throttleTimeout = useRef<NodeJS.Timeout | null | number>(null);

  const callBack = (): void => {
    const curr = getScrollPosition();
    effect({ prev: position.current, curr });
    position.current = curr;
    throttleTimeout.current = null;
  };

  useEffect(() => {
    const handleScroll = (): void => {
      if (wait) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
