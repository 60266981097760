import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

interface LinkRenderProps {
  href: string;
  isActive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  ref?: React.Ref<HTMLAnchorElement>;
}

interface RenderLinkProps {
  href?: string;
  isActive?: boolean;
  onClick?: () => void;
  children: (props: LinkRenderProps) => React.ReactElement;
}

const RenderLink = React.forwardRef<HTMLAnchorElement, RenderLinkProps>(
  ({ href, isActive, onClick, children }, ref) => {
    if (!href) {
      throw new Error('Link not passed any href');
    }
    return children({ href, isActive, onClick, ref });
  },
);

RenderLink.displayName = 'RenderLink';

export interface LinkProps {
  href: string;
  children: (props: LinkRenderProps) => React.ReactElement;
}

export const Link: React.FC<LinkProps> = ({ href, children }) => {
  const router = useRouter();
  return (
    <NextLink href={href} passHref legacyBehavior>
      <RenderLink isActive={router.asPath === href}>{children}</RenderLink>
    </NextLink>
  );
};
