import { usePage } from '@spaceship-fspl/tracking';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const usePageTracking = (): void => {
  const path = useRouter().asPath;
  const page = usePage();

  useEffect(() => {
    page?.(path);
  }, [page, path]);
};
