import {
  Button,
  Columns,
  Container,
  Divider,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import React from 'react';

import { PanelSection } from '../panel-section';

export type SpaceshipProduct = 'voyager' | 'super' | 'nova';

export interface SignUpPanelProps {
  product?: SpaceshipProduct;
  title: string;
  tagline?: string;
}

export const SignUpPanel: React.FC<
  React.PropsWithChildren<SignUpPanelProps>
> = ({ product, title, tagline }) => {
  let href = ExternalRoutes.SPACESHIP_SIGNUP;
  if (product) {
    href =
      product === 'voyager'
        ? ExternalRoutes.VOYAGER_SIGNUP
        : ExternalRoutes.SUPER_SIGNUP;
  }
  return (
    <>
      <Divider color="neutral.050" />
      <PanelSection>
        <Container>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, md: 6 / 8, lg: 10 / 12 }}>
              <Stack spaceY="lg" alignX="center">
                <Stack spaceY="md">
                  <Heading
                    component="h2"
                    variant={2}
                    color="neutral.100"
                    align="center"
                  >
                    {title}
                  </Heading>
                  {tagline && (
                    <Text variant={1} color="neutral.080" align="center">
                      {tagline}
                    </Text>
                  )}
                </Stack>

                <Button href={href} size="lg" variant="primary">
                  Sign up
                </Button>
              </Stack>
            </Columns.Column>
          </Columns>
        </Container>
      </PanelSection>
    </>
  );
};
