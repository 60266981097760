import {
  Avatar,
  Box,
  Text,
  Tiles,
  UnstyledLink,
} from '@spaceship-fspl/components';
import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
  borderRadiusTopRight,
  boxShadow,
  fontSize,
  getColor,
  getSpace,
  lineHeight,
  map,
  marginRight,
  marginTop,
  match,
  Media,
  minWidth,
  padding,
  paddingBottom,
  paddingTop,
  paddingX,
  queries,
  zIndex,
} from '@spaceship-fspl/styles';
import {
  createProps,
  createVariant,
  ResponsiveValue,
} from '@spaceship-fspl/substance-style';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import {
  getAvatarImageSrcset,
  getFeatureImageSrcset,
  rewriteGhostImageURL,
} from 'data/ghost';
import Link from 'next/link';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import styled, { css } from 'styled-components';

import { BlogTitle } from '../blog-title';

export type ArticleCardVariant = 'default' | 'featured';

const CARD_BORDER_RADIUS = 'sm';

const Content = styled(Box).attrs({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
})`
  border: 1px solid ${getColor('neutral.050')};
  ${borderRadiusBottomLeft(CARD_BORDER_RADIUS)}
  ${borderRadiusBottomRight(CARD_BORDER_RADIUS)}
  min-height: 0;
`;

const FeatureImage = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
`;

const TagName = styled(Text)`
  display: inline-block;
  font-weight: 500;
  transition: 0.15s;
`;

const Title = styled(BlogTitle)`
  p {
    margin: 0;
  }

  ${({ variant }) =>
    variant === 1
      ? css`
          ${fontSize({ xs: '24px', md: '32px', lg: '48px' })}
          ${lineHeight({
            xs: '36px',
            md: '46px',
            lg: '64px',
          })}
            letter-spacing: 0;

          ${match('md')`
              letter-spacing: -0.6px;
            `}
          ${match('lg')`
              letter-spacing: -2px;
            `}
        `
      : css`
          ${fontSize({ xl: '24px' })}
          ${lineHeight({ lg: '36px' })}
        `}
`;

const Description = styled(Text)`
  align-items: flex-start;
  display: flex;
  flex: 1;
  overflow: hidden;
  font-weight: normal;

  p {
    margin: 0;
  }
`;

const CardLink = styled(UnstyledLink)`
  :focus {
    opacity: 0.4;
    outline: none;
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

const AuthorAvatar = styled(Avatar)`
  ${marginRight('xs')}
`;

const AuthorName = styled(Text)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TagLink = styled(UnstyledLink)`
  position: relative;
  ${zIndex(1)}

  :focus {
    outline: none;
  }

  :hover ${TagName}, :focus ${TagName} {
    opacity: 0.4;
    transition: 0.15s;
  }
`;

const AuthorLink = styled(UnstyledLink)`
  ${marginRight('xxs')}
  align-items: center;
  display: flex;
  min-width: 0;
  position: relative;
  ${zIndex(1)}
  transition: all 0.2s ease-in 0s;

  :focus {
    outline: none;
  }

  :hover ${AuthorAvatar}, :focus ${AuthorAvatar} {
    ${borderColor('indigo.060')}
  }
`;

const ReadingTime = styled(Text)`
  white-space: nowrap;
`;

const variant = createVariant({
  default: css`
    flex-direction: column;

    ${Content} {
      ${paddingX({ xs: 'md', lg: 'lg' })}
      ${paddingBottom({ xs: 'md', lg: 'lg' })}
      border-top: none;
    }

    ${TagLink} {
      ${paddingTop({ lg: 'xxxs' })}
    }

    ${TagName} {
      ${paddingBottom('xxxs')}
      ${paddingTop('md')}
    }

    ${Description} {
      ${marginTop('xxs')}
    }

    ${match('md')`
      height: 500px;
    `}
  `,
  featured: css`
    flex-direction: column;

    ${Content} {
      ${padding({ xs: 'md', lg: 'lg', xl: 'xl' })}
    }

    ${TagName} {
      ${paddingBottom({ xs: 'xxxs', md: 'xs' })}
    }

    ${Description} {
      ${fontSize({ xs: '16px', md: '20px', lg: '24px' })}
      ${lineHeight({ xs: '26px', md: '32px', lg: '38px' })}
      ${marginTop({ xs: 'xxs', md: 'sm' })}
    }

    ${match('md')`
      flex-direction: row;
      height: 390px;

      ${FeatureImage} {
        height: auto;
        width: calc((100% - ${getSpace('md')}) / 2);
      }

      ${Content} {
        ${borderRadiusTopRight(CARD_BORDER_RADIUS)}
        ${borderRadiusBottomLeft('none')}
        border-left: none;
        height: 100%;
      }

      ${ReadingTime} {
        ${fontSize('16px')}
        ${lineHeight('26px')}
      }
    `}

    ${match('lg')`
      height: 512px;

      ${FeatureImage},
      ${Content} {
        width: 50%;
      }
    `}
  `,
});

const Card = styled.div<{ variant: ArticleCardVariant }>`
  ${backgroundColor('neutral.000')}
  ${borderRadius(CARD_BORDER_RADIUS)}
  display: flex;
  height: 485px;
  overflow: hidden;
  position: relative;

  :hover {
    ${boxShadow('md')}
  }

  &,
  :hover {
    transition: 0.15s box-shadow;
  }

  ${FeatureImage} {
    height: 200px;
  }

  ${createProps({ variant })}
`;

export interface ArticleCardProps {
  variant?: ArticleCardVariant;
  slug?: string | null;
  image?: string | null;
  title?: string | null;
  description?: string | null;
  tag?: {
    name?: string;
    slug?: string;
  } | null;
  author?: {
    name?: string;
    slug?: string;
    profileImage?: string | null;
  } | null;
  readingTime?: number | null;
}

export const ArticleCard: React.FC<
  React.PropsWithChildren<ArticleCardProps>
> = ({
  slug,
  image,
  title,
  tag,
  description,
  author,
  readingTime,
  variant = 'default',
}) => {
  const authorAvatarAriaDescribedBy = `article-card-${slug}-author-${author?.name?.replace(
    /\s/g,
    '-',
  )}`;

  return (
    <TrackingCategory category="Article Card">
      <Card variant={variant}>
        <FeatureImage
          src={image ? rewriteGhostImageURL(image) : undefined}
          alt={`Feature image for ${title}`}
          srcSet={image ? getFeatureImageSrcset(image) : undefined}
          sizes={
            variant === 'default'
              ? `
            ${queries['md']} 400px,
            600px
          `
              : `
            ${queries['md']} 400px,
            ${queries['lg']} 600px,
            600px
          `
          }
        />

        <Content>
          <Box display="flex" flexDirection="column">
            {tag && (
              <Link
                href="/learn/tag/[slug]/"
                as={`/learn/tag/${tag.slug}/`}
                legacyBehavior
              >
                <TagLink>
                  <TagName
                    component="span"
                    color="neutral.085"
                    variant={variant === 'default' ? 4 : 3}
                  >
                    {tag.name?.toUpperCase()}
                  </TagName>
                </TagLink>
              </Link>
            )}

            <Link href="/learn/[slug]/" as={`/learn/${slug}/`} legacyBehavior>
              <CardLink>
                <Title
                  component="div"
                  variant={variant === 'default' ? 4 : 1}
                  align="left"
                >
                  <LinesEllipsis text={title || ''} maxLine="2" component="p" />
                </Title>
              </CardLink>
            </Link>
          </Box>

          <Description
            component="div"
            variant={variant === 'default' ? 2 : 1}
            color="neutral.085"
          >
            <LinesEllipsis text={description || ''} maxLine="3" component="p" />
          </Description>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="md"
          >
            <Link
              href="/learn/author/[slug]/"
              as={`/learn/author/${author?.slug}/`}
              legacyBehavior
            >
              <AuthorLink>
                <AuthorAvatar
                  size="sm"
                  aria-describedby={authorAvatarAriaDescribedBy}
                  src={
                    author?.profileImage
                      ? rewriteGhostImageURL(author?.profileImage)
                      : undefined
                  }
                  srcset={
                    author?.profileImage
                      ? getAvatarImageSrcset(author?.profileImage)
                      : undefined
                  }
                  alt={`${author?.name} profile image`}
                />

                <AuthorName
                  id={authorAvatarAriaDescribedBy}
                  variant={variant === 'default' ? 3 : 2}
                  color="neutral.100"
                >
                  {author?.name || ''}
                </AuthorName>
              </AuthorLink>
            </Link>

            <ReadingTime variant={3} color="neutral.080">
              {readingTime} min read
            </ReadingTime>
          </Box>
        </Content>
      </Card>
    </TrackingCategory>
  );
};

export const TiledArticleCards: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Tiles
    columns={{ xs: 1, md: 2, lg: 3 }}
    alignX="center"
    spaceX="md"
    spaceY={{ xs: 'md', lg: 'lg' }}
  >
    {children}
  </Tiles>
);

const ScrollableWrapper = styled.div`
  overflow-x: auto;
  /* offsetting padding on the Container - should make a variant without space at some stage */
  ${map({ xs: 'sm', sm: 'md', xl: 'lg' }, (m) => `margin: 0 -${getSpace(m)};`)}
  & > div {
    box-sizing: border-box;
    ${paddingX({ xs: 'sm', md: 'md' })}
    ${paddingBottom('md')}
    ${minWidth({
      xs: `calc(${308 * 3}px + 4 * ${getSpace('sm')})`,
      md: `calc(${368 * 3}px + 4 * ${getSpace('md')})`,
    })}
  }

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${getColor('neutral.015')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${getColor('neutral.015')};
    border-radius: 10px;
  }

  :hover::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral.050')};
  }
`;

export interface ScrollableArticleCardsProps {
  columns?: ResponsiveValue<Media, number>;
}

export const ScrollableArticleCards: React.FC<
  React.PropsWithChildren<ScrollableArticleCardsProps>
> = ({ children, columns = 3 }) => (
  <ScrollableWrapper>
    <div>
      <Tiles
        columns={columns}
        alignX="left"
        spaceX={{ xs: 'sm', md: 'md' }}
        spaceY={{ xs: 'md', lg: 'lg' }}
      >
        {children}
      </Tiles>
    </div>
  </ScrollableWrapper>
);
