const WEBSITE_PATH = process.env.WEBSITE_PATH || '';

const GHOST_HTML_REGEXP =
  /src=(["'])https:\/\/spaceship.ghost.io\/content\/images\/?/g;

export const rewriteGhostImagesInHTML = (html: string): string => {
  return html.replace(GHOST_HTML_REGEXP, `src=$1${WEBSITE_PATH}/blog/images/`);
};

const GHOST_IMAGE_REGEXP =
  /^(https:\/\/spaceship.ghost.io)?\/content\/images\//;

// config from package.json in Ghost theme is set to sizes below
// @see https://ghost.org/docs/api/v3/handlebars-themes/responsive-images/
export const rewriteGhostImageURL = (
  url: string,
  size?: '32' | '64' | '128' | `200` | '400' | '600' | '800' | '1200' | '2000',
): string => {
  // we don't want to rewrite the url when storybook is running
  if (process.env.STORYBOOK) {
    return url;
  }

  if (size) {
    return url.replace(
      GHOST_IMAGE_REGEXP,
      `${WEBSITE_PATH}/blog/images/size/w${size}/`,
    );
  } else {
    return url.replace(GHOST_IMAGE_REGEXP, `${WEBSITE_PATH}/blog/images/`);
  }
};

export const getAvatarImageSrcset = (imageURL: string): string => {
  return `
    ${rewriteGhostImageURL(imageURL, '32')} 32w,
    ${rewriteGhostImageURL(imageURL, '64')} 64w,
    ${rewriteGhostImageURL(imageURL, '128')} 128w,
    ${rewriteGhostImageURL(imageURL, '200')} 200w,
    ${rewriteGhostImageURL(imageURL, '400')} 400w
  `;
};

export const getFeatureImageSrcset = (imageURL: string): string => {
  return `
    ${rewriteGhostImageURL(imageURL, '400')} 400w,
    ${rewriteGhostImageURL(imageURL, '600')} 600w,
    ${rewriteGhostImageURL(imageURL, '800')} 800w,
    ${rewriteGhostImageURL(imageURL, '1200')} 1200w
  `;
};
