import fetch from 'cross-fetch';

const PRISMIC_REPO = process.env.PRISMIC_REPO || '';
const PRISMIC_REST_URL = `https://${PRISMIC_REPO}.cdn.prismic.io/api/v2`;
const PRISMIC_GQL_URL = `https://${PRISMIC_REPO}.prismic.io/graphql`;

let cachedRef: string | undefined = undefined;

const getRef = async (): Promise<string> => {
  if (cachedRef) {
    return cachedRef;
  }
  const res = await fetch(PRISMIC_REST_URL);
  const json = await res.json();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = json.refs.find((ref: any) => ref.id === 'master');
  cachedRef = ref.ref;
  return ref.ref;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const query = async (query: string): Promise<any> => {
  const minifiedQuery = query.replace(/\n/g, '').replace(/\s\s+/g, ' ');
  const res = await fetch(
    `${PRISMIC_GQL_URL}?query=${encodeURIComponent(minifiedQuery)}`,
    {
      method: 'get',
      headers: {
        'prismic-ref': await getRef(),
      },
    },
  );
  if (res.status !== 200) {
    try {
      console.error(res);
      console.error(await res.json());
    } catch {
      /* do nothing - we were just trying to output to assist in debugging */
    }
    throw new Error('Invalid response from Prismic.');
  }
  const json = await res.json();
  return json.data;
};
