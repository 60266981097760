import {
  ActionLink,
  Container as SContainer,
} from '@spaceship-fspl/components';
import {
  color,
  display,
  getColor,
  paddingX,
  paddingY,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

export interface NavigationItemProp {
  title?: string | null;
  slug?: string | null;
}
export interface NavigationProps {
  items?: NavigationItemProp[] | null;
}

const Wrapper = styled.div`
  ${color('neutral.080')}
  border-top: 1px solid ${getColor('neutral.030')};
  border-bottom: 1px solid ${getColor('neutral.030')};
`;

const Container = styled(SContainer)`
  ${display({ lg: 'flex' })}
  white-space: nowrap;
  overflow-x: auto;
  text-align: center;
  justify-content: space-around;
`;

const NavigationItem = styled(ActionLink)`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  ${paddingX('lg')}
  ${paddingY('md')}

    &:first-child,
    &:last-child {
    padding-left: 0;
  }

  &:hover {
    ${color('neutral.100')}
    border-bottom: none;
    font-weight: 600;
  }
`;

export const Navigation: React.FC<React.PropsWithChildren<NavigationProps>> = ({
  items,
}) =>
  items ? (
    <TrackingCategory category="Category Navigation">
      <Wrapper>
        <Container>
          {items.map(({ title, slug }) => (
            <Link
              key={`nav_${slug}`}
              href="/learn/tag/[slug]/"
              as={`/learn/tag/${slug}/`}
              passHref={true}
              legacyBehavior
            >
              <NavigationItem color="neutral.080">{title}</NavigationItem>
            </Link>
          ))}
          <Link href="/learn/categories/" passHref legacyBehavior>
            <NavigationItem color="neutral.080">Explore more</NavigationItem>
          </Link>
        </Container>
      </Wrapper>
    </TrackingCategory>
  ) : null;
