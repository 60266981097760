export const getPathsBasedOnUIDs = (
  uids: string[],
  paramName: string,
): Array<{ params: { [k: string]: string[] } }> => {
  return uids.map((uid) => ({
    params: { [paramName]: constructParamsListByUID(uid) },
  }));
};

export const constructParamsListByUID = (uid: string): string[] => {
  return uid.split('_');
};

export const constructUIDByParamsList = (params?: string[]): string => {
  return params && params.length ? params.join('_') : '';
};

export const constructPathByUID = (uid: string): string => {
  return constructParamsListByUID(uid).join('/');
};
