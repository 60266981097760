import GhostContentAPI, {
  Author,
  Authors,
  FilterParam,
  IncludeParam,
  LimitParam,
  PageParam,
  PostOrPage,
  PostsOrPages,
  Tag,
  Tags,
} from '@tryghost/content-api';

export const contentAPI = new GhostContentAPI({
  url: 'https://spaceship.ghost.io',
  key: process.env.GHOST_API_KEY || '',
  version: 'v5.0',
});

interface DefaultParams {
  filter: FilterParam | FilterParam[];
  include: IncludeParam[];
}

export const defaultParams: DefaultParams = {
  filter: 'visibility:public',
  include: ['tags', 'authors'],
};

export interface CommonParams {
  filter?: FilterParam[];
  limit?: LimitParam;
  page?: PageParam;
  ignore?: string;
}

export interface GetPostsParams extends CommonParams {
  tag?: string;
  author?: string;
}

enum FilterOperand {
  AND = '+',
  OR = ',',
}

const safeConcat = <T>(...xs: Array<T | undefined | Array<T>>): Array<T> =>
  xs.reduce((acc: T[], x) => (x === undefined ? acc : acc.concat(x)), []);

export const createFilters = <T>(
  filters: Array<T | undefined | Array<T>>,
  operator: FilterOperand = FilterOperand.AND,
): string => safeConcat(...filters).join(operator);
export const createAuthorFilter = (slug?: string): string | undefined =>
  slug ? `primary_author:${slug}` : undefined;
export const createTagFilter = (slug?: string): string | undefined =>
  slug ? `tag:${slug}` : undefined;
export const createIgnoreFilter = (slug?: string): string | undefined =>
  slug ? `slug:-${slug}` : undefined;

export const getPost = (slug: string): Promise<PostOrPage> =>
  contentAPI.posts.read({ slug, ...defaultParams });

export const getPosts = (params?: GetPostsParams): Promise<PostsOrPages> => {
  const { limit, page, filter, author, tag, ignore } = params || {};

  return contentAPI.posts.browse({
    ...defaultParams,
    ...(limit && { limit }),
    ...(page && { page }),
    filter: createFilters([
      defaultParams.filter,
      filter,
      createAuthorFilter(author),
      createTagFilter(tag),
      createIgnoreFilter(ignore),
    ]),
  });
};

export const getTag = (slug: string): Promise<Tag> =>
  contentAPI.tags.read({ slug, ...defaultParams });

export const getTags = (params?: CommonParams): Promise<Tags> => {
  const { limit, page, filter, ignore } = params || {};

  return contentAPI.tags.browse({
    ...defaultParams,
    ...(limit && { limit }),
    ...(page && { page }),
    filter: createFilters([
      defaultParams.filter,
      filter,
      createIgnoreFilter(ignore),
    ]),
  });
};

export const getAuthor = (slug: string): Promise<Author> =>
  contentAPI.authors.read(
    { ...defaultParams, slug },
    { include: ['count.posts'] },
  );

export const getAuthors = (params?: CommonParams): Promise<Authors> => {
  const { limit, page, filter, ignore } = params || {};

  return contentAPI.authors.browse({
    ...defaultParams,
    ...(limit && { limit }),
    ...(page && { page }),
    filter: createFilters([
      defaultParams.filter,
      filter,
      createIgnoreFilter(ignore),
    ]),
  });
};
