/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextLink, TextLinkProps } from '@spaceship-fspl/components';
import Link from 'next/link';
import { Elements, RichText as PrismicRichText } from 'prismic-reactjs';
import React from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';

import { isExternalURL } from './is-external-url';
import { constructPathByUID } from './path';

const Paragraph = styled.p`
  margin: 0;
`;

type DocumentType =
  | 'about_page'
  | 'homepage'
  | 'important_documents'
  | 'important_document_page'
  | 'learn_categories_page'
  | 'learn_homepage'
  | 'news_article'
  | 'news_homepage'
  | 'privacy_policy'
  | 'super_homepage'
  | 'super_portfolio'
  | 'voyager_homepage'
  | 'voyager_portfolio';

export interface PrismicDocumentData {
  link_type: 'Web' | 'Media' | 'Document';
  url?: string;
  type?: DocumentType;
  slug?: string;
  id?: string;
  uid?: string;
}

const documentTypeToSlug = {
  about_page: '/about/',
  homepage: '/',
  important_documents: '/important-documents/',
  learn_categories_page: '/learn/categories/',
  learn_homepage: '/learn/',
  news_homepage: '/news/',
  privacy_policy: '/privacy-policy/',
  super_homepage: '/super/',
  voyager_homepage: '/voyager/',
};

// @see https://prismic.io/docs/javascript/beyond-the-api/link-resolving
export const resolveLink = (data: PrismicDocumentData): string => {
  if (data.link_type === 'Document' && data.type) {
    switch (data.type) {
      case 'news_article':
        return documentTypeToSlug['news_homepage'] + data.slug + '/';

      case 'super_portfolio':
        if (data.uid) {
          return `${documentTypeToSlug['super_homepage']}${constructPathByUID(
            data.uid,
          )}/`;
        }
        return documentTypeToSlug['super_homepage'];

      case 'voyager_portfolio':
        if (data.uid) {
          return `${documentTypeToSlug['voyager_homepage']}${constructPathByUID(
            data.uid,
          )}/`;
        }
        return documentTypeToSlug['voyager_homepage'];

      case 'important_document_page':
        if (data.uid) {
          return `${
            documentTypeToSlug['important_documents']
          }${constructPathByUID(data.uid)}/`;
        }
        return documentTypeToSlug['important_documents'];

      default:
        return documentTypeToSlug[data.type] || '/';
    }
  }

  // For link types 'Media' & 'Web'
  return data.url || '/';
};

const serializeHTML = (
  type: string,
  element: any,
  _content: any,
  children: React.ReactNode,
  key: string,
  options?: {
    variant: 'dark' | 'light';
  },
): React.ReactNode => {
  switch (type) {
    case Elements.hyperlink: {
      const href = resolveLink(element.data);
      const target = element.data.target;
      const hasTarget = Boolean(target);

      const textLinkProps: Omit<TextLinkProps, 'children'> = {
        color: options?.variant === 'light' ? 'neutral.000' : 'indigo.070',
        rel: hasTarget ? 'noopener' : undefined,
        target: hasTarget ? target : undefined,
      };

      // Don't use next/link for external links
      // @see https://github.com/ijjk/next.js/blob/canary/errors/invalid-href-passed.md
      if (isExternalURL(href)) {
        return (
          <TextLink {...textLinkProps} key={key} href={href}>
            {children}
          </TextLink>
        );
      }

      return (
        <Link key={key} href={href} passHref={true} legacyBehavior>
          <TextLink {...textLinkProps}>{children}</TextLink>
        </Link>
      );
    }

    case Elements.paragraph: {
      return <Paragraph key={key}>{children}</Paragraph>;
    }

    case Elements.preformatted: {
      return <Markdown key={key}>{element.text}</Markdown>;
    }

    default:
      return null;
  }
};

export const RichText: React.FC<
  React.PropsWithChildren<{
    render?: any;
    variant?: 'dark' | 'light';
  }>
> = ({ render, variant = 'dark' }) =>
  render ? (
    <PrismicRichText
      render={render}
      htmlSerializer={(...args) => serializeHTML(...args, { variant })}
    />
  ) : null;
