import { Box, Stack, Text } from '@spaceship-fspl/components';
import {
  calcInceptionToPerformanceAsAtDateInMonths,
  DATE_FORMAT_SHORT_MONTH_NAME,
  formatDate,
  stringToDate,
} from '@spaceship-fspl/helpers';
import { fontSize, match } from '@spaceship-fspl/styles';
import {
  productIcons,
  SuperPrismicPortfolio,
  VoyagerPrismicPortfolio,
} from 'helpers/products';
import React from 'react';
import styled from 'styled-components';

export interface PerformanceCardProps<T> {
  variant: 'light' | 'dark';
  type: T;
  title: string;
  inceptionDate?: string;
  performanceAsAt?: string;
  oneMonthPerformancePercentage?: string;
  threeMonthPerformancePercentage?: string;
  sixMonthPerformancePercentage?: string;
  oneYearPerformancePercentage?: string;
  threeYearPerformancePercentage?: string;
  sinceInceptionPerformancePercentage?: string;
}

const PerformanceText = styled(Text)`
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;

  ${match('xs')`
    font-size: 48px;
    line-height: 64px;
  `}

  ${match('xl')`
    font-size: 64px;
    line-height: 64px;
  `}
`;

const PerAnnum = styled.span`
  ${fontSize({ xs: '24px', lg: '28px' })}
`;

const PeriodicPerformanceText = styled(Text)`
  font-weight: 500;

  ${match('lg')`
    font-size: 20px;
    line-height: 32px;
  `}
`;

export function PerformanceCard<
  T extends VoyagerPrismicPortfolio | SuperPrismicPortfolio,
>({
  variant,
  type,
  title,
  sinceInceptionPerformancePercentage,
  oneMonthPerformancePercentage,
  threeMonthPerformancePercentage,
  sixMonthPerformancePercentage,
  oneYearPerformancePercentage,
  threeYearPerformancePercentage,
  inceptionDate,
  performanceAsAt,
}: PerformanceCardProps<T>): React.ReactElement {
  const isVoyager = [
    'universe',
    'origin',
    'earth',
    'galaxy',
    'explorer',
  ].includes(type);
  const Icon =
    productIcons[type as VoyagerPrismicPortfolio | SuperPrismicPortfolio];

  const inceptionDateDate = stringToDate(inceptionDate ?? '');
  const performanceAsAtDate = stringToDate(performanceAsAt ?? '');
  const annualisedPerformanceDataInMonths =
    calcInceptionToPerformanceAsAtDateInMonths(
      inceptionDateDate,
      performanceAsAtDate,
    );

  return (
    <Box
      backgroundColor={variant === 'dark' ? 'neutral.000' : 'neutral.015'}
      borderRadius="md"
      height="100%"
      display={{ md: 'inline-block' }}
      width={{ md: 445 }}
      marginX={{ md: 'xs' }}
    >
      <StyledBox
        paddingX="md"
        paddingTop="xl"
        paddingBottom="lg"
        backgroundColor="indigo.100"
        borderRadius="md"
      >
        <Stack spaceY="sm">
          <Stack spaceY="xxxs" alignX="center">
            <Icon color="indigo.020" size={{ xs: 'xxl', lg: 'xxxl' }} />

            <Text variant={1} color="indigo.020" component="div" align="center">
              {title}
            </Text>
          </Stack>

          <PerformanceText align="center" variant={1} color="indigo.020">
            {sinceInceptionPerformancePercentage === 'N/A'
              ? 'x.xx'
              : sinceInceptionPerformancePercentage}
            %<PerAnnum>&nbsp;pa</PerAnnum>
          </PerformanceText>

          {inceptionDate && performanceAsAt && (
            <Text
              variant={3}
              align="center"
              color="neutral.000"
              component="div"
            >
              Annualised performance since{' '}
              {isVoyager ? 'the Funded Date' : 'inception'} ({inceptionDate} to{' '}
              {performanceAsAt}) ({annualisedPerformanceDataInMonths} months)
              <br />
              <br />
            </Text>
          )}

          {(type === 'galaxy' || type === 'explorer') && (
            <Text
              variant={3}
              align="center"
              color="neutral.000"
              component="div"
            >
              Investment performance data for the {title} will be available
              after one full year of history. The {title} was funded on{' '}
              {inceptionDate}.
            </Text>
          )}
        </Stack>
      </StyledBox>
      {!!oneMonthPerformancePercentage &&
        !!threeMonthPerformancePercentage &&
        !!sixMonthPerformancePercentage &&
        !!oneYearPerformancePercentage &&
        !!threeYearPerformancePercentage &&
        !!inceptionDate && (
          <Box paddingY="lg" paddingX={{ xs: 'md', xl: 'lg' }}>
            {!!threeYearPerformancePercentage && (
              <Box display="flex" justifyContent="space-between">
                <PeriodicPerformanceText variant={1} color="neutral.100">
                  3 years
                </PeriodicPerformanceText>

                <PeriodicPerformanceText
                  variant={1}
                  color="neutral.100"
                  align="right"
                >
                  {threeYearPerformancePercentage}% pa
                </PeriodicPerformanceText>
              </Box>
            )}

            {!!oneYearPerformancePercentage && (
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="xxs"
              >
                <PeriodicPerformanceText variant={1} color="neutral.100">
                  1 year
                </PeriodicPerformanceText>

                <PeriodicPerformanceText
                  variant={1}
                  color="neutral.100"
                  align="right"
                >
                  {oneYearPerformancePercentage}%
                </PeriodicPerformanceText>
              </Box>
            )}

            {!!sixMonthPerformancePercentage && (
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="xxs"
              >
                <PeriodicPerformanceText variant={1} color="neutral.100">
                  6 months
                </PeriodicPerformanceText>

                <PeriodicPerformanceText
                  variant={1}
                  color="neutral.100"
                  align="right"
                >
                  {sixMonthPerformancePercentage}%
                </PeriodicPerformanceText>
              </Box>
            )}

            {!!threeMonthPerformancePercentage && (
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="xxs"
              >
                <PeriodicPerformanceText variant={1} color="neutral.100">
                  3 months
                </PeriodicPerformanceText>

                <PeriodicPerformanceText
                  variant={1}
                  color="neutral.100"
                  align="right"
                >
                  {threeMonthPerformancePercentage}%
                </PeriodicPerformanceText>
              </Box>
            )}

            {!!oneMonthPerformancePercentage && (
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="xxs"
              >
                <PeriodicPerformanceText variant={1} color="neutral.100">
                  1 month
                </PeriodicPerformanceText>

                <PeriodicPerformanceText
                  variant={1}
                  color="neutral.100"
                  align="right"
                >
                  {oneMonthPerformancePercentage}%
                </PeriodicPerformanceText>
              </Box>
            )}

            <Box display="flex" justifyContent="space-between" marginTop="xxs">
              <PeriodicPerformanceText variant={1} color="neutral.085">
                {isVoyager ? 'Funded date' : 'Inception date'}
              </PeriodicPerformanceText>

              <PeriodicPerformanceText
                variant={1}
                color="neutral.085"
                align="right"
              >
                {formatDate(
                  new Date(inceptionDate),
                  DATE_FORMAT_SHORT_MONTH_NAME,
                )}
              </PeriodicPerformanceText>
            </Box>
          </Box>
        )}
    </Box>
  );
}

const StyledBox = styled(Box)`
  white-space: wrap;
`;
