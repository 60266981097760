import { Inline } from '@spaceship-fspl/components';
import { IconProps } from '@spaceship-fspl/icons-web';
import {
  borderRadius,
  borderWidth,
  color,
  fontWeight,
  match,
  paddingX,
  paddingY,
  ResponsiveValue,
  rgba,
  Space,
  text,
} from '@spaceship-fspl/styles';
import { TrackingAction, useTracking } from '@spaceship-fspl/tracking';
import React from 'react';
import styled, { css } from 'styled-components';

export interface TabProps {
  isActive?: boolean;
}

export const Tab = styled.button<TabProps>`
  ${borderRadius('lg')}
  ${borderWidth('none')}
  ${paddingX('sm')}
  ${paddingY('xxxs')}
  ${text({ variant: 4 })}

  ${match('lg')`
    ${text({ variant: 3 })}
  `}

  ${({ isActive }) =>
    isActive
      ? css`
          ${color('neutral.000')}
          background-color: ${rgba('neutral.000', 0.15)};
          ${fontWeight(600)}
        `
      : css`
          color: ${rgba('neutral.000', 0.4)};
          background-color: transparent;
          ${fontWeight(500)}
        `}

  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
`;

export interface TabSwitcherProps<T> {
  value?: T;
  options: {
    label: string;
    icon?: React.ComponentType<IconProps>;
    value: T;
  }[];
  onChange: (value: T) => void;
  spaceX?: ResponsiveValue<Space>;
  spaceY?: ResponsiveValue<Space>;
}

export function TabSwitcher<T>({
  value,
  options,
  onChange,
  spaceX = { xs: 'sm', md: 'lg' },
  spaceY = { xs: 'sm' },
}: TabSwitcherProps<T>): React.ReactElement {
  const { track } = useTracking();
  const handleChange = (label: string, value: T): void => {
    track({
      action: TrackingAction.Select,
      label: label,
    });
    onChange(value);
  };
  return (
    <Inline alignX="center" spaceX={spaceX} spaceY={spaceY}>
      {options.map(({ label, value: option, icon: Icon }, index) => (
        <Tab
          key={index}
          isActive={option === value}
          onClick={() => handleChange(label, option)}
        >
          <Inline spaceX="xxxs" alignY="center">
            {Icon && <Icon />}
            <span>{label}</span>
          </Inline>
        </Tab>
      ))}
    </Inline>
  );
}
