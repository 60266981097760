import { Heading, HeadingProps } from '@spaceship-fspl/components';
import { fontFamily, match } from '@spaceship-fspl/styles';
import React from 'react';
import styled from 'styled-components';

const PageHeading = styled(Heading)`
  ${fontFamily('articleHeading')}
  font-weight: bold;

  ${({ variant }) =>
    variant === 1 &&
    match('lg')`
      line-height: 64px;
    `}
`;

export const BlogTitle: React.FC<
  React.PropsWithChildren<
    Omit<HeadingProps, 'variant'> & Partial<Pick<HeadingProps, 'variant'>>
  >
> = ({ children, ...props }) => (
  <PageHeading variant={1} align="center" component="h1" {...props}>
    {children}
  </PageHeading>
);
