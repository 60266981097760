import {
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidthTop,
  color,
  fontFamily,
  getColor,
  heading,
  HeadingVariant,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  match,
  paddingLeft,
  paddingX,
  text,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css } from 'styled-components';

export * from './sticky-article-header';

const size = createVariant({
  sm: css`
    h1 {
      ${heading({ variant: 1 })}
    }

    h2 {
      ${heading({ variant: 4 })}
    }

    h3 {
      ${heading({ variant: 5 })}
    }

    h1,
    h2,
    h3 {
      ${marginBottom('sm')}
      ${marginTop('lg')}

      :first-child {
        ${marginTop('none')}
      }
    }

    p,
    li,
    blockquote {
      ${text({ variant: 2 })}
    }

    p,
    li {
      ${marginY('md')}
    }

    blockquote {
      font-weight: 500;
    }
  `,
  lg: css`
    ${['h1', 'h2', 'h3', 'h4', 'h5'].map(
      (hx, i) => css`
        ${hx} {
          ${heading({ variant: (i + 1) as HeadingVariant })}
          ${marginY('md')}
        }
      `,
    )}

    p, li, blockquote {
      ${text({ variant: 1 })}
    }

    p,
    li {
      ${marginY('md')}
      font-weight: normal;
    }
  `,
});

const Wrapper = styled.article<ArticleProps>`
  ${fontFamily('text')}

  h1,
  h2,
  h3,
  h4,
  h5 {
    ${color('neutral.100')}
    margin: 0;
    font-style: normal;
  }

  h1 {
    ${fontFamily('articleHeading')}
    font-weight: bold;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
  }

  h1 + p {
    font-weight: 500;
  }

  p,
  li,
  figcaption {
    ${color('neutral.085')}
    font-weight: normal;
    font-style: normal;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  li {
    ${marginY('md')}
  }

  a {
    ${color('indigo.070')}
    font-weight: 500;
    transition: 0.15s color;

    :active,
    :visited,
    :hover {
      ${color('indigo.090')}
    }
  }

  blockquote {
    ${color('neutral.100')}
    ${marginLeft({ xs: 'sm', lg: 'md' })}
    ${marginRight('none')}
    ${marginY({ xs: 'md', lg: 'lg' })}
    ${paddingLeft('sm')}
    font-weight: 500;
    border-left: 6px solid ${getColor('indigo.070')};
  }

  figcaption {
    ${marginBottom('lg')}
    ${marginTop('sm')}
    ${text({ variant: 3 })}
  }

  /* ghost img container */
  figure {
    ${marginY({ xs: 'sm', lg: 'lg' })}
    ${marginX('none')}

    img {
      display: block;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }

    iframe {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }

  hr {
    border: none;
    ${borderColor('neutral.030')}
    ${borderWidthTop('sm')}
    border-top-style: solid;
    margin: 0;
  }

  ${createProps({ size })}
`;

export interface ArticleProps {
  size?: 'sm' | 'lg';
}

export const Article: React.FC<React.PropsWithChildren<ArticleProps>> = ({
  children,
  size = 'lg',
}) => <Wrapper size={size}>{children}</Wrapper>;

export const ArticleContentContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export interface ArticleFeatureImageProps {
  url?: string | null;
}

export const FeatureImageWrapper = styled.div`
  max-width: 1200px;
  ${paddingX({ md: 'md' })}
  ${marginY({ xs: 'lg', md: 'xl', lg: 'xxl' })}
  margin-left: auto;
  margin-right: auto;
`;

export const FeatureImage = styled.div<ArticleFeatureImageProps>`
  width: 100%;
  padding-top: 50%; /* 1:2 Aspect Ratio */
  ${backgroundColor('neutral.015')}
  background-image: url(${({ url }) => url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${match('md')`
    ${borderRadius('sm')}
  `}
`;

export const ArticleFeatureImage: React.FC<
  React.PropsWithChildren<ArticleFeatureImageProps>
> = (props) => (
  <FeatureImageWrapper>
    <FeatureImage {...props} />
  </FeatureImageWrapper>
);
