import { Box, Divider, Text, UnstyledLink } from '@spaceship-fspl/components';
import { FeatherArrowRightIcon } from '@spaceship-fspl/icons-web';
import {
  borderWidthBottom,
  borderWidthTop,
  color,
  display,
  getColor,
  marginLeft,
  marginRight,
  paddingBottom,
  paddingTop,
  text,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import React from 'react';
import styled from 'styled-components';

import { Document } from './documents';
export * from './documents';

const ViewText = styled(Text).attrs({
  variant: 1,
  color: 'indigo.070',
  component: 'span',
})`
  ${display({ xs: 'none', md: 'inline-block' })}
  ${marginRight({ md: 'xs' })}
  opacity: 0;
  transition: opacity 0.15s ease-in;
`;

const ViewButtonContainer = styled.div`
  ${color('neutral.050')}
  ${marginLeft({ xs: 'sm', md: 'lg' })}
  white-space: nowrap;
`;

const ViewButton: React.FC<React.PropsWithChildren> = () => (
  <ViewButtonContainer>
    <ViewText>View</ViewText>
    <FeatherArrowRightIcon />
  </ViewButtonContainer>
);

const ListItem = styled.li``;

const Link = styled(UnstyledLink)`
  ${text({ variant: 1 })}
  ${color('neutral.100')}
  ${paddingBottom('md')}
  ${paddingTop('md')}
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  flex: 1;

  &,
  ${ViewButtonContainer} {
    transition: color 0.15s ease-in;
  }

  :hover {
    &,
    ${ViewButtonContainer}, ${ViewText} {
      ${color('indigo.070')}
      opacity: 1;
    }
  }
`;

const Title = styled(Text).attrs({ variant: 2, align: 'center' })`
  ${paddingBottom('sm')}
  ${borderWidthBottom('md')}
  border-bottom-color: ${getColor('neutral.100')};
  border-bottom-style: solid;
`;

const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  ${ListItem}:first-child {
    ${borderWidthTop('sm')}
    border-top-color: ${getColor('neutral.030')};
    border-top-style: solid;
  }

  ${ListItem} {
    ${borderWidthBottom('sm')}
    border-bottom-color: ${getColor('neutral.030')};
    border-bottom-style: solid;
  }
`;

export interface DocumentListProps {
  title?: string;
  documents: Document[];
  subsectionTitle?: string;
  subsectionDocuments?: Document[];
  subsectionTitle2?: string;
  subsectionDocuments2?: Document[];
}

export const DocumentList: React.FC<
  React.PropsWithChildren<DocumentListProps>
> = ({
  title,
  documents,
  subsectionTitle,
  subsectionDocuments,
  subsectionTitle2,
  subsectionDocuments2,
}) => (
  <TrackingCategory category="Documents Panel">
    {title && <Title>{title}</Title>}

    <ListContainer>
      {documents.map(({ name, link, isExternal }) => (
        <ListItem key={`${name}_${link}`}>
          <Link
            href={link}
            target="_blank"
            rel={isExternal ? 'noreferrer' : undefined}
          >
            {name}
            <ViewButton />
          </Link>
        </ListItem>
      ))}
    </ListContainer>

    {subsectionTitle && !!subsectionDocuments?.length && (
      <>
        <Box marginY="md">
          <Text variant={2} color="neutral.080">
            {subsectionTitle}
          </Text>
        </Box>

        <Divider color="neutral.050" size="md" />

        <ListContainer>
          {subsectionDocuments.map(({ name, link }) => (
            <ListItem key={`${name}_${link}`}>
              <Link href={link} target="_blank">
                {name}
                <ViewButton />
              </Link>
            </ListItem>
          ))}
        </ListContainer>
      </>
    )}

    {subsectionTitle2 && !!subsectionDocuments2?.length && (
      <>
        <Box marginY="md">
          <Text variant={2} color="neutral.080">
            {subsectionTitle2}
          </Text>
        </Box>

        <Divider color="neutral.050" size="md" />

        <ListContainer>
          {subsectionDocuments2.map(({ name, link }) => (
            <ListItem key={`${name}_${link}`}>
              <Link href={link} target="_blank">
                {name}
                <ViewButton />
              </Link>
            </ListItem>
          ))}
        </ListContainer>
      </>
    )}
  </TrackingCategory>
);
