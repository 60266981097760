import {
  Box,
  Button,
  Columns,
  Container,
  Heading,
  IconButton,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FeatherArrowLeftIcon,
  FeatherArrowRightIcon,
} from '@spaceship-fspl/icons-web';
import {
  boxShadow,
  getSpace,
  height,
  marginTop,
  match,
  width,
  zIndex,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import { DottedCircle, PanelSection } from 'components';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled, { css } from 'styled-components';

const BenefitImage = styled.img`
  display: block;
  margin: auto;
  max-height: 420px;
  max-width: calc(100% - 144px);

  ${match('md')`
    max-height: none;
    max-width: 100%;
    width: 160px;
  `}

  ${match('lg')`
    width: 250px;
  `}
`;

const BenefitDescription = styled(Text).attrs({
  variant: 1,
  color: 'neutral.100',
  align: 'center',
})`
  margin: auto;
  ${marginTop('xl')}

  ${match('md')`
    ${marginTop('lg')}
    max-width: 195px;
  `}

  ${match('lg')`
    ${marginTop('xl')}
  `}
`;

const BenefitDots = styled.div`
  ${height({ xs: '180px', md: '138px', lg: '205px' })}
  ${width({ xs: '180px', md: '138px', lg: '205px' })}
  position: absolute;
  transform: translate(-50%, -50%);
`;

const LeftBackgroundBenefitDots = styled(BenefitDots)`
  left: 37%;
  top: 25%;

  ${match('md')`
    left: 6%;
    top: 5%;
  `}
`;

const RightBackgroundBenefitDots = styled(BenefitDots)`
  left: 60%;
  top: 71%;

  ${match('md')`
    left: 90%;
    top: 70%;
  `}

  ${match('lg')`
    left: 94%;
    top: 45%;
  `}
`;

const carouselButtonStyles = css`
  ${boxShadow('lg')}
  ${zIndex(1)}
  appearance: none;
  background: none;
  border: none;
  border-radius: 50%;
  outline: none;
  padding: 0;
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
`;

const CarouselBackButton = styled.button`
  ${carouselButtonStyles}
  left: ${getSpace('md')};
`;

const CarouselNextButton = styled.button`
  ${carouselButtonStyles}
  right: ${getSpace('md')};
`;

const StyledCarouselContainer = styled.div`
  .carousel .slide img {
    width: auto;
  }
`;

export interface BenefitsPanelProps {
  title: string;
  tagline: React.ReactNode;
  benefits?: Array<{
    image: string;
    imageAltText: string;
    description: React.ReactNode;
  }> | null;
  signupText?: string;
  signupUrl?: string;
  signupUrlTarget?: HTMLAttributeAnchorTarget;
}

export const BenefitsPanel: React.FC<BenefitsPanelProps> = ({
  title,
  tagline,
  benefits,
  signupText = 'Sign up',
  signupUrl = ExternalRoutes.VOYAGER_SIGNUP,
  signupUrlTarget,
}) => (
  <TrackingCategory category="Benefits Panel">
    <PanelSection>
      <Stack spaceY={{ xs: 'lg', lg: 'xl' }}>
        <Container>
          <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
            <Heading
              component="h2"
              variant={2}
              color="neutral.100"
              align="center"
            >
              {title}
            </Heading>

            <Text
              variant={1}
              color="neutral.080"
              align="center"
              component="div"
            >
              {tagline}
            </Text>
          </Stack>
        </Container>

        <Stack spaceY={{ xs: 'lg', lg: 'xl' }}>
          <Visible
            isHidden={{ xs: true, md: false }}
            displayValue="block"
            component="div"
          >
            <Container>
              <Box display="flex" justifyContent="center" position="relative">
                <LeftBackgroundBenefitDots>
                  <DottedCircle color="neutral.070" />
                </LeftBackgroundBenefitDots>
                <RightBackgroundBenefitDots>
                  <DottedCircle color="neutral.070" />
                </RightBackgroundBenefitDots>

                <Box position="relative" width="100%">
                  <Columns alignX="center">
                    {benefits?.map((benefit, i) => (
                      <Columns.Column width={1 / 3} key={`benefit_${i}`}>
                        <BenefitImage
                          src={benefit.image}
                          alt={benefit.imageAltText}
                        />

                        <BenefitDescription component="div">
                          {benefit.description}
                        </BenefitDescription>
                      </Columns.Column>
                    ))}
                  </Columns>
                </Box>
              </Box>
            </Container>
          </Visible>

          {!!benefits && benefits.length > 0 && (
            <Visible
              isHidden={{ md: true }}
              displayValue="block"
              component="div"
            >
              <Box position="relative" width="100%">
                <LeftBackgroundBenefitDots>
                  <DottedCircle color="neutral.070" />
                </LeftBackgroundBenefitDots>
                <RightBackgroundBenefitDots>
                  <DottedCircle color="neutral.070" />
                </RightBackgroundBenefitDots>

                <StyledCarouselContainer>
                  <Carousel
                    emulateTouch={true}
                    infiniteLoop={true}
                    renderArrowPrev={(onClick) => (
                      <CarouselBackButton onClick={onClick}>
                        <IconButton
                          as="span"
                          variant="light"
                          icon={FeatherArrowLeftIcon}
                          size="lg"
                        />
                      </CarouselBackButton>
                    )}
                    renderArrowNext={(onClick) => (
                      <CarouselNextButton onClick={onClick}>
                        <IconButton
                          as="span"
                          variant="light"
                          icon={FeatherArrowRightIcon}
                          size="lg"
                        />
                      </CarouselNextButton>
                    )}
                    showThumbs={false}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                  >
                    {benefits.map((benefit, i) => (
                      <div key={`benefit_mobile_${i}`}>
                        <BenefitImage src={benefit.image} alt="" />
                        <BenefitDescription component="div">
                          {benefit.description}
                        </BenefitDescription>
                      </div>
                    ))}
                  </Carousel>
                </StyledCarouselContainer>
              </Box>
            </Visible>
          )}

          <Box display="flex" justifyContent="center">
            <Button
              variant="primary"
              size="lg"
              href={signupUrl}
              target={signupUrlTarget}
            >
              {signupText}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </PanelSection>
  </TrackingCategory>
);
