import { Columns, Stack, Text } from '@spaceship-fspl/components';
import {
  backgroundColor,
  fontSize,
  fontWeight,
  lineHeight,
  match,
} from '@spaceship-fspl/styles';
import { Card } from 'components/card';
import * as React from 'react';
import styled from 'styled-components';

export interface AssetAllocation {
  name: string;
  percentage: number;
}

export interface AssetAllocationCardProps {
  title: string;
  assetAllocations: AssetAllocation[];
  isActive?: boolean;
}

const CardWrapper = styled(Card)<{ isActive: boolean }>`
  ${({ isActive }) => backgroundColor(isActive ? 'indigo.090' : 'neutral.000')}
  transition: 0.4s;

  ${match('md')`
    ${backgroundColor('indigo.090')}
  `}
`;

const AssetAllocationTitle = styled(Text).attrs({
  variant: 4,
  color: 'indigo.020',
  align: 'center',
})`
  ${fontSize({ lg: '16px' })}
  ${fontWeight(600)}
  ${lineHeight({ lg: '26px' })}
  text-transform: uppercase;
`;

const AssetAllocationData = styled(Text).attrs({
  variant: 2,
  color: 'indigo.020',
})`
  ${fontSize({ lg: '24px' })}
  ${fontWeight({ lg: 500 })}
  ${lineHeight({ lg: '38px' })}
`;

const NoWrapColumns = styled(Columns)`
  & > div {
    flex-wrap: nowrap;
  }
`;

export const AssetAllocationCard: React.FC<
  React.PropsWithChildren<AssetAllocationCardProps>
> = ({ assetAllocations, isActive = true, title }) => {
  return (
    <CardWrapper
      isActive={isActive}
      variant="indigo"
      padding={{ xs: 'md', md: 'lg', lg: 'xl' }}
    >
      <Stack spaceY={{ xs: 'xs', md: 'sm' }}>
        <AssetAllocationTitle>{title}</AssetAllocationTitle>
        <div>
          {assetAllocations.map(({ name, percentage }) => (
            <NoWrapColumns
              spaceY={{ xs: 'xs', md: 'xxs' }}
              spaceX="xxs"
              key={name}
            >
              <Columns.Column width="max">
                <AssetAllocationData align="left">{name}</AssetAllocationData>
              </Columns.Column>
              <Columns.Column width="min">
                <AssetAllocationData>{percentage}%</AssetAllocationData>
              </Columns.Column>
            </NoWrapColumns>
          ))}
        </div>
      </Stack>
    </CardWrapper>
  );
};
