import {
  Author,
  Authors,
  PostOrPage,
  PostsOrPages,
  Tag,
  Tags,
} from '@tryghost/content-api';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from 'react-query';

import {
  CommonParams,
  getAuthor,
  getAuthors,
  getPost,
  getPosts,
  GetPostsParams,
  getTag,
  getTags,
} from './api';

export const usePost = (slug: string): UseQueryResult<PostOrPage> =>
  useQuery(['post', slug], () => getPost(slug));

export const usePosts = ({
  params,
  // The first page of posts
  // pre-fetched in `getStaticProps`
  initialData,
  // Default to first page
  // pre-fetched in `getStaticProps`
  initialPage = 1,
}: {
  params?: GetPostsParams;
  initialData?: PostsOrPages;
  initialPage?: number;
}): UseInfiniteQueryResult<PostsOrPages, number> =>
  useInfiniteQuery(
    ['posts', params],
    ({ pageParam: page = initialPage }) => getPosts({ page, ...params }),
    {
      getNextPageParam: (previousPageOfPosts: PostsOrPages) => {
        if (previousPageOfPosts?.meta?.pagination) {
          return previousPageOfPosts?.meta?.pagination.next || undefined;
        }

        return initialPage + 1;
      },
      initialData: initialData && {
        pages: [initialData],
        pageParams: [initialPage - 1],
      },
    },
  );

export const useTag = (slug: string): UseQueryResult<Tag> =>
  useQuery(['tag', slug], () => getTag(slug));

export const useTags = (params?: CommonParams): UseQueryResult<Tags> =>
  useQuery(['tags', params], () => getTags(params));

export const useAuthor = (slug: string): UseQueryResult<Author> =>
  useQuery(['author', slug], () => getAuthor(slug));

export const useAuthors = (params?: CommonParams): UseQueryResult<Authors> =>
  useQuery(['authors', params], () => getAuthors(params));
