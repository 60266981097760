import {
  Columns,
  Container,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import React from 'react';

import { Document, DocumentList } from '../document-list';
import { PanelSection } from '../panel-section';

export type ImportantDocumentsPanelProps = {
  title: string;
  tagline: React.ReactNode;
  documents: Document[];
} & React.ComponentProps<typeof PanelSection>;

export const ImportantDocumentsPanel: React.FC<
  ImportantDocumentsPanelProps
> = ({ title, tagline, documents, ...rest }) => (
  <PanelSection {...rest}>
    <Container>
      <Columns alignX="center" spaceY={{ xs: 'lg', lg: 'xl' }}>
        <Columns.Column width={{ xs: 1, md: 6 / 8, lg: 8 / 12 }}>
          <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
            <Heading component="h2" variant={2} align="center">
              {title}
            </Heading>

            <Text
              variant={1}
              align="center"
              color="neutral.080"
              component="div"
            >
              {tagline}
            </Text>
          </Stack>
        </Columns.Column>

        <Columns.Column width={{ xs: 1, lg: 10 / 12 }}>
          <DocumentList documents={documents} />
        </Columns.Column>
      </Columns>
    </Container>
  </PanelSection>
);
