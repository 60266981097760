import { Color, getColor } from '@spaceship-fspl/styles';
import * as React from 'react';
import styled, { css, SimpleInterpolation } from 'styled-components';

export interface DottedBackgroundProps {
  color?: Color;
  alpha?: number;
}

export const dottedBackground = ({
  color,
  alpha,
}: DottedBackgroundProps): SimpleInterpolation => css`
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='5' height='5'><rect x='0' y='0' width='2' height='2' fill='%23${getColor(
    color || 'neutral.100',
  )?.substr(1)}' fill-opacity='${alpha ?? 1}' /></svg>");
`;

const CircleWithDottedBackground = styled.div<DottedBackgroundProps>`
  ${dottedBackground}
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

export const DottedCircle: React.FC<
  React.PropsWithChildren<DottedBackgroundProps>
> = (props) => <CircleWithDottedBackground {...props} />;
