import { Author, PostOrPage, Tag } from '@tryghost/content-api';
import { rewriteGhostImageURL } from 'data/ghost';

import { MetadataProps } from './metadata';

const TITLE_SUFFIX = ` | Spaceship`;

const WEBSITE_ORIGIN = process.env.WEBSITE_ORIGIN || '';

function rewriteGhostImageURLWithOrigin(url: string): string {
  const rewrittenURL = rewriteGhostImageURL(url);
  return `${rewrittenURL.startsWith('/') ? WEBSITE_ORIGIN : ''}${rewrittenURL}`;
}

function isAuthor(data: PostOrPage | Tag | Author): data is Author {
  return data && (data as Author).name !== undefined;
}

function isPostOrPage(data: PostOrPage | Tag | Author): data is PostOrPage {
  return data && (data as PostOrPage).title !== undefined;
}

export function getMetadataPropsFromGhostPostPageTagOrAuthor(
  data: PostOrPage | Tag | Author,
): MetadataProps {
  const props: MetadataProps = {
    title: '',
  };

  if (isAuthor(data)) {
    // default the metadata from the author when we have the minimal data
    props.title =
      data.meta_title || (data.name && `${data.name}${TITLE_SUFFIX}`) || '';
    if (data.meta_description || data.bio) {
      props.description = data.meta_description || data.bio || '';
    }
    if (data.name && data.profile_image) {
      const profileImage = rewriteGhostImageURLWithOrigin(data.profile_image);
      props.opengraph = {
        type: 'profile',
        image: profileImage,
        title: data.name,
      };
      props.twitter = {
        card: 'summary',
        image: profileImage,
        title: data.name,
      };
      if (data.bio) {
        props.opengraph.description = data.bio;
        props.twitter.description = data.bio;
      }
    }
  } else if (isPostOrPage(data)) {
    props.title =
      data.meta_title || (data.title && `${data.title}${TITLE_SUFFIX}`) || '';
    if (data.meta_description) {
      props.description = data.meta_description || '';
    }

    const featureImage = data.feature_image
      ? rewriteGhostImageURLWithOrigin(data.feature_image)
      : undefined;

    const og_image = data.og_image
      ? rewriteGhostImageURLWithOrigin(data.og_image)
      : featureImage;
    const og_title = data.og_title || data.title;
    const og_description = data.og_description || data.excerpt;
    if (og_image && og_title) {
      props.opengraph = {
        type: 'article',
        image: og_image,
        title: og_title,
      };
      if (og_description) {
        props.opengraph.description = og_description;
      }
    }

    const tw_image = data.twitter_image
      ? rewriteGhostImageURLWithOrigin(data.twitter_image)
      : featureImage;
    const tw_title = data.twitter_title || data.title;
    const tw_description = data.twitter_description || data.excerpt;
    if (tw_image && tw_title) {
      props.twitter = {
        card: 'summary',
        image: tw_image,
        title: tw_title,
      };
      if (tw_description) {
        props.twitter.description = tw_description;
      }
    }
  } else {
    props.title =
      data.meta_title || (data.name && `${data.name}${TITLE_SUFFIX}`) || '';
    if (data.meta_description) {
      props.description = data.meta_description || '';
    }
  }

  if (!props.title) {
    throw new Error('Metadata must define a title.');
  }

  return props;
}
