import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Box, Stack } from '@spaceship-fspl/components';
import {
  borderWidth,
  color,
  fontWeight,
  getSpace,
  paddingX,
  rgba,
  text,
  transition,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled, { css } from 'styled-components';

type Variant = 'dark' | 'light';

interface MobileOptionsProps<T, V> {
  variant: Variant;
  options: Array<
    T & {
      label: string;
      value: V;
    }
  >;
  selectedOption: V;
  onChange: (value: V) => void;
  renderOption: (props: T, index: number) => React.ReactElement;
  fullHeight?: boolean;
}

export function MobileOptions<T, V>({
  variant,
  options,
  fullHeight = false,
  selectedOption,
  onChange,
  renderOption,
}: MobileOptionsProps<T, V>): React.ReactElement {
  const activeIndex = options.findIndex(
    ({ value }) => value === selectedOption,
  );

  return (
    <Stack spaceY="md">
      <StyledOptionsContainer>
        {options?.map((option) => (
          <StyledOptionButton
            key={option.value as string}
            variant={variant}
            isActive={selectedOption === option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </StyledOptionButton>
        ))}
      </StyledOptionsContainer>

      <StyledCarouselContainer>
        <Carousel
          emulateTouch={true}
          centerMode={true}
          centerSlidePercentage={98}
          selectedItem={activeIndex}
          onChange={(index) => {
            const selectedOption = options[index];
            if (selectedOption) {
              onChange(selectedOption.value);
            }
          }}
          showThumbs={false}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
        >
          {options.map((option, index) => (
            <StyledSlide
              key={option.value as string}
              isActive={activeIndex === index}
              height={fullHeight ? '100%' : undefined}
              variant={variant}
            >
              {renderOption(option, index)}
            </StyledSlide>
          ))}
        </Carousel>
      </StyledCarouselContainer>
    </Stack>
  );
}

const StyledOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledOptionButton = styled.button<{
  isActive: boolean;
  variant: Variant;
}>`
  ${borderWidth('none')}
  ${paddingX('md')}
  ${text({ variant: 2 })}
  ${fontWeight(600)}
  border-radius: ${getSpace('md')};
  cursor: pointer;
  height: ${getSpace('xl')};
  outline: none;
  text-align: center;
  text-transform: capitalize;

  ${({ isActive }) =>
    createProps({
      variant: createVariant({
        dark: css`
          ${color(isActive ? 'neutral.000' : 'neutral.070')}
          background-color: ${rgba('neutral.000', isActive ? 0.2 : 0)};
        `,
        light: css`
          ${color('neutral.100')}
          background-color: ${rgba('neutral.070', isActive ? 0.5 : 0)};
        `,
      }),
    })}
`;

const StyledCarouselContainer = styled.div`
  .slider-wrapper {
    ${paddingX('md')}
  }
`;

const StyledSlide = styled(Box).attrs({
  paddingX: 'xxxs',
})<{
  isActive: boolean;
  variant: Variant;
}>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  ${transition}

  ${({ isActive }) =>
    createProps({
      variant: createVariant({
        dark: css`
          opacity: ${isActive ? 1 : 0.2};
        `,
        light: css`
          opacity: ${isActive ? 1 : 0.6};
        `,
      }),
    })}
`;
