/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Columns,
  Divider,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import {
  borderWidth,
  color,
  fontSize,
  fontWeight,
  getSpace,
  height,
  lineHeight,
  match,
  paddingX,
  rgba,
  text,
  textAlign,
} from '@spaceship-fspl/styles';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Card, CardShadowPosition } from '../card';

interface PortfolioButtonProps {
  isActive: boolean;
}

const PortfolioTitle = styled(Text).attrs({
  variant: 2,
  align: 'center',
  color: 'neutral.000',
})`
  text-transform: capitalize;
`;

const PortfolioButton = styled.button<PortfolioButtonProps>`
  ${({ isActive }) => color(isActive ? 'neutral.000' : 'neutral.070')}
  ${height({ xs: 'xl', md: 'auto' })}
  ${borderWidth('none')}
  ${paddingX({ xs: 'md', md: 'none' })}
  ${text({ variant: 2 })}
  ${textAlign({ xs: 'center', md: 'right' })}
  background-color: ${({ isActive }) =>
    isActive ? rgba('neutral.000', 0.2) : 'transparent'};
  border-radius: ${getSpace('md')};
  cursor: pointer;
  font-weight: 600;
  outline: none;
  text-transform: capitalize;

  ${match('md')`
    ${color('neutral.000')}
    background-color: transparent;
    border-radius: 0;
    cursor: text;
    width: 100%;
  `}
`;

const RowHeading = styled(Text)`
  ${color({ xs: 'neutral.070', md: 'neutral.000' })}
  ${fontSize({ md: '20px', lg: '24px' })}
  ${lineHeight({ md: '32px', lg: '36px' })}
  ${fontWeight({ xs: 'normal', md: 500 })}
`;

const RowDescription = styled(Text)`
  ${fontSize({ md: '16px' })}
  ${lineHeight({ md: '26px' })}
`;

const getFriendlyPortfolioName = (name: string): string => {
  const lowercaseName = name.toLowerCase();

  switch (lowercaseName) {
    case 'growthx':
      return 'GrowthX';
    default:
      return name;
  }
};

export interface CollapsibleFeeTableRow {
  heading: string;
  description?: string;
  data: string[];
}

export interface CollapsibleFeeTableProps {
  variant: 'voyager' | 'super';
  title: string;
  portfolios: string[];
  rows?: CollapsibleFeeTableRow[];
  selectedPortfolio?: string;
  onPortfolioChange?: (value: string) => void;
}

export const CollapsibleFeeTable: React.FC<
  React.PropsWithChildren<CollapsibleFeeTableProps>
> = ({
  variant,
  onPortfolioChange,
  portfolios,
  rows,
  selectedPortfolio,
  title,
}) => {
  const columnSize = 1 / portfolios.length;
  const isSinglePortfolio = portfolios.length === 1;
  const ownSelectedPortfolio =
    isSinglePortfolio || !selectedPortfolio ? portfolios[0] : selectedPortfolio;
  const headingColWidth = isSinglePortfolio ? 3 / 5 : 2 / 5;
  const portfolioColWidth = isSinglePortfolio ? 2 / 5 : 3 / 5;
  const portfolioType = variant === 'voyager' ? 'portfolio' : 'option';

  return (
    <Card
      variant="dark"
      shadow={{ position: CardShadowPosition.bottom, color: 'indigo.100' }}
      paddingX={{ xs: 'md', md: 'lg', lg: 'xl' }}
      paddingTop={{ xs: 'md', lg: 'lg' }}
      paddingBottom={{ xs: 'lg', lg: 'xl' }}
    >
      <Box marginBottom="md">
        <Columns alignY="bottom" spaceX={{ xs: 'none', md: 'md', lg: 'none' }}>
          {isSinglePortfolio ? (
            <Columns.Column width={1}>
              <PortfolioTitle>
                <Text
                  component="span"
                  variant={2}
                  isBold={true}
                  color="neutral.000"
                >
                  Spaceship {getFriendlyPortfolioName(portfolios[0]!)}{' '}
                  {portfolioType}
                </Text>
                <Visible isHidden={{ xs: true, md: false }}>
                  &nbsp;–&nbsp;
                </Visible>
                <Visible isHidden={{ xs: false, md: true }}>
                  <br />
                </Visible>
                {title}
              </PortfolioTitle>
            </Columns.Column>
          ) : (
            <>
              <Columns.Column width={{ xs: 0, md: headingColWidth }}>
                <Visible
                  component="div"
                  isHidden={{ xs: true, md: false }}
                  displayValue="block"
                >
                  <Text variant={2} color="neutral.000">
                    {title}
                  </Text>
                </Visible>
              </Columns.Column>

              <Columns.Column width={{ xs: 1, md: portfolioColWidth }}>
                <Columns alignX="center" spaceX={{ xs: 'none', md: 'md' }}>
                  {portfolios.map((portfolio) => (
                    <Columns.Column
                      key={portfolio}
                      width={{ xs: 'min', md: columnSize }}
                    >
                      <PortfolioButton
                        onClick={
                          !onPortfolioChange
                            ? undefined
                            : () => {
                                onPortfolioChange(portfolio);
                              }
                        }
                        isActive={ownSelectedPortfolio === portfolio}
                      >
                        <Visible isHidden={{ xs: true, md: false }}>
                          Spaceship{' '}
                        </Visible>
                        {getFriendlyPortfolioName(portfolio)}
                        <Visible isHidden={{ xs: true, md: false }}>
                          {` ${portfolioType}`}
                        </Visible>
                      </PortfolioButton>
                    </Columns.Column>
                  ))}
                </Columns>
              </Columns.Column>
            </>
          )}
        </Columns>
      </Box>

      <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
        {rows?.map(({ heading, description, data }, index) => (
          <Fragment key={heading}>
            <Divider size={index === 0 ? 'md' : 'sm'} color="indigo.040" />

            <Stack spaceY="xxs">
              <Columns
                spaceX={{ xs: 'none', md: 'md', lg: 'none' }}
                spaceY={{ xs: 'xxxs', md: 'none' }}
              >
                <Columns.Column width={{ xs: 1, md: headingColWidth }}>
                  <RowHeading variant={2} align={{ xs: 'center', md: 'left' }}>
                    {heading}
                  </RowHeading>
                </Columns.Column>

                <Columns.Column width={{ xs: 1, md: portfolioColWidth }}>
                  <Columns spaceX={{ xs: 'none', md: 'md' }}>
                    {data.map((columnData: string, index: number) => (
                      <Columns.Column
                        key={`${portfolios[index]}_${heading}_${columnData}`}
                        width={{ xs: 1, md: columnSize }}
                      >
                        <Visible
                          component="div"
                          isHidden={{
                            xs: ownSelectedPortfolio !== portfolios[index],
                            md: false,
                          }}
                        >
                          <Text
                            variant={1}
                            color="neutral.000"
                            align={{ xs: 'center', md: 'right' }}
                          >
                            {columnData}
                          </Text>
                        </Visible>
                      </Columns.Column>
                    ))}
                  </Columns>
                </Columns.Column>
              </Columns>

              {description && (
                <Columns>
                  <Columns.Column width={{ xs: 1, md: headingColWidth }}>
                    <RowDescription
                      variant={4}
                      color="neutral.070"
                      align={{ xs: 'center', md: 'left' }}
                    >
                      {description}
                    </RowDescription>
                  </Columns.Column>
                </Columns>
              )}
            </Stack>
          </Fragment>
        ))}
      </Stack>
    </Card>
  );
};
