import * as React from 'react';

import { Box } from '../box';
import { Stack } from '../layout';
import {
  DefaultLinkComponent,
  PropsWithLinkComponent,
} from '../link-component';
import { UnstyledLink } from '../links';
import { NewsletterSubscribe } from '../newsletter-subscribe';
import { Text } from '../text';

export const NewsletterSection: React.FC<
  React.PropsWithChildren<PropsWithLinkComponent>
> = ({ LinkComponent = DefaultLinkComponent }) => {
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  return (
    <Box
      display={{ xs: 'block', md: 'flex' }}
      justifyContent="center"
      alignItems="center"
    >
      {!isSubmitted && (
        <Stack spaceY="xxxs" alignX={{ xs: 'center', md: 'left' }}>
          <Text variant={2} color="neutral.000" id="footer-sign-up-label">
            Sign up for our newsletter today
          </Text>
          <LinkComponent href="/privacy-policy/">
            {({ isActive, ...props }) => (
              <UnstyledLink {...props}>
                <Text variant={4} color="neutral.070">
                  Privacy Collection Notice
                </Text>
              </UnstyledLink>
            )}
          </LinkComponent>
        </Stack>
      )}
      <Box
        marginTop={{ xs: 'sm', md: 'none' }}
        marginLeft={{ xs: 'none', md: 'md' }}
      >
        <NewsletterSubscribe
          id="footer-newsletter"
          aria-labelledby="footer-sign-up-label"
          variant="dark"
          onSubscribe={() => setIsSubmitted(true)}
        />
      </Box>
    </Box>
  );
};
