import { Box } from '@spaceship-fspl/components';
import { mapNodeToHeaderProps } from 'components/header';
import { getBlogNavigation, getHeader } from 'data/prismic';
import { GetStaticProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';

import {
  ContentPageLayout,
  ContentPageLayoutProps,
  getContentPageLayoutStaticProps,
} from '../content-page-layout';
import { Navigation, NavigationItemProp } from './navigation';

interface BlogPageLayoutProps extends ContentPageLayoutProps {
  navigation?: NavigationItemProp[] | null;
  showNavigation?: boolean;
}

export const BlogPageLayout: React.FC<
  React.PropsWithChildren<BlogPageLayoutProps>
> = ({ navigation, children, showNavigation = true, ...otherProps }) => (
  <ContentPageLayout {...otherProps}>
    {showNavigation && <Navigation items={navigation} />}
    {children}
  </ContentPageLayout>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasProps<P>(value: any): value is { props: P } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value as any).props !== undefined;
}

interface WithBlogPageLayoutProps {
  layout: BlogPageLayoutProps;
}

export const getBlogPageLayoutStaticProps = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends Record<string, any>,
  Q extends ParsedUrlQuery = ParsedUrlQuery,
>(
  fn: GetStaticProps<P, Q>,
): GetStaticProps<P & WithBlogPageLayoutProps, Q> => {
  return getContentPageLayoutStaticProps(async (context) => {
    const [result, header, navigation] = await Promise.all([
      await fn(context),
      await getHeader(),
      await getBlogNavigation(),
    ]);
    if (hasProps(result)) {
      return {
        ...result,
        props: {
          ...result.props,
          layout: {
            headerProps: mapNodeToHeaderProps(header),
            navigation: navigation.items || [],
          },
        },
      };
    } else {
      return result;
    }
  });
};

export function withBlogPageLayout() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ComponentProps extends Record<string, any>>(
    Component: React.ComponentType<React.PropsWithChildren<ComponentProps>>,
  ) => {
    return function WithBlogPageLayout(
      props: ComponentProps & WithBlogPageLayoutProps,
    ): React.ReactElement {
      return (
        <BlogPageLayout {...props.layout}>
          <Component {...props} />
        </BlogPageLayout>
      );
    };
  };
}

export function withBlogArticlePageLayout() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ComponentProps extends Record<string, any>>(
    Component: React.ComponentType<React.PropsWithChildren<ComponentProps>>,
  ) => {
    return function WithBlogArticlePageLayout(
      props: ComponentProps & WithBlogPageLayoutProps,
    ): React.ReactElement {
      return (
        <BlogPageLayout {...props.layout} isStickyHeader={false}>
          <Component {...props} />
        </BlogPageLayout>
      );
    };
  };
}

export const BlogVerticalMarginContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Box
    marginTop={{ xs: 'lg', lg: 'xl' }}
    marginBottom={{ xs: 'xl', md: 'xxl', lg: 'xxxl' }}
  >
    {children}
  </Box>
);
