import {
  ActionLink,
  Box,
  Columns,
  Container,
  Heading,
  NewsletterSubscribe,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { height, width, zIndex } from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import React from 'react';
import styled from 'styled-components';

import { DottedCircle as S8DottedCircle } from '../dotted-circle';
import imageSpaceshipTimes from './image-spaceship-times.png';

const DottedCircle = styled(S8DottedCircle)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${width({ xs: '249px', md: '267px', lg: '400px' })}
  ${height({ xs: '249px', md: '267px', lg: '400px' })}
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  ${zIndex(1)}
`;

export const NewsletterPanel: React.FC<React.PropsWithChildren> = () => (
  <TrackingCategory category="Newsletter Panel">
    <Box
      backgroundColor="neutral.015"
      paddingY={{
        xs: 'xl',
        md: 'xxl',
      }}
    >
      <Container>
        <Columns>
          <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
            <Box position="relative" marginRight={{ xs: 'md', md: 'xxl' }}>
              <DottedCircle color="neutral.070" />
              <Image src={imageSpaceshipTimes} alt="Spaceship times" />
            </Box>
          </Columns.Column>
          <Columns.Column width={{ xs: 1, md: 1 / 2 }}>
            <Stack spaceY="sm">
              <Heading
                variant={2}
                color="neutral.100"
                align={{ xs: 'center', md: 'left' }}
              >
                The money talk you didn&apos;t know you needed
              </Heading>
              <Text
                variant={1}
                color="neutral.080"
                align={{ xs: 'center', md: 'left' }}
              >
                Join thousands of Australians already in-the-know.
              </Text>
              <NewsletterSubscribe id="newsletter-panel" variant="light" />
              <Text variant={4} align={{ xs: 'center', md: 'left' }}>
                <ActionLink
                  color="indigo.070"
                  href="/spaceship-capital/privacy-collection-notice"
                >
                  Privacy Collection Notice
                </ActionLink>
              </Text>
            </Stack>
          </Columns.Column>
        </Columns>
      </Container>
    </Box>
  </TrackingCategory>
);
