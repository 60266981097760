import {
  EtfProductIcon,
  IconComponent,
  StocksProductIcon,
  SuperGlobalIndexStarsIcon,
  SuperGrowthxStarsIcon,
  VoyagerEarthStarsIcon,
  VoyagerExplorerStarsIcon,
  VoyagerGalaxyStarsIcon,
  VoyagerOriginStarsIcon,
  VoyagerUniverseStarsIcon,
} from '@spaceship-fspl/icons-web';

export type SuperPrismicPortfolio = 'growthx' | 'global_index';
export type VoyagerPrismicPortfolio =
  | 'universe'
  | 'earth'
  | 'origin'
  | 'galaxy'
  | 'explorer';
export type NovaPrismicInvestmentOption = 'etf' | 'stocks';

export const productIcons: Record<
  VoyagerPrismicPortfolio | SuperPrismicPortfolio | NovaPrismicInvestmentOption,
  IconComponent
> = {
  growthx: SuperGrowthxStarsIcon,
  global_index: SuperGlobalIndexStarsIcon,
  universe: VoyagerUniverseStarsIcon,
  origin: VoyagerOriginStarsIcon,
  galaxy: VoyagerGalaxyStarsIcon,
  explorer: VoyagerExplorerStarsIcon,
  earth: VoyagerEarthStarsIcon,
  etf: EtfProductIcon,
  stocks: StocksProductIcon,
};
