import {
  Color,
  fill as SFill,
  height,
  ResponsiveValue,
  width,
} from '@spaceship-fspl/styles';
import { createProps, Interpolation } from '@spaceship-fspl/substance-style';
import React from 'react';
import styled, { css, ThemedStyledProps, ThemeProps } from 'styled-components';
export interface ProductIconProps {
  size: ResponsiveValue<number>;
  fill?: Color;
  highlightFill1?: Color;
  highlightFill2?: Color;
}

const SVG = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 96 96',
})`
  ${createProps({
    $size: [width, height],
  })}
`;

interface SVGElementProps {
  defaultFill: string;
  color?: Color;
}

const fillMixin = ({
  color,
  defaultFill,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
SVGElementProps): Interpolation<ThemedStyledProps<ThemeProps<any>, any>> =>
  color
    ? SFill(color)
    : css`
        fill: ${defaultFill};
      `;

const Path = styled.path<SVGElementProps>`
  ${fillMixin}
`;

export const SuperFHSSIcon: React.FC<
  React.PropsWithChildren<ProductIconProps>
> = ({ size, fill, highlightFill1, highlightFill2 }) => (
  <SVG $size={size}>
    <Path
      d="M70.1889 66.1406L68.9469 66.7643C68.9469 66.7643 68.4324 67.0345 68.0946 67.4814C67.7308 67.944 67.5073 68.6767 67.5073 68.6871L66.837 70.9685L66.1562 68.6871C66.0938 68.4792 65.8755 67.8712 65.5689 67.4814C65.2415 67.0449 64.727 66.7747 64.7166 66.7643L63.459 66.1406L64.7166 65.5066C64.7166 65.5066 65.2311 65.2364 65.5689 64.7998C65.9327 64.3217 66.1562 63.6046 66.1562 63.5942L66.8266 61.3127L67.5073 63.5942C67.5697 63.802 67.788 64.4101 68.0946 64.7998C68.4376 65.2364 68.9469 65.5066 68.9469 65.5066L70.1889 66.1406Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M20.2366 3.73655L19.244 4.21986C19.244 4.21986 18.8334 4.42774 18.5632 4.77593C18.2722 5.13451 18.0955 5.70097 18.0955 5.71137L17.5602 7.47831L17.0145 5.71137C16.9678 5.55026 16.7911 5.07735 16.5468 4.77593C16.2818 4.43813 15.8764 4.23026 15.866 4.21986L14.863 3.73655L15.866 3.24805C15.866 3.24805 16.2766 3.04017 16.5468 2.70238C16.8379 2.3334 17.0145 1.77733 17.0145 1.76694L17.5498 0L18.0955 1.76694C18.1423 1.92804 18.319 2.40096 18.5632 2.70238C18.8334 3.04017 19.244 3.24805 19.244 3.24805L20.2366 3.73655Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M10.2691 14.229L8.89194 14.9046C8.89194 14.9046 8.32548 15.1957 7.94611 15.6842C7.54075 16.1883 7.2965 16.9834 7.2965 16.9938L6.55334 19.4727L5.7946 16.9938C5.72704 16.7703 5.48279 16.1051 5.14499 15.6842C4.78121 15.2061 4.20955 14.915 4.19916 14.9046L2.8064 14.229L4.19916 13.5379C4.19916 13.5379 4.76562 13.2468 5.14499 12.7687C5.55035 12.2542 5.7946 11.4695 5.7946 11.4591L6.53775 8.98019L7.2965 11.4591C7.36406 11.6826 7.60831 12.3478 7.94611 12.7687C8.32548 13.2468 8.89194 13.5379 8.89194 13.5379L10.2691 14.229Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M40.7798 20.3302C50.6487 20.3302 59.3015 20.9746 64.1813 21.9412C60.2785 14.2394 51.2359 8.74634 40.7694 8.74634C30.3029 8.74634 21.2292 14.1875 17.3523 21.9464C22.227 20.9798 30.8954 20.3302 40.7798 20.3302Z"
      defaultFill="#E2D3FF"
      color={highlightFill2}
    />
    <Path
      d="M60.3773 20.5433C55.1337 19.8936 48.1699 19.5351 40.7643 19.5351C33.3588 19.5351 26.3949 19.8936 21.1513 20.5433C13.4339 21.4995 12.3738 22.648 12.3738 23.7497C12.3738 24.8515 13.5847 25.553 16.5573 26.2079L16.9314 26.291L17.2277 26.0519C19.6702 24.0667 28.5153 22.8299 40.307 22.8299C52.0987 22.8299 61.1517 24.0823 63.4851 26.1871L63.7761 26.4521L64.1658 26.3794C67.7049 25.6934 69.1497 24.9346 69.1497 23.7601C69.1497 22.6584 68.0843 21.5099 60.3721 20.5537L60.3773 20.5433Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M40.9877 28.5205C51.4178 28.5205 60.7202 29.0973 66.8214 29.9964L64.7738 26.2234C63.2355 23.5211 53.9175 22.0296 40.5304 22.0296C27.1432 22.0296 17.4511 23.6198 16.287 26.2234L14.7019 30.0639C20.7874 29.1233 30.2977 28.5205 40.9825 28.5205H40.9877Z"
      defaultFill="#E2D3FF"
      color={highlightFill2}
    />
    <Path
      d="M41.0345 38.0359C53.4447 38.0359 64.54 38.7063 71.8936 39.7561L69.4614 35.2712C67.6218 32.0387 56.4901 30.2562 40.4889 30.2562C24.4877 30.2562 12.9142 32.1583 11.5163 35.2712L9.63501 39.8341C16.9834 38.7375 28.3178 38.0359 41.0345 38.0359Z"
      defaultFill="#E2D3FF"
      color={highlightFill2}
    />
    <Path
      d="M69.1236 38.1035C61.5466 37.2044 51.4751 36.7055 40.7643 36.7055C30.0535 36.7055 19.9872 37.1992 12.4049 38.1035C0 39.5794 0 41.4503 0 42.2506C0 43.2432 0.784728 44.4125 6.62602 45.5298C10.4665 46.2626 15.892 46.8654 22.3258 47.2604L22.8974 47.2968L23.6198 45.4935C24.6696 44.9634 30.9942 44.0903 40.8007 44.0903C50.6072 44.0903 56.9058 44.9114 57.9867 45.5038L58.6987 47.2916L59.2704 47.2552C65.6833 46.8602 71.0985 46.2574 74.9234 45.5246C80.7439 44.4073 81.5286 43.2432 81.5286 42.2506C81.5286 41.4503 81.5286 39.5794 69.1236 38.1035Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M65.6572 28.8011C59.0988 28.0112 50.3784 27.5798 41.1124 27.5798C31.8463 27.5798 23.1312 28.0112 16.5675 28.8011C5.74243 30.1003 5.74243 31.7373 5.74243 32.5221C5.74243 33.7433 7.28071 34.5904 10.8873 35.3492L11.2927 35.4323L11.5941 35.1517C14.3225 32.6052 25.688 30.963 40.5459 30.963C55.4038 30.963 66.946 32.5948 69.612 35.3232L69.9083 35.6246L70.324 35.5466C74.6946 34.7359 76.4771 33.8577 76.4771 32.5169C76.4771 31.7321 76.4771 30.0951 65.652 28.7959L65.6572 28.8011Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M40.7642 50.9762C51.3494 50.9762 59.9303 49.7686 59.9303 48.279C59.9303 46.7894 51.3494 45.5818 40.7642 45.5818C30.1791 45.5818 21.5981 46.7894 21.5981 48.279C21.5981 49.7686 30.1791 50.9762 40.7642 50.9762Z"
      defaultFill="#B897E8"
      color={highlightFill1}
    />
    <Path
      d="M58.6054 45.0517C58.1896 44.1786 50.6074 43.2952 40.7489 43.2952C30.8904 43.2952 23.1419 44.2202 22.8924 45.0517L21.7595 47.8788C23.1419 46.5796 31.1035 45.587 40.7125 45.587C50.3216 45.587 58.7977 46.6472 59.7799 48.0087L58.6106 45.0517H58.6054Z"
      defaultFill="#702FE6"
      color={fill}
      stroke={fill ?? '#702FE6'}
      strokeWidth="1.53"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M37.4019 76.9917C36.69 76.9917 36.1079 76.4149 36.1079 75.6977V49.5418C36.1079 48.8299 36.6848 48.2478 37.4019 48.2478C38.1191 48.2478 38.696 48.8247 38.696 49.5418V75.6977C38.696 76.4097 38.1191 76.9917 37.4019 76.9917Z"
      defaultFill="#702FE6"
      color={fill}
    />
    <Path
      d="M42.9832 76.9917C42.2713 76.9917 41.6892 76.4149 41.6892 75.6977V49.5418C41.6892 48.8299 42.2661 48.2478 42.9832 48.2478C43.7004 48.2478 44.2773 48.8247 44.2773 49.5418V75.6977C44.2773 76.4097 43.7004 76.9917 42.9832 76.9917Z"
      defaultFill="#702FE6"
      color={fill}
    />
  </SVG>
);
