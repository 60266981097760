import { PrismicNextImage } from '@prismicio/next';
import {
  Box,
  Button,
  ButtonVariant,
  Columns,
  Container,
  Divider,
  Heading,
  Logo,
  Stack,
  Text,
  UnstyledButton,
  UnstyledLink,
  Visible,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FeatherChevronDownIcon,
  FeatherChevronRightIcon,
  FeatherMenuIcon,
  FeatherXIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  boxShadow,
  color,
  fontWeight,
  getColor,
  getSpace,
  height,
  marginLeft,
  marginTop,
  match,
  paddingBottom,
  paddingX,
  ResponsiveValue,
  rgba,
  text,
  transition,
  useBetweenMedia,
  zIndex,
} from '@spaceship-fspl/styles';
import { TrackingAction, TrackingCategory } from '@spaceship-fspl/tracking';
import { Link } from 'components/link';
import { RichText } from 'data/prismic';
import { Header as PrismicHeaderType } from 'data/prismic/types';
import React, { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styled from 'styled-components';

export const HEADER_HEIGHT_MOBILE = getSpace('xxl');
export const HEADER_HEIGHT_DESKTOP = getSpace('xxxl');
const NAV_LINK_MIN_HEIGHT_MOBILE = getSpace('xl');

interface NavItem {
  text?: string;
  url?: string;
  openNewTab?: boolean;
}

interface ChildNavItem extends NavItem {
  icon?: {
    dimensions: {
      width: number;
      height: number;
    };
    alt: string;
    url: string;
  };
  richdescription?: JSON;
}

export interface HeaderProps {
  loginText?: string;
  loginUrl?: string;
  loginOpenNewTab?: boolean;
  signupText?: string;
  signupUrl?: string;
  signupOpenNewTab?: boolean;
  navItems?: Array<
    NavItem & {
      childNavItems?: Array<ChildNavItem>;
    }
  >;
  buttonVariant?: ResponsiveValue<ButtonVariant>;
}

export const mapNodeToHeaderProps = (node: PrismicHeaderType): HeaderProps => {
  return {
    loginText: node.logintext ?? '',
    loginUrl: node.loginurl ?? '',
    loginOpenNewTab: node.loginopennewtab ?? false,
    signupText: node.signuptext ?? '',
    signupUrl: node.signupurl ?? '',
    signupOpenNewTab: node.signupopennewtab ?? false,
    navItems: (node.body ?? []).map((slice) => ({
      text: slice.primary?.text ?? '',
      url: slice.primary?.url ?? '',
      openNewTab: slice.primary?.opennewtab ?? false,
      childNavItems: (slice.fields ?? []).map((item) => ({
        icon: item.icon,
        text: item.text ?? '',
        url: item.url ?? '',
        openNewTab: item.opennewtab ?? false,
        richdescription: item.richdescription,
      })),
    })),
  };
};

const addTrailingSlash = (url?: string): string =>
  url ? url.replace(/\/?$/, '/') : '';

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  loginText,
  loginUrl: prismicLoginUrl,
  loginOpenNewTab = false,
  signupText,
  signupUrl: prismicSignupUrl,
  signupOpenNewTab = false,
  navItems,
  buttonVariant = 'primary',
}) => {
  const isBetweenXsAndMd = useBetweenMedia('xs', 'md');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState<
    number | undefined
  >();
  const selectedChildNavItems =
    selectedNavItemIndex !== undefined
      ? (navItems?.[selectedNavItemIndex]?.childNavItems ?? [])
      : [];
  const loginUrl = prismicLoginUrl
    ? prismicLoginUrl
    : ExternalRoutes.SPACESHIP_LOGIN;
  const signupUrl = prismicSignupUrl
    ? prismicSignupUrl
    : ExternalRoutes.SPACESHIP_SIGNUP;

  const handleMenuButtonClick = (): void => {
    setShowMobileMenu(true);
  };

  const handleCloseButtonClick = (): void => {
    setShowMobileMenu(false);
  };

  return (
    <TrackingCategory category="Header">
      <HeaderBox>
        <Container>
          <Columns alignY="center" spaceX={{ lg: 'sm', xl: 'md' }}>
            <Columns.Column width="min">
              <Link href="/">
                {({ isActive, ...otherProps }): React.ReactElement => (
                  <UnstyledLink {...otherProps} aria-label="Spaceship Home">
                    <Logo variant="dark" size={{ xs: 'sm', lg: 'lg' }} />
                  </UnstyledLink>
                )}
              </Link>
            </Columns.Column>

            <Columns.Column width="max">
              <RemoveScroll enabled={isBetweenXsAndMd && showMobileMenu}>
                <Nav showMobileMenu={showMobileMenu}>
                  <Visible isHidden={{ lg: true }} component="div">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      backgroundColor="neutral.100"
                      paddingX="md"
                    >
                      <Link href="/">
                        {({ isActive, ...otherProps }): React.ReactElement => (
                          <UnstyledLink
                            {...otherProps}
                            aria-label="Spaceship Home"
                          >
                            <Logo
                              variant="light"
                              size={{ xs: 'sm', lg: 'lg' }}
                            />
                          </UnstyledLink>
                        )}
                      </Link>

                      <CloseMenuButton
                        onClick={handleCloseButtonClick}
                        tracking={{
                          action: TrackingAction.Hide,
                        }}
                        aria-label="Close navigation menu"
                      >
                        <FeatherXIcon size="lg" />
                      </CloseMenuButton>
                    </Box>
                  </Visible>

                  <Box
                    alignItems={{ xs: 'stretch', lg: 'center' }}
                    display="flex"
                    flex={{ xs: 1, lg: '0 1 auto' }}
                    flexDirection={{ xs: 'column', lg: 'row' }}
                    justifyContent={{ lg: 'center' }}
                  >
                    {navItems?.map((item, index) => {
                      const hasChildNavItems =
                        !!item.childNavItems && item.childNavItems.length > 0;
                      const isChildNavOpen = selectedNavItemIndex === index;
                      const itemUrl = addTrailingSlash(item.url);

                      return (
                        <React.Fragment key={item.text}>
                          {index !== 0 && <MobileMenuDivider />}

                          {hasChildNavItems ? (
                            <>
                              <Visible isHidden={{ lg: true }}>
                                <Box padding="md">
                                  <Text variant={1} isBold={true}>
                                    {item.text}
                                  </Text>

                                  {item.childNavItems?.map((navItem) => {
                                    const navItemUrl = addTrailingSlash(
                                      navItem.url,
                                    );

                                    if (navItemUrl) {
                                      return (
                                        <Link
                                          href={navItemUrl}
                                          key={navItem.text}
                                        >
                                          {({
                                            isActive,
                                            ...props
                                          }): React.ReactElement => (
                                            <UnstyledLink
                                              {...props}
                                              target={
                                                navItem.openNewTab
                                                  ? '_blank'
                                                  : undefined
                                              }
                                            >
                                              <Box marginTop="sm">
                                                <NavLink
                                                  isActive={isActive}
                                                  isBold={false}
                                                >
                                                  {navItem.text}
                                                  <Visible
                                                    isHidden={{ lg: true }}
                                                  >
                                                    <FeatherChevronRightIcon />
                                                  </Visible>
                                                </NavLink>
                                              </Box>
                                            </UnstyledLink>
                                          )}
                                        </Link>
                                      );
                                    }

                                    return null;
                                  })}
                                </Box>
                              </Visible>

                              <Visible isHidden={{ xs: true, lg: false }}>
                                <UnstyledButton
                                  onClick={() => {
                                    if (isChildNavOpen) {
                                      setSelectedNavItemIndex(undefined);
                                    } else {
                                      setSelectedNavItemIndex(index);
                                    }
                                  }}
                                >
                                  <Box
                                    paddingX={{
                                      xs: 'md',
                                      lg: 'sm',
                                      xl: 'md',
                                    }}
                                    paddingY={{ xs: 'md', lg: 'none' }}
                                  >
                                    <NavLink isActive={false}>
                                      {item.text}
                                      <StyledChevronIconContainer
                                        isChildNavOpen={isChildNavOpen}
                                      >
                                        <FeatherChevronDownIcon />
                                      </StyledChevronIconContainer>
                                    </NavLink>
                                  </Box>
                                </UnstyledButton>
                              </Visible>
                            </>
                          ) : itemUrl ? (
                            <Link href={itemUrl}>
                              {({ isActive, ...props }): React.ReactElement => (
                                <UnstyledLink
                                  {...props}
                                  target={
                                    item.openNewTab ? '_blank' : undefined
                                  }
                                >
                                  <Box
                                    paddingX={{
                                      xs: 'md',
                                      lg: 'sm',
                                      xl: 'md',
                                    }}
                                    paddingY={{ xs: 'md', lg: 'none' }}
                                  >
                                    <NavLink isActive={isActive}>
                                      {item.text}
                                      <Visible isHidden={{ lg: true }}>
                                        <FeatherChevronRightIcon />
                                      </Visible>
                                    </NavLink>
                                  </Box>
                                </UnstyledLink>
                              )}
                            </Link>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                  </Box>

                  <Visible isHidden={{ xs: !loginText, lg: true }}>
                    <MobileMenuDivider />

                    <Box marginTop="md" paddingX={{ xs: 'md', lg: 'none' }}>
                      <UnstyledLink
                        href={loginUrl}
                        target={loginOpenNewTab ? '_blank' : undefined}
                      >
                        <Text
                          variant={1}
                          color="neutral.080"
                          align="center"
                          isBold={true}
                        >
                          {loginText}
                        </Text>
                      </UnstyledLink>
                    </Box>
                  </Visible>

                  <Visible isHidden={{ xs: !signupText, lg: true }}>
                    <Box paddingX={{ xs: 'md', lg: 'none' }}>
                      <MobileSignupButton
                        href={signupUrl}
                        variant={buttonVariant}
                        size="lg"
                        target={signupOpenNewTab ? '_blank' : undefined}
                      >
                        {signupText}
                      </MobileSignupButton>
                    </Box>
                  </Visible>
                </Nav>
              </RemoveScroll>
            </Columns.Column>

            <Columns.Column width="min">
              <Visible isHidden={{ lg: true }}>
                <Box display="flex">
                  <Visible isHidden={!signupText}>
                    <Button
                      href={signupUrl}
                      variant={buttonVariant}
                      size="sm"
                      target={signupOpenNewTab ? '_blank' : undefined}
                    >
                      {signupText}
                    </Button>
                  </Visible>

                  <MenuButton
                    onClick={handleMenuButtonClick}
                    tracking={{
                      action: TrackingAction.Show,
                    }}
                    aria-label="Open navigation menu"
                  >
                    <FeatherMenuIcon size="lg" />
                  </MenuButton>
                </Box>
              </Visible>

              <Visible isHidden={{ xs: true, lg: false }} component="div">
                <Columns alignY="center" spaceX="lg">
                  <Columns.Column width="min">
                    {!!loginText && (
                      <StyledLoginLink
                        href={loginUrl}
                        target={loginOpenNewTab ? '_blank' : undefined}
                      >
                        {loginText}
                      </StyledLoginLink>
                    )}
                  </Columns.Column>

                  {!!signupText && (
                    <Columns.Column width="min">
                      <Button
                        href={signupUrl}
                        variant={buttonVariant}
                        size="sm"
                        target={signupOpenNewTab ? '_blank' : undefined}
                      >
                        {signupText}
                      </Button>
                    </Columns.Column>
                  )}
                </Columns>
              </Visible>
            </Columns.Column>
          </Columns>
        </Container>
      </HeaderBox>

      {selectedChildNavItems.length > 0 && (
        <Visible isHidden={{ xs: true, lg: false }}>
          <Box position="relative">
            <StyledChildNavigationBox
              as="nav"
              onMouseLeave={() => {
                setSelectedNavItemIndex(undefined);
              }}
            >
              <Columns alignX="center">
                <Columns.Column width={{ lg: 1, xl: 2 / 3 }}>
                  <Columns>
                    {selectedChildNavItems.map((item) => {
                      const itemUrl = addTrailingSlash(item.url);

                      return (
                        <StyledChildNavItemColumn width={1 / 2} key={item.text}>
                          <Link href={itemUrl}>
                            {({
                              isActive,
                              ...otherProps
                            }): React.ReactElement => (
                              <StyledChildNavItemLink
                                {...otherProps}
                                target={item.openNewTab ? '_blank' : undefined}
                              >
                                <Box display="flex" flexDirection="row">
                                  {!!item.icon && (
                                    <Box marginRight="sm">
                                      <PrismicNextImage
                                        field={item.icon}
                                        imgixParams={{ auto: [] }}
                                      />
                                    </Box>
                                  )}
                                  <Stack spaceY="xs">
                                    <Heading variant={5}>{item.text}</Heading>
                                    <Text
                                      variant={2}
                                      color="neutral.085"
                                      component="div"
                                    >
                                      <RichText render={item.richdescription} />
                                    </Text>
                                  </Stack>
                                </Box>
                              </StyledChildNavItemLink>
                            )}
                          </Link>
                        </StyledChildNavItemColumn>
                      );
                    })}
                  </Columns>
                </Columns.Column>
              </Columns>
            </StyledChildNavigationBox>
          </Box>
        </Visible>
      )}
    </TrackingCategory>
  );
};

const HeaderBox = styled.header`
  ${backgroundColor('neutral.000')}
  ${height({
    xs: HEADER_HEIGHT_MOBILE,
    lg: HEADER_HEIGHT_DESKTOP,
  })}
  align-items: center;
  display: flex;
`;

const Nav = styled.nav<{ showMobileMenu: boolean }>`
  ${backgroundColor('neutral.000')}
  ${boxShadow('lg')}
  ${paddingBottom('lg')}
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: ${({ showMobileMenu }) => (showMobileMenu ? 0 : 105)}%;
  position: fixed;
  top: 0;
  transition: 0.35s;
  width: 100vw;
  ${zIndex('max')};
  overflow: auto;

  ${match('lg')`
    overflow: unset;
    ${boxShadow('none')}
    ${paddingBottom('none')}
    background: none;
    flex-direction: row;
    height: auto;
    left: auto;
    position: static;
    transition: none;
    top: auto;
    width: auto;
    z-index: auto;
  `}
`;

const NavLink = styled(Text).attrs({
  align: { xs: 'center', lg: 'left' },
  variant: { xs: 1, lg: 2 },
})<{
  isActive?: boolean;
  isBold?: boolean;
}>`
  ${({ isActive }) => color(isActive ? 'indigo.070' : 'neutral.100')}
  align-items: center;
  display: flex;
  justify-content: space-between;
  ${({ isBold = true }) => fontWeight(isBold ? 600 : 400)}
  min-height: ${NAV_LINK_MIN_HEIGHT_MOBILE};
  opacity: ${({ isActive }) => (isActive ? 0.5 : 1)};
  text-decoration: none;

  :hover {
    opacity: 0.55;
  }

  ${match('lg')`
    ${color('neutral.100')}
    display: inline-block;
    font-weight: 600;
  justify-content: center;
    min-height: 0;
    transition: 0.15s opacity;

    :active {
      font-weight: 600;
    }
  `}
`;

const StyledLoginLink = styled(UnstyledLink)`
  ${text({ variant: 2 })}
  ${color('neutral.100')}
  font-weight: 600;

  :hover {
    opacity: 0.55;
  }
`;

const MenuButton = styled(UnstyledButton)`
  ${color('neutral.100')}
  ${marginLeft('sm')}
  background: none;
  border: 0;
  cursor: pointer;
  font-size: 0;
  padding: 0;

  :focus {
    outline: none;
  }
`;

const CloseMenuButton = styled(MenuButton)`
  ${color('neutral.070')}
  ${marginLeft('none')}
  display: block;
  height: ${HEADER_HEIGHT_MOBILE};
`;

const MobileSignupButton = styled(Button)`
  ${marginTop('md')}
  width: 100%;
`;

const MobileMenuDivider = styled(Divider).attrs({
  color: 'neutral.050',
})`
  ${match('lg')`
    display: none;
  `}
`;

const StyledChildNavigationBox = styled(Box).attrs({
  position: 'absolute',
  width: '100%',
  backgroundColor: rgba('neutral.000', 0.95),
  padding: 'lg',
})`
  backdrop-filter: blur(4px);
  z-index: 10;
`;

const StyledChildNavItemColumn = styled(Columns.Column)`
  ${paddingX('lg')}
  border-right: 2px solid ${getColor('neutral.050')};

  :first-child {
    border-left: 2px solid ${getColor('neutral.050')};
  }
`;

const StyledChildNavItemLink = styled(UnstyledLink)`
  :hover {
    opacity: 0.65;
  }
`;

const StyledChevronIconContainer = styled.span<{
  isChildNavOpen: boolean;
}>`
  display: inline-block;
  rotate: ${({ isChildNavOpen }) => (isChildNavOpen ? 180 : 0)}deg;
  ${transition}
`;
