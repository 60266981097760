import {
  Box,
  Button,
  Columns,
  Container,
  Divider,
  Heading,
  PowerUpPanel,
  Stack,
  Text,
  useSetEqualClientHeights,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  Color,
  fontSize,
  fontWeight,
  lineHeight,
  marginBottom,
  marginTop,
  paddingLeft,
  ResponsiveValue,
  rgba,
} from '@spaceship-fspl/styles';
import { Super_HomepageBodyFeesFields } from 'data/prismic/types';
import { productIcons, SuperPrismicPortfolio } from 'helpers/products';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Card, CardVariant } from '../card';
import { PanelSection } from '../panel-section';

const StyledDisclaimer = styled(Text).attrs({
  variant: 3,
  align: 'center',
  color: 'neutral.085',
  component: 'div',
})`
  p {
    ${marginBottom('sm')}
  }
`;

interface SuperFeesPanelProps {
  backgroundColor?: ResponsiveValue<Color>;
  title: string;
  tagline?: string | React.ReactNode;
  disclaimer?: string | React.ReactNode;
  adminFeeWaiver?: string | null;
  adminFeeWaiverDisclaimer?: string | null;
  feeTableTitle: string;
  fees?: Super_HomepageBodyFeesFields[] | null;
  portfolios: Array<{
    type: SuperPrismicPortfolio;
    title: string;
  }>;
}

export const SuperFeesPanel: React.FC<SuperFeesPanelProps> = ({
  title,
  tagline,
  disclaimer,
  adminFeeWaiver,
  adminFeeWaiverDisclaimer,
  feeTableTitle,
  fees,
  portfolios,
  ...props
}) => (
  <PanelSection {...props}>
    <Container>
      <Stack spaceY={{ xs: 'md', md: 'lg', lg: 'xl' }}>
        <Columns alignX="center">
          <Columns.Column width={{ xs: 'min', md: 6 / 8, lg: 1 / 2 }}>
            <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
              <Heading
                component="h2"
                variant={2}
                color="neutral.100"
                align="center"
              >
                {title}
              </Heading>

              {tagline && (
                <Text
                  component="div"
                  variant={1}
                  color="neutral.080"
                  align="center"
                >
                  {tagline}
                </Text>
              )}
            </Stack>
          </Columns.Column>
        </Columns>

        <Columns alignX="center">
          <Columns.Column width={{ lg: 5 / 6 }}>
            <Stack spaceY={{ xs: 'sm', md: 'md', lg: 'lg' }}>
              <Columns
                spaceX={{ xs: 'none', md: 'md' }}
                spaceY={{ xs: 'sm', md: 'none' }}
              >
                {portfolios.map((portfolio) => {
                  const Icon = productIcons[portfolio.type];
                  return (
                    <Columns.Column
                      key={portfolio.type}
                      width={{ xs: 1, md: 1 / 2 }}
                    >
                      <Box
                        paddingY={{ xs: 'xl', lg: 'xxl' }}
                        paddingX={{ xs: 'lg', lg: 'xl' }}
                        backgroundColor="neutral.000"
                        borderRadius="md"
                        height="100%"
                      >
                        <Stack spaceY="md">
                          <Stack spaceY="xs" alignX="center">
                            <Icon color="indigo.070" size="xxxl" />

                            <Heading
                              variant={4}
                              component="h3"
                              color="neutral.100"
                              align="center"
                            >
                              {portfolio.title}
                            </Heading>
                          </Stack>

                          {fees?.map((f, index) => {
                            let fee: string | undefined;
                            let feeNote: string | undefined;
                            switch (portfolio.type) {
                              case 'growthx':
                                fee = f.growthx_portfolio_fee ?? undefined;
                                feeNote =
                                  f.growthx_portfolio_fee_note ?? undefined;
                                break;

                              case 'global_index':
                                fee = f.index_portfolio_fee ?? undefined;
                                feeNote =
                                  f.index_portfolio_fee_note ?? undefined;
                                break;

                              default:
                                break;
                            }

                            return (
                              <Fragment key={f.fee_type}>
                                <Divider
                                  color="neutral.050"
                                  size={index === 0 ? 'md' : 'sm'}
                                />
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Text
                                    variant={{ xs: 1, md: 2, lg: 1 }}
                                    color="neutral.085"
                                  >
                                    {f.fee_type}
                                  </Text>
                                  <Text
                                    variant={{ xs: 1, md: 2, lg: 1 }}
                                    color="neutral.100"
                                    align="right"
                                  >
                                    {fee}
                                  </Text>
                                </Box>
                                {feeNote && (
                                  <Text variant={3} color="neutral.080">
                                    {feeNote}
                                  </Text>
                                )}
                              </Fragment>
                            );
                          })}
                        </Stack>
                      </Box>
                    </Columns.Column>
                  );
                })}
              </Columns>
            </Stack>
          </Columns.Column>
        </Columns>

        {Boolean(adminFeeWaiver) && (
          <Columns alignX="center">
            <Columns.Column width={{ lg: 5 / 6 }}>
              <Card
                variant="blueGradient"
                paddingX={'lg'}
                paddingY={{ xs: 'lg', md: 'sm' }}
              >
                <Columns alignY="center" spaceY="md">
                  <Columns.Column width={{ xs: 1, md: 6 / 12, lg: 7 / 12 }}>
                    <Heading
                      variant={5}
                      color="neutral.000"
                      align={{ xs: 'center', md: 'left' }}
                    >
                      {adminFeeWaiver}
                    </Heading>
                  </Columns.Column>
                  <Columns.Column width={{ xs: 1, md: 6 / 12, lg: 5 / 12 }}>
                    <Box
                      display="flex"
                      justifyContent={{ xs: 'center', md: 'flex-end' }}
                    >
                      <Button
                        variant="tertiary"
                        size="lg"
                        href={ExternalRoutes.SUPER_SIGNUP}
                      >
                        Sign up
                      </Button>
                    </Box>
                  </Columns.Column>
                </Columns>
              </Card>
            </Columns.Column>
          </Columns>
        )}
        {Boolean(disclaimer) && (
          <Columns alignX="center">
            <Columns.Column width={{ lg: 5 / 6 }}>
              <StyledDisclaimer>{disclaimer}</StyledDisclaimer>
            </Columns.Column>
          </Columns>
        )}
      </Stack>
    </Container>
  </PanelSection>
);

export type PortfolioInfo = {
  title: string;
  accountFee: string;
  accountFeeBalanceCopy: string;
  accountFeeAllPorfoliosCopy: string;
  managementFee: string;
  managementFeeCopy: string;
};

const StyledDescriptionText = styled(Text).attrs({
  variant: 2,
  align: { xs: 'center', md: 'left' },
  component: 'div',
})<{ checkColor: Color }>`
  ul {
    margin: 0;
    ${marginTop('md')}
  }

  li {
    list-style-image: ${({ checkColor }) =>
      `url('data:image/svg+xml,<svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="${rgba(checkColor, 1)}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0, 6)"><path d="M20 6L9 17L4 12" /></g></svg>');`};
    text-align: left;
    ${marginTop('sm')}
    ${paddingLeft('xs')}
    ${fontSize('18px')}
    ${fontWeight(400)}
    ${lineHeight('26px')}
  }
`;

export const FeeCardText = styled(Text).attrs({
  variant: 1,
})`
  ${fontSize('18px')}
  font-weight: ${({ isBold }) => (isBold ? 700 : 500)};
  ${lineHeight('28px')}
`;

export const FeeCard: React.FC<{
  accountFee: string;
  accountFeeCopy: React.ReactNode;
  otherFee: string;
  features?: Array<string>;
  variant?: 'default' | 'portfolio' | 'powerUp';
}> = ({
  accountFee,
  accountFeeCopy,
  otherFee,
  variant = 'default',
  features = [
    'Make unlimited investments.',
    'Create unlimited investment plans.',
    'Invest in one, two or all five Spaceship Voyager portfolios for one monthly fee.',
  ],
}) => {
  const powerUp = variant === 'powerUp';
  const textColor: Color = powerUp ? 'neutral.000' : 'neutral.100';

  let cardVariant: CardVariant;
  switch (variant) {
    case 'default': {
      cardVariant = 'neutral030';
      break;
    }
    case 'portfolio': {
      cardVariant = 'neutral050';
      break;
    }

    case 'powerUp': {
      cardVariant = 'dark';
      break;
    }
  }

  return (
    <Card
      variant={cardVariant}
      paddingX={{ xs: 'lg', md: 'lg', lg: 'xl' }}
      paddingTop="md"
      paddingBottom="xl"
      fullHeight
      fullWidth
    >
      <Stack spaceY="md">
        <Heading variant={2} color={textColor}>
          {accountFee}
        </Heading>
        {React.isValidElement(accountFeeCopy) ? (
          accountFeeCopy
        ) : (
          <FeeCardText color={textColor}>{accountFeeCopy}</FeeCardText>
        )}
        <FeeCardText variant={1} color={textColor} isBold>
          {otherFee}
        </FeeCardText>
        <Divider color="indigo.040" size="md" />
      </Stack>

      <StyledDescriptionText
        color={textColor}
        checkColor={powerUp ? 'neutral.000' : 'indigo.070'}
      >
        <ul>
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </StyledDescriptionText>

      {powerUp ? (
        <Box marginTop="xl">
          <PowerUpPanel>
            <Text
              variant={2}
              isBold={true}
              align={'center'}
              color="neutral.000"
            >
              If you’ve paid the Spaceship Voyager monthly fee in the last
              month, you’ll get complete access to US Investing for no
              additional monthly fee.
            </Text>
          </PowerUpPanel>
        </Box>
      ) : null}
    </Card>
  );
};

export const VoyagerNewFeesCards: React.FC<{
  portfolios: PortfolioInfo[];
}> = ({ portfolios }) => {
  const copyRefOne = React.useRef<HTMLDivElement>(null);
  const copyRefTwo = React.useRef<HTMLDivElement>(null);

  useSetEqualClientHeights([copyRefOne, copyRefTwo]);

  return (
    <Columns spaceY="lg" spaceX="lg" alignX="center">
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <FeeCard
          accountFee="$0 / month"
          accountFeeCopy={
            <Box ref={copyRefOne}>
              <FeeCardText color="neutral.100">
                When your Spaceship Voyager portfolios <b>all</b> have balances
                of <b>less than $100</b>.
              </FeeCardText>
            </Box>
          }
          otherFee="Plus 0.15%-0.50% / year management fee per portfolio."
        />
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <FeeCard
          accountFee={portfolios[0]?.accountFee ?? ''}
          accountFeeCopy={
            <Box ref={copyRefTwo}>
              <FeeCardText color="neutral.000">
                When you have <b>any</b> Spaceship Voyager portfolio with a
                balance of <b>$100 or more.</b>
              </FeeCardText>
            </Box>
          }
          otherFee="Plus 0.15%-0.50% / year management fee per portfolio."
          variant="powerUp"
        />
      </Columns.Column>
    </Columns>
  );
};

export interface VoyagerFeesPanelProps {
  backgroundColor?: ResponsiveValue<Color>;
  title: string;
  tagline?: string | React.ReactNode;
  disclaimer?: string | React.ReactNode;
  portfolios: Array<PortfolioInfo>;
}

export const VoyagerFeesPanel: React.FC<VoyagerFeesPanelProps> = ({
  title,
  tagline,
  disclaimer,
  portfolios,
  ...props
}) => {
  return (
    <PanelSection {...props}>
      <Container>
        <Stack spaceY={{ xs: 'md', md: 'lg', lg: 'xl' }}>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 'min', md: 6 / 8, lg: 1 / 2 }}>
              <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
                <Heading
                  component="h2"
                  variant={2}
                  color="neutral.100"
                  align="center"
                >
                  {title}
                </Heading>

                {tagline && (
                  <Text
                    component="div"
                    variant={1}
                    color="neutral.080"
                    align="center"
                  >
                    {tagline}
                  </Text>
                )}
              </Stack>
            </Columns.Column>
          </Columns>

          <VoyagerNewFeesCards portfolios={portfolios} />

          {Boolean(disclaimer) && (
            <Columns alignX="center">
              <Columns.Column width={{ xs: 'min', md: 4 / 5 }}>
                <Text
                  variant={2}
                  align="center"
                  color="neutral.085"
                  component="div"
                >
                  {disclaimer}
                </Text>
              </Columns.Column>
            </Columns>
          )}
        </Stack>
      </Container>
    </PanelSection>
  );
};

export const VoyagerNewFeesForPortfolioCards: React.FC<{
  portfolio?: PortfolioInfo;
}> = ({ portfolio }) => {
  const copyRefOne = React.useRef<HTMLDivElement>(null);
  const copyRefTwo = React.useRef<HTMLDivElement>(null);

  useSetEqualClientHeights([copyRefOne, copyRefTwo]);

  return (
    <Columns spaceY="lg" spaceX="lg" alignX="center">
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <FeeCard
          accountFee="$0 / month"
          accountFeeCopy={
            <Box ref={copyRefOne}>
              <FeeCardText color="neutral.100">
                When your Spaceship Voyager portfolios <b>all</b> have balances
                of <b>less than $100</b>.
              </FeeCardText>
            </Box>
          }
          otherFee={`Plus ${portfolio?.managementFee} management fee for the ${portfolio?.title}.`}
          variant="portfolio"
        />
      </Columns.Column>
      <Columns.Column width={{ xs: 1, lg: 1 / 2 }}>
        <FeeCard
          accountFee={portfolio?.accountFee ?? ''}
          accountFeeCopy={
            <Box ref={copyRefTwo}>
              <FeeCardText color="neutral.000">
                When you have <b>any</b> Spaceship Voyager portfolio with a
                balance of <b>$100 or more.</b>
              </FeeCardText>
            </Box>
          }
          otherFee={`Plus ${portfolio?.managementFee} management fee for the ${portfolio?.title}.`}
          variant="powerUp"
        />
      </Columns.Column>
    </Columns>
  );
};

export interface VoyagerFeesForPortfolioPanelProps {
  backgroundColor?: ResponsiveValue<Color>;
  title: string;
  tagline?: string | React.ReactNode;
  disclaimer?: string | React.ReactNode;
  portfolio?: PortfolioInfo;
}

export const VoyagerFeesForPortfolioPanel: React.FC<
  VoyagerFeesForPortfolioPanelProps
> = ({ title, tagline, disclaimer, portfolio, ...props }) => {
  return (
    <PanelSection {...props}>
      <Container>
        <Stack spaceY={{ xs: 'md', md: 'lg', lg: 'xl' }}>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 'min', md: 6 / 8, lg: 1 / 2 }}>
              <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
                <Heading
                  component="h2"
                  variant={2}
                  color="neutral.100"
                  align="center"
                >
                  {title}
                </Heading>

                {tagline && (
                  <Text
                    component="div"
                    variant={1}
                    color="neutral.080"
                    align="center"
                  >
                    {tagline}
                  </Text>
                )}
              </Stack>
            </Columns.Column>
          </Columns>

          <VoyagerNewFeesForPortfolioCards portfolio={portfolio} />

          {Boolean(disclaimer) && (
            <Columns alignX="center">
              <Columns.Column width={{ xs: 'min', md: 4 / 5 }}>
                <Text
                  variant={2}
                  align="center"
                  color="neutral.085"
                  component="div"
                >
                  {disclaimer}
                </Text>
              </Columns.Column>
            </Columns>
          )}
        </Stack>
      </Container>
    </PanelSection>
  );
};
