import {
  Box,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { match } from '@spaceship-fspl/styles';
import { MobileOptions } from 'components/mobile-options';
import {
  SuperPrismicPortfolio,
  VoyagerPrismicPortfolio,
} from 'helpers/products';
import React from 'react';
import styled, { css } from 'styled-components';

import { PanelSection } from '../panel-section';
import {
  AssetAllocation as AssetAllocationType,
  AssetAllocationCard,
} from './asset-allocation-card';
import { PortfolioCard } from './portfolio-card';
import SuperBackground from './super-background.svg';
import VoyagerBackground from './voyager-background.svg';

export type AssetAllocation = AssetAllocationType;

const BackgroundContainer = styled.div<{
  variant: 'voyager' | 'super';
}>`
  ${({ variant }) => css`
    ${match('md')`
      background-image: url(${
        variant === 'voyager' ? VoyagerBackground : SuperBackground
      });
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: ${variant === 'voyager' ? 'contain' : '100%'};
    `}
    ${match('lg')`
      background-size: ${variant === 'voyager' ? 'contain' : '90%'};
    `};
    ${match('xl')`
      background-size: ${variant === 'voyager' ? 'contain' : '1200px'};
    `};
  `}
`;

export interface PortfolioOptionsPanelProps<T> {
  variant: 'voyager' | 'super';
  title: string;
  tagline: string;
  portfolios?: Array<{
    type: T;
    name: string;
    title: string;
    subtitle?: string;
    description: string;
    productSlug: string;
    assetAllocationsTitle?: string;
    assetAllocations?: AssetAllocation[];
  }>;
  selectedPortfolio: T;
  onPortfolioChange: (value: T) => void;
}

export function PortfolioOptionsPanel<
  T extends VoyagerPrismicPortfolio | SuperPrismicPortfolio,
>({
  variant,
  title,
  tagline,
  portfolios,
  selectedPortfolio,
  onPortfolioChange,
}: PortfolioOptionsPanelProps<T>): React.ReactElement {
  const isVoyager = variant === 'voyager';
  const hasMoreThanTwoPorfolios =
    (portfolios && portfolios?.length > 2) || false;

  const PortfolioCards: React.FC<React.PropsWithChildren> = () => (
    <Columns alignX="center">
      <Columns.Column
        width={{
          xs: 1,
          md: hasMoreThanTwoPorfolios ? 7 / 8 : 1,
          lg: hasMoreThanTwoPorfolios ? 1 : 10 / 12,
        }}
      >
        <Columns
          spaceX={{
            xs: 'none',
            md: 'lg',
            lg: hasMoreThanTwoPorfolios ? 'md' : 'xl',
          }}
          spaceY={
            hasMoreThanTwoPorfolios && isVoyager
              ? { xs: 'none', md: 'md', lg: 'none' }
              : 'none'
          }
        >
          {portfolios?.map(
            (
              {
                type,
                title: portfolioTitle,
                subtitle,
                description: portfolioDescription,
                productSlug,
                assetAllocations,
                assetAllocationsTitle,
              },
              index,
            ) => (
              <Columns.Column
                width={hasMoreThanTwoPorfolios ? { xs: 1, lg: 1 / 3 } : 1 / 2}
                key={type}
              >
                <Stack spaceY={{ md: 'sm', lg: 'md' }}>
                  <Box
                    marginBottom={
                      isVoyager && !hasMoreThanTwoPorfolios && index === 0
                        ? 'xxxl'
                        : 'none'
                    }
                    marginTop={
                      isVoyager && !hasMoreThanTwoPorfolios && index !== 0
                        ? 'xxl'
                        : 'none'
                    }
                  >
                    <PortfolioCard
                      variant={variant}
                      type={type}
                      title={portfolioTitle}
                      subtitle={subtitle || ''}
                      productSlug={productSlug}
                      description={portfolioDescription || ''}
                      index={index}
                      numOfPortfolios={portfolios?.length}
                    />
                  </Box>

                  {assetAllocations && (
                    <AssetAllocationCard
                      title={assetAllocationsTitle || ''}
                      assetAllocations={assetAllocations}
                    />
                  )}
                </Stack>
              </Columns.Column>
            ),
          )}
        </Columns>
      </Columns.Column>
    </Columns>
  );

  return (
    <PanelSection backgroundColor="indigo.100">
      <Stack spaceY={{ xs: 'lg', lg: 'xl' }}>
        <Container>
          <Columns alignX="center">
            <Columns.Column width={{ xs: 1, md: 6 / 8, lg: 8 / 12 }}>
              <Stack spaceY={{ xs: 'lg', lg: 'xl' }}>
                <Stack spaceY={{ xs: 'sm', lg: isVoyager ? 'md' : 'xs' }}>
                  <Heading
                    component="h2"
                    variant={2}
                    align="center"
                    color="neutral.000"
                  >
                    {title}
                  </Heading>

                  <Text variant={1} align="center" color="neutral.070">
                    {tagline}
                  </Text>
                </Stack>
              </Stack>
            </Columns.Column>
          </Columns>
        </Container>

        <Visible
          isHidden={{ xs: true, md: false }}
          displayValue="block"
          component="div"
        >
          <BackgroundContainer variant={variant}>
            {hasMoreThanTwoPorfolios ? (
              <Box display="flex" justifyContent="center">
                <Box
                  maxWidth={{ xs: 'auto', md: 665, lg: 1200 }}
                  paddingX={{ xs: 'none', md: 'md', lg: 'none' }}
                >
                  <PortfolioCards />
                </Box>
              </Box>
            ) : (
              <Container>
                <PortfolioCards />
              </Container>
            )}
          </BackgroundContainer>
        </Visible>

        {!!portfolios && portfolios.length > 0 && (
          <Visible isHidden={{ md: true }} displayValue="block" component="div">
            <MobileOptions
              variant="dark"
              options={
                portfolios.map((portfolio) => ({
                  ...portfolio,
                  label: portfolio.name,
                  value: portfolio.type,
                })) ?? []
              }
              selectedOption={selectedPortfolio}
              onChange={(value) => onPortfolioChange(value)}
              renderOption={(props) => (
                <Stack spaceY="sm">
                  <PortfolioCard
                    variant={variant}
                    title={props.title}
                    subtitle={props.subtitle ?? ''}
                    type={props.type}
                    description={props.description ?? ''}
                    productSlug={props.productSlug}
                    numOfPortfolios={portfolios.length}
                  />

                  {props.assetAllocations && props.assetAllocationsTitle && (
                    <AssetAllocationCard
                      title={props.assetAllocationsTitle}
                      assetAllocations={props.assetAllocations}
                      isActive={props.type === selectedPortfolio}
                    />
                  )}
                </Stack>
              )}
            />
          </Visible>
        )}
      </Stack>
    </PanelSection>
  );
}
