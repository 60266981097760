import { useScrollPosition } from '@spaceship-fspl/helpers';
import {
  backgroundColor,
  boxShadow,
  height,
  match,
  zIndex,
} from '@spaceship-fspl/styles';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  Header,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
  HeaderProps,
} from '../header';

const StickyHeaderContainer = styled.div<{ isSticky: boolean }>`
  transition: top 0.2s ease-in;

  ${({ isSticky }) =>
    isSticky
      ? css`
          ${boxShadow('md')}
          ${zIndex('max')}
          left: 0;
          position: fixed;
          top: 0;
          width: 100%;

          ${match('lg')`
            ${backgroundColor('neutral.000')}
          `}
        `
      : css`
          position: static;
          top: -${HEADER_HEIGHT_MOBILE};

          ${match('lg')`
            top: -${HEADER_HEIGHT_DESKTOP};
          `}
        `}
`;

// In Firefox, elements jump around without this
const StickyHeaderPlaceholder = styled.div`
  ${height({
    xs: HEADER_HEIGHT_MOBILE,
    lg: HEADER_HEIGHT_DESKTOP,
  })}
`;

export const StickyHeader: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  ...props
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);

  useScrollPosition(
    ({ curr, prev }) => {
      if (stickyHeaderRef.current) {
        const scrollUp =
          curr.y > stickyHeaderRef.current.getBoundingClientRect().bottom &&
          curr.y < prev.y;

        if (isSticky !== scrollUp) {
          setIsSticky(scrollUp);
        }
      }
    },
    [isSticky],
    250,
  );

  return (
    <>
      <StickyHeaderContainer ref={stickyHeaderRef} isSticky={isSticky}>
        <Header {...props} />
      </StickyHeaderContainer>

      {isSticky && <StickyHeaderPlaceholder />}
    </>
  );
};
