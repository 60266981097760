import React, { useState } from 'react';

import { Box } from '../box';
import { Text } from '../text';
import {
  InnerWrapper,
  Label,
  LeftIconWrapper,
  RightIconWrapper,
  StyledInput,
} from './index.style';

export type TextInputVariant = 'light' | 'dark';

export interface TextInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'label' | 'placeholder' | 'type' | 'disabled' | 'defaultValue' | 'value'
  > {
  variant: TextInputVariant;
  id: string;
  label: string;
  errorMessage?: string;
  helpMessage?: string;
  value?: string;
  placeholder?: string;
  defaultValue?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  before?: React.ReactNode;
  after?: React.ReactNode;
  className?: string;
}

export const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> = ({
  variant,
  id,
  label,
  errorMessage,
  helpMessage,
  value,
  defaultValue,
  isRequired = false,
  isDisabled = false,
  before,
  after,
  onFocus,
  onBlur,
  className,
  ...props
}) => {
  const [isFocusedAndNotEmpty, setFocusAndNotEmptyState] = useState<boolean>(
    (!!value && value !== '') || (!!defaultValue && defaultValue !== ''),
  );

  const onInputFocus = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void | undefined => {
    setFocusAndNotEmptyState(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    setFocusAndNotEmptyState(e.target.value?.length !== 0);

    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <InnerWrapper className={className}>
      <Label
        htmlFor={id}
        variant={variant}
        isFocusedAndNotEmpty={isFocusedAndNotEmpty}
        isDisabled={isDisabled}
        before={!!before}
        isHidden={Boolean(errorMessage)}
        isInvalid={false}
      >
        {label}
      </Label>
      {errorMessage && (
        <Label
          as="span"
          id={`${id}__error`}
          variant={variant}
          isFocusedAndNotEmpty={isFocusedAndNotEmpty}
          isDisabled={isDisabled}
          before={!!before}
          isInvalid={true}
        >
          {errorMessage}
        </Label>
      )}
      {before && <LeftIconWrapper>{before}</LeftIconWrapper>}
      <StyledInput
        id={id}
        aria-invalid={errorMessage ? 'true' : 'false'}
        aria-describedby={[
          errorMessage && `${id}__error`,
          helpMessage && `${id}__hint`,
        ]
          .filter((v) => v !== undefined)
          .join(' ')}
        className={className}
        variant={variant}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        type="text"
        isFocusedAndNotEmpty={isFocusedAndNotEmpty}
        isInvalid={Boolean(errorMessage)}
        isDisabled={isDisabled}
        disabled={isDisabled}
        required={isRequired}
        value={value}
        defaultValue={defaultValue}
        before={!!before}
        after={!!after}
        {...props}
      />
      {helpMessage && (
        <Box marginTop="xxs">
          <Text variant={4} color="neutral.080" id={`${id}__hint`}>
            {helpMessage}
          </Text>
        </Box>
      )}
      {after && <RightIconWrapper>{after}</RightIconWrapper>}
    </InnerWrapper>
  );
};
