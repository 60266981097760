import React from 'react';

import { Box } from '../box';
import { Text } from '../text';

export const Placeholder: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Box backgroundColor="neutral.030" padding="xxxs">
    <Text variant={2} align="center">
      {children}
    </Text>
  </Box>
);
