import {
  Box,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import {
  Color,
  getColor,
  paddingX,
  paddingY,
  ResponsiveValue,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import { MobileOptions } from 'components/mobile-options';
import {
  PerformanceCard,
  PerformanceCardProps,
} from 'components/performance-card';
import {
  SuperPrismicPortfolio,
  VoyagerPrismicPortfolio,
} from 'helpers/products';
import React from 'react';
import styled from 'styled-components';

import { PanelSection } from '../panel-section';

type PortfolioData<T> = Omit<PerformanceCardProps<T>, 'variant'> & {
  name: string;
};

export interface PortfolioPerformancePanelProps<T> {
  backgroundColor?: ResponsiveValue<Color>;
  title: string;
  disclaimer: React.ReactNode;
  portfolios?: Array<PortfolioData<T>>;
  selectedPortfolio: T;
  onPortfolioChange: (value: T) => void;
}

export function PortfolioPerformancePanel<
  T extends VoyagerPrismicPortfolio | SuperPrismicPortfolio,
>({
  title,
  disclaimer,
  portfolios,
  selectedPortfolio,
  onPortfolioChange,
  ...props
}: PortfolioPerformancePanelProps<T>): React.ReactElement {
  return (
    <TrackingCategory category="Performance Panel">
      <PanelSection {...props}>
        <Stack spaceY="lg">
          <Container>
            <Heading variant={2} align="center">
              {title}
            </Heading>
          </Container>

          {!!portfolios && portfolios.length > 0 && (
            <>
              <Visible isHidden={{ md: true }} displayValue="block">
                <MobileOptions
                  options={portfolios.map((portfolio) => ({
                    ...portfolio,
                    label: portfolio.name ?? '',
                    value: portfolio.type ?? '',
                  }))}
                  selectedOption={selectedPortfolio}
                  onChange={(value) => onPortfolioChange(value)}
                  variant="light"
                  renderOption={(props) => (
                    <Box paddingTop="sm" paddingBottom="md">
                      <PerformanceCard variant="dark" {...props} />
                    </Box>
                  )}
                />
              </Visible>

              <Visible isHidden={{ xs: true, md: false }} displayValue="block">
                <StyledPortfolioContainer>
                  {portfolios.map((portfolio) => (
                    <PerformanceCard
                      {...portfolio}
                      key={portfolio.type}
                      variant="dark"
                    />
                  ))}
                </StyledPortfolioContainer>
              </Visible>
            </>
          )}
          <Container>
            <Columns>
              <Columns.Column
                offset={{ xs: 0, lg: 1 / 12 }}
                width={{ xs: 1, lg: 10 / 12 }}
              >
                <Text
                  variant={3}
                  color="neutral.085"
                  align="center"
                  component="div"
                >
                  {disclaimer}
                </Text>
              </Columns.Column>
            </Columns>
          </Container>
        </Stack>
      </PanelSection>
    </TrackingCategory>
  );
}

const StyledPortfolioContainer = styled.div`
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  ${paddingX({ md: 'sm', xl: 'xxxl' })}
  ${paddingY('lg')}

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${getColor('neutral.015')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${getColor('neutral.015')};
    border-radius: 10px;
  }

  :hover::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: ${getColor('neutral.050')};
  }
`;
