import {
  Box,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  borderColor,
  borderWidth,
  borderWidthLeft,
  borderWidthTop,
  paddingX,
  paddingY,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import React from 'react';
import styled from 'styled-components';

import { PanelSection } from '../panel-section';

const RiskIcon = styled.img`
  height: 58px;
  max-width: 100%;
  width: 58px;
`;

const SuperRiskBox = styled(Box)`
  ${borderColor('neutral.050')}
  ${borderWidth('none')}
  ${paddingX({ xs: 'none', md: 'lg' })}
  ${paddingY({ xs: 'sm', md: 'xxs' })}
  border-style: solid;

  :not(:first-child) {
    ${borderWidthLeft({ xs: 'none', md: 'md' })}
    ${borderWidthTop({ xs: 'md', md: 'none' })}
  }
`;

export interface Risk {
  iconURL?: string;
  name: string;
  description: React.ReactNode;
}

const RisksWithIcons: React.FC<{ risks: Risk[] }> = ({ risks }) => (
  <Columns
    spaceX={{ xs: 'none', md: 'sm', lg: 'lg' }}
    spaceY={{ xs: 'md', md: 'xl' }}
    alignX="center"
  >
    {risks.map(({ iconURL, name, description }) => (
      <Columns.Column key={name} width={{ xs: 1, md: 1 / 3 }}>
        <Columns
          spaceX={{ xs: 'md', md: 'none' }}
          spaceY={{ xs: 'none', md: 'sm' }}
        >
          <Columns.Column width={{ xs: 1 / 4, md: 1 }}>
            <Box display="flex" justifyContent="center">
              <RiskIcon src={iconURL} alt={`${name} icon`} />
            </Box>
          </Columns.Column>

          <Columns.Column width={{ xs: 3 / 4, md: 1 }}>
            <Stack spaceY={{ xs: 'xxs', lg: 'xs' }}>
              <Text
                variant={1}
                color="neutral.100"
                align={{ xs: 'left', md: 'center' }}
              >
                {name}
              </Text>
              <Text
                variant={2}
                color="neutral.085"
                align={{ xs: 'left', md: 'center' }}
                component="div"
              >
                {description}
              </Text>
            </Stack>
          </Columns.Column>
        </Columns>
      </Columns.Column>
    ))}
  </Columns>
);

const SuperRisks: React.FC<React.PropsWithChildren<{ risks: Risk[] }>> = ({
  risks,
}) => (
  <Box
    backgroundColor="neutral.015"
    borderRadius="md"
    display="flex"
    flexDirection={{ xs: 'column', md: 'row' }}
    paddingX={{ xs: 'md', md: 'none', lg: 'sm' }}
    paddingY={{ xs: 'xxs', md: 'lg' }}
  >
    {risks.map(({ name, description }, index) => (
      <SuperRiskBox key={name} flex={1 / (index === risks.length - 1 ? 2 : 4)}>
        <Stack spaceY={{ xs: 'xxs', lg: 'xs' }}>
          <Text variant={2} color="neutral.080" align="center">
            {name}
          </Text>
          <Text variant={1} color="neutral.100" align="center">
            {description}
          </Text>
        </Stack>
      </SuperRiskBox>
    ))}
  </Box>
);

export interface RiskPanelProps {
  variant: 'voyager' | 'super' | 'nova';
  title: string;
  tagline: React.ReactNode;
  risks?: Risk[];
  disclaimer?: React.ReactNode;
}

export const RiskPanel: React.FC<React.PropsWithChildren<RiskPanelProps>> = ({
  variant,
  title,
  tagline,
  risks,
  disclaimer,
}) => {
  return (
    <TrackingCategory category="Risks">
      <PanelSection>
        <Container>
          <Columns alignX="center" spaceY={{ xs: 'lg', lg: 'xl' }}>
            <Columns.Column width={{ xs: 1, md: 6 / 8, lg: 8 / 12 }}>
              <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
                <Heading component="h2" variant={2} align="center">
                  {title}
                </Heading>

                <Text
                  variant={1}
                  align="center"
                  color="neutral.080"
                  component="div"
                >
                  {tagline}
                </Text>
              </Stack>
            </Columns.Column>

            {risks &&
              (variant === 'voyager' || variant === 'nova' ? (
                <Columns.Column>
                  <RisksWithIcons risks={risks} />
                </Columns.Column>
              ) : (
                <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
                  <SuperRisks risks={risks} />
                </Columns.Column>
              ))}

            {disclaimer && (
              <Columns.Column width={{ xs: 1, lg: 8 / 12 }}>
                <Text
                  variant={3}
                  color="neutral.085"
                  align="center"
                  component="div"
                >
                  {disclaimer}
                </Text>
              </Columns.Column>
            )}
          </Columns>
        </Container>
      </PanelSection>
    </TrackingCategory>
  );
};
