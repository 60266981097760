import { useScrollPosition } from '@spaceship-fspl/helpers';
import { backgroundColor } from '@spaceship-fspl/styles';
import * as React from 'react';
import styled from 'styled-components';

export const PROGRESS_BAR_HEIGHT = 3;

const GreyBackground = styled.div`
  ${backgroundColor('neutral.050')}
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: ${PROGRESS_BAR_HEIGHT}px;
  ${backgroundColor('indigo.060')}
  width: ${({ progress }) => `${progress}%`};
`;

export const ReadingProgressBar: React.FC<
  React.PropsWithChildren<{
    target: React.RefObject<HTMLDivElement> | null;
  }>
> = ({ target }) => {
  const [readingProgress, setReadingProgress] = React.useState<number>(0);
  useScrollPosition(
    ({ curr }) => {
      if (!target || !target.current) {
        return;
      }

      const element = target.current;
      const elementTop = element.offsetTop;
      const scrollBottom = curr.y + window.innerHeight;
      const maxHeight = element.clientHeight;

      const newReadingProgress = Math.min(
        100,
        Math.max(0, ((scrollBottom - elementTop) / maxHeight) * 100),
      );

      if (newReadingProgress !== readingProgress) {
        setReadingProgress(newReadingProgress);
      }
    },
    [readingProgress],
    16,
  );

  return (
    <GreyBackground>
      <ProgressBar progress={readingProgress} />
    </GreyBackground>
  );
};
