import {
  Box,
  Button,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
  Visible,
} from '@spaceship-fspl/components';
import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  EtfProductIcon,
  StocksProductIcon,
  VoyagerProductStarsIcon,
} from '@spaceship-fspl/icons-web';
import {
  backgroundColor,
  color,
  fontWeight,
  getSpace,
  height,
  left,
  linearGradient,
  LinearGradientConfig,
  match,
  maxHeight,
  maxWidth,
  paddingBottom,
  paddingX,
  text,
  width,
} from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import { HeaderProps, SuperFHSSIcon } from 'components';
import React, { HTMLAttributeAnchorTarget } from 'react';
import styled, { css } from 'styled-components';

import boostsHeroDesktopImage from './boosts-hero-desktop.svg';
import boostsHeroMobileImage from './boosts-hero-mobile.svg';
import fhssDesktopImage from './fhss-desktop.svg';
import firstHomeDesktopImage from './first-home-desktop.svg';
import firstHomeMobileImage from './first-home-mobile.svg';
import heroDesktopImage from './hero-desktop.svg';
import novaHeroDesktopImage from './nova-hero-desktop.png';

export type HeroPanelVariant =
  | 'voyager'
  | 'boosts'
  | 'fhss'
  | 'nova'
  | 'first-home'
  | 'first-home-state';

const voyagerGradient: LinearGradientConfig = {
  alpha: 0.95,
  colorStops: [
    ['indigo.050', '0.07%'],
    ['red.020', '108.95%'],
  ],
  angle: '53.6deg',
};

const novaGradient: LinearGradientConfig = {
  alpha: 1,
  colorStops: [
    ['indigo.100', '0%'],
    ['product.etf', '100%'],
  ],
  angle: '45deg',
};

const firstHomeGradient: LinearGradientConfig = {
  alpha: 1,
  colorStops: [
    ['indigo.100', '0%'],
    ['indigo.090', '113.19%'],
  ],
  angle: '31deg',
};

const desktopImageForVariant = (
  variant: HeroPanelVariant,
  image?: string,
): string => {
  switch (variant) {
    case 'voyager': {
      return heroDesktopImage;
    }
    case 'boosts': {
      return boostsHeroDesktopImage;
    }
    case 'fhss': {
      return fhssDesktopImage;
    }
    case 'nova': {
      return novaHeroDesktopImage;
    }
    case 'first-home': {
      return firstHomeDesktopImage;
    }
    case 'first-home-state': {
      return image ?? '';
    }
  }
};

const HeroPanelContainer = styled.div<{
  variant: HeroPanelVariant;
  heroDesktopImage?: string;
}>`
  ${paddingBottom({ lg: 'xl' })}

  position: relative;

  ${({ variant }) => {
    switch (variant) {
      case 'voyager': {
        return css`
          background: ${linearGradient(voyagerGradient)};

          ${match('md')`
            background:
              ${linearGradient({
                ...voyagerGradient,
                angle: '30.69deg',
              })};
          `}

          ${match('lg')`
            background:
              ${linearGradient({
                ...voyagerGradient,
                angle: '22.62deg',
              })};
          `}
        `;
      }

      case 'first-home-state':
      case 'boosts': {
        return css`
          ${backgroundColor('neutral.015')};
        `;
      }

      case 'fhss': {
        return css`
          ${backgroundColor('indigo.100')};
        `;
      }

      case 'nova': {
        return css`
          background: ${linearGradient(novaGradient)};

          ${match('md')`
            background:
              ${linearGradient({
                ...novaGradient,
                angle: '30.69deg',
              })};
          `}

          ${match('lg')`
            background:
              ${linearGradient({
                ...novaGradient,
                angle: '22.62deg',
              })};
          `}
        `;
      }

      case 'first-home': {
        return css`
          background: ${linearGradient(firstHomeGradient)};
        `;
      }
    }
  }}

  ::before {
    background: url(${(props) =>
        desktopImageForVariant(props.variant, props.heroDesktopImage)})
      no-repeat;
    background-position: center bottom;
    background-size: contain;
    content: '';
    display: none;
    position: absolute;
    bottom: 0;

    ${({ variant }) => {
      switch (variant) {
        case 'voyager':
        case 'boosts':
          return css`
            ${match('md')`
              ${height({ md: 450, lg: 600, xl: 668 })}
              ${width({ md: 380, lg: 507, xl: 564 })}
              display: block;
              left: 66%;
            `}

            ${match('xl')`
              left: calc(50% + ${getSpace('lg')});
            `}
          `;

        case 'fhss':
          return css`
            ${match('md')`
              ${height({ md: 462, lg: 601, xl: 654 })}
              ${width({ md: 380, lg: 494, xl: 537 })}
              display: block;
              left: 66%;
            `}

            ${match('xl')`
              left: calc(50% + ${getSpace('lg')});
            `}
          `;

        case 'nova':
          return css`
            ${match('md')`
              ${height({ md: 462, lg: 601, xl: 668 })}
              ${width({ md: 458, lg: 596, xl: 662 })}
              display: block;
              left: 61%;
            `}

            ${match('xl')`
              left: 50%;
            `}
          `;

        case 'first-home':
          return css`
            ${match('md')`
              ${height({ md: 436, lg: 524, xl: 659 })}
              ${width({ md: 1000, lg: 1200, xl: 1509 })}
              ${left({
                md: 'calc(50% - 620px)',
                lg: 'calc(50% - 780px)',
                xl: 'calc(50% - 880px)',
              })}
              display: block;
            `}
          `;

        case 'first-home-state':
          return css`
            ${height({ md: 300, lg: 400, xl: 521 })}
            aspect-ratio: 1087 / 521;

            ${match('md')`
              display: block;
              left: 66%;
            `}

            ${match('xl')`
              left: 51%;
            `}
          `;
      }
    }}
  }
`;

const MobileHeroImage = styled.img`
  ${maxWidth(350)};
  ${maxHeight(350)};
  width: 100%;
  height: auto;
`;

const StyledTag = styled.span`
  ${text({ variant: 3 })}
  ${fontWeight(700)}
  ${color('indigo.100')}
  ${paddingX('xxs')}
  ${backgroundColor('blue.030')}
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
  height: 28px;
  border-radius: 14px;
  letter-spacing: 0.01em;
  margin-bottom: 16px;
`;

const StyledProductIconBox = styled(Box).attrs({
  height: 96,
  width: 96,
  borderRadius: 'xl',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})``;

const StyledStocksProductIconBox = styled(StyledProductIconBox)`
  margin-left: -${getSpace('lg')};
  margin-top: ${getSpace('lg')};
`;

export interface HeroPanelProps {
  headerComponent: React.FC<HeaderProps>;
  title: string;
  tagline: React.ReactNode;
  product: string;
  variant?: HeroPanelVariant;
  heroDesktopImage?: string;
  heroMobileImage?: string;
  tag?: string | null;
  footnote?: React.ReactNode;
  signupText?: string;
  signupUrl?: string;
  signupUrlTarget?: HTMLAttributeAnchorTarget;
}

export const HeroPanel: React.FC<React.PropsWithChildren<HeroPanelProps>> = ({
  headerComponent: HeaderComponent,
  title,
  tagline,
  product,
  heroDesktopImage,
  heroMobileImage,
  variant = 'voyager',
  tag,
  footnote,
  signupText,
  signupUrl,
  signupUrlTarget,
}) => {
  const signupHref =
    signupUrl ??
    (variant === 'fhss'
      ? ExternalRoutes.SUPER_SIGNUP
      : ExternalRoutes.VOYAGER_SIGNUP);
  const isDarkText = ['boosts', 'first-home-state'].includes(variant);

  return (
    <TrackingCategory category="Hero Panel">
      <HeroPanelContainer variant={variant} heroDesktopImage={heroDesktopImage}>
        <HeaderComponent
          buttonVariant={
            ['fhss', 'first-home'].includes(variant) ? 'primary' : 'secondary'
          }
        />

        <Box
          paddingBottom={{
            xs: variant === 'boosts' ? 'none' : 'xl',
            md: footnote ? 'lg' : 'xxl',
            lg: footnote ? 'lg' : 'xxxl',
          }}
          paddingTop={{ xs: 'lg', md: 'xl', lg: 'xxl' }}
          position="relative"
        >
          <Container>
            <Columns alignX={variant === 'first-home' ? 'right' : 'left'}>
              <Columns.Column width={{ xs: 1, md: 2 / 3, xl: 1 / 2 }}>
                {(() => {
                  switch (variant) {
                    case 'voyager':
                      return (
                        <Visible
                          isHidden={{ xs: false, md: true }}
                          displayValue="block"
                        >
                          <Box
                            marginBottom="md"
                            display="flex"
                            justifyContent="center"
                          >
                            <VoyagerProductStarsIcon
                              size="xxxl"
                              color="neutral.000"
                            />
                          </Box>
                        </Visible>
                      );

                    case 'fhss':
                      return (
                        <Visible
                          isHidden={{ xs: false, md: true }}
                          displayValue="block"
                        >
                          <Box
                            marginBottom="md"
                            display="flex"
                            justifyContent="center"
                          >
                            <SuperFHSSIcon size={96} />
                          </Box>
                        </Visible>
                      );

                    case 'nova':
                      return (
                        <Visible
                          isHidden={{ xs: false, md: true }}
                          displayValue="block"
                        >
                          <Box
                            display="flex"
                            marginBottom="md"
                            justifyContent="center"
                          >
                            <StyledProductIconBox backgroundColor="product.etf">
                              <EtfProductIcon size="xxl" color="neutral.000" />
                            </StyledProductIconBox>
                            <StyledStocksProductIconBox backgroundColor="product.stocks">
                              <StocksProductIcon
                                size="xxl"
                                color="neutral.000"
                              />
                            </StyledStocksProductIconBox>
                          </Box>
                        </Visible>
                      );

                    case 'first-home':
                      return (
                        <Visible
                          isHidden={{ xs: false, md: true }}
                          displayValue="block"
                        >
                          <Box
                            marginTop="md"
                            display="flex"
                            justifyContent="center"
                          >
                            <img
                              src={firstHomeMobileImage}
                              alt="First home super saver"
                            />
                          </Box>
                        </Visible>
                      );

                    case 'first-home-state':
                      return (
                        <Visible
                          isHidden={{ xs: false, md: true }}
                          displayValue="block"
                        >
                          <Box
                            height={160}
                            marginBottom="md"
                            display="flex"
                            justifyContent="center"
                          >
                            <img
                              src={heroMobileImage}
                              alt="First home super saver"
                            />
                          </Box>
                        </Visible>
                      );

                    default:
                      return null;
                  }
                })()}

                <Stack spaceY="lg" alignX={{ xs: 'center', md: 'left' }}>
                  <Stack spaceY="md" alignX={{ xs: 'center', md: 'left' }}>
                    <Text
                      variant={2}
                      color={isDarkText ? 'neutral.100' : 'neutral.000'}
                      align={{ xs: 'center', md: 'left' }}
                    >
                      {product}
                    </Text>

                    <Heading
                      variant={1}
                      color={isDarkText ? 'neutral.100' : 'neutral.000'}
                      align={{ xs: 'center', md: 'left' }}
                      component="h1"
                    >
                      <Box
                        display="inline"
                        marginRight={tag ? { md: 'sm', lg: 'md' } : 'none'}
                      >
                        {title}
                      </Box>
                      {!!tag && (
                        <Visible isHidden={{ xs: true, md: false }}>
                          <StyledTag>{tag}</StyledTag>
                        </Visible>
                      )}
                    </Heading>

                    <Text
                      component="div"
                      variant={1}
                      color={isDarkText ? 'neutral.100' : 'neutral.000'}
                      align={{ xs: 'center', md: 'left' }}
                    >
                      {tagline}
                    </Text>
                  </Stack>

                  <Stack spaceY="lg" alignX={{ xs: 'center', md: 'left' }}>
                    {!!signupText && (
                      <Button
                        href={signupHref}
                        target={
                          signupUrl && signupUrlTarget
                            ? signupUrlTarget
                            : undefined
                        }
                        size="lg"
                        variant={variant === 'fhss' ? 'primary' : 'secondary'}
                      >
                        {signupText}
                      </Button>
                    )}

                    {!!footnote && (
                      <Box
                        marginTop={
                          !signupText ? { lg: 'sm', xl: 'xxl' } : 'none'
                        }
                      >
                        <Text
                          variant={4}
                          component="div"
                          color={isDarkText ? 'neutral.100' : 'neutral.000'}
                          align={{ xs: 'center', md: 'left' }}
                        >
                          {footnote}
                        </Text>
                      </Box>
                    )}

                    {variant === 'boosts' && (
                      <Visible isHidden={{ md: true }} displayValue="block">
                        <Box
                          display="flex"
                          alignItems="flex-end"
                          flexShrink={1}
                        >
                          <MobileHeroImage src={boostsHeroMobileImage} />
                        </Box>
                      </Visible>
                    )}
                  </Stack>
                </Stack>
              </Columns.Column>
            </Columns>
          </Container>
        </Box>
      </HeroPanelContainer>
    </TrackingCategory>
  );
};
