import { Header, HeaderProps } from 'components/header';
import { StickyHeader } from 'components/sticky-header';
import React from 'react';

import { SignUpPanel } from '../../sign-up-panel';
import {
  getPageLayoutStaticProps,
  PageLayout,
  PageLayoutProps,
} from '../page-layout';

export interface ContentPageLayoutProps extends PageLayoutProps {
  isStickyHeader?: boolean;
  headerProps?: HeaderProps;
  showSignUpPanel?: boolean;
}

export const ContentPageLayout: React.FC<
  React.PropsWithChildren<ContentPageLayoutProps>
> = ({
  children,
  headerProps,
  isStickyHeader = true,
  showSignUpPanel = true,
  ...otherProps
}) => {
  return (
    <PageLayout {...otherProps}>
      {isStickyHeader ? (
        <StickyHeader {...headerProps} />
      ) : (
        <Header {...headerProps} />
      )}
      {children}
      {showSignUpPanel && (
        <SignUpPanel
          title="Invest in your future, so you can live the life you want to live"
          tagline="Get started in five minutes."
        />
      )}
    </PageLayout>
  );
};

interface WithContentPageLayoutProps {
  layout: ContentPageLayoutProps;
}

export const getContentPageLayoutStaticProps = getPageLayoutStaticProps;

export function withContentPageLayout() {
  return <ComponentProps,>(
    Component: React.ComponentType<React.PropsWithChildren<ComponentProps>>,
  ) => {
    return function WithContentPageLayout(
      props: ComponentProps & WithContentPageLayoutProps,
    ): React.ReactElement {
      return (
        <ContentPageLayout {...props.layout}>
          <Component {...props} />
        </ContentPageLayout>
      );
    };
  };
}
