import {
  Box,
  Container,
  IconButton,
  Logo,
  Text,
  UnstyledLink,
  Visible,
} from '@spaceship-fspl/components';
import { useScrollPosition } from '@spaceship-fspl/helpers';
import { FeatherArrowUpIcon } from '@spaceship-fspl/icons-web';
import { marginY, zIndex } from '@spaceship-fspl/styles';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import {
  PROGRESS_BAR_HEIGHT,
  ReadingProgressBar,
} from '../reading-progress-bar';

const NAV_HEADER_HEIGHT = 161;
export const STICKY_ARTICLE_HEADER_HEIGHT = 96;

const StickyHeader = styled.div<{ isVisible: boolean }>`
  position: fixed;
  width: 100%;
  background-color: white;
  text-align: center;
  ${zIndex('max')}
  pointer-events: none;
  max-height: ${STICKY_ARTICLE_HEADER_HEIGHT + PROGRESS_BAR_HEIGHT}px;
  top: -${STICKY_ARTICLE_HEADER_HEIGHT + PROGRESS_BAR_HEIGHT}px;
  transition: all 500ms ease;

  ${({ isVisible }) =>
    isVisible &&
    css`
      top: 0;
      pointer-events: auto;
      transition: top 500ms ease;
    `}
`;

export const StyledContainer = styled(Container)`
  ${marginY('md')}
`;

export const StickyArticleHeader: React.FC<
  React.PropsWithChildren<{
    progressBarTarget: React.RefObject<HTMLDivElement> | null;
  }>
> = ({ children, progressBarTarget }) => {
  const [isVisible, setIsVisible] = useState(false);

  useScrollPosition(
    ({ curr }) => {
      if (curr.y > NAV_HEADER_HEIGHT && !isVisible) {
        setIsVisible(true);
      } else if (curr.y <= NAV_HEADER_HEIGHT && isVisible) {
        setIsVisible(false);
      }
    },
    [isVisible],
  );

  const onScrollToTop = (): void => {
    window.scrollTo({ top: NAV_HEADER_HEIGHT, behavior: 'smooth' });
  };

  return (
    <StickyHeader isVisible={isVisible}>
      <StyledContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <UnstyledLink href="/">
            <Logo variant="dark" size="lg" />
          </UnstyledLink>
          <Box flex={1} paddingX="sm">
            <Visible isHidden={{ xs: true, md: false }}>
              <Text
                variant={{ xs: 2, lg: 1 }}
                align={{ xs: 'left', lg: 'center' }}
                color="neutral.100"
              >
                {children}
              </Text>
            </Visible>
          </Box>
          <IconButton
            variant="dark"
            size="lg"
            icon={FeatherArrowUpIcon}
            onClick={onScrollToTop}
          />
        </Box>
      </StyledContainer>
      <ReadingProgressBar target={progressBarTarget} />
    </StickyHeader>
  );
};
