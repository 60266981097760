import { ExternalRoutes } from '@spaceship-fspl/helpers';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  RssIcon,
  TwitterIcon,
} from '@spaceship-fspl/icons-web';
import { Space } from '@spaceship-fspl/styles';
import React from 'react';

import { Box } from '../box';
import { IconButton, IconButtonProps, IconButtonSize } from '../buttons';
import { Inline, Stack } from '../layout';

export type SocialLinksVariant = 'light' | 'dark';
export type SocialLinksSize = IconButtonSize;
export type SocialLinksOrientation = 'horizontal' | 'vertical';
export type SocialLinksSpace = Space | 'between';

export interface SocialLinksProps {
  variant: SocialLinksVariant;
  size: SocialLinksSize;
  orientation: SocialLinksOrientation;
  space: SocialLinksSpace;
  sharePostUrl?: string;
  shouldShowRSS?: boolean;
}

export const SocialLinks: React.FC<
  React.PropsWithChildren<SocialLinksProps>
> = ({ sharePostUrl, variant, size, orientation, space, shouldShowRSS }) => {
  const iconButtonProps: Omit<IconButtonProps, 'icon'> = {
    size,
    variant,
    rel: 'noopener',
    target: '_blank',
  };
  const titlePrefix = sharePostUrl ? 'Share on ' : 'Follow us on ';
  const links = (
    <>
      <IconButton
        {...iconButtonProps}
        icon={FacebookIcon}
        href={
          sharePostUrl
            ? `${ExternalRoutes.SOCIAL_FACEBOOK_SHARE}${sharePostUrl}`
            : ExternalRoutes.SOCIAL_FACEBOOK
        }
        title={`${titlePrefix}Facebook`}
      />
      <IconButton
        {...iconButtonProps}
        icon={TwitterIcon}
        href={
          sharePostUrl
            ? `${ExternalRoutes.SOCIAL_TWITTER_SHARE}${sharePostUrl}`
            : ExternalRoutes.SOCIAL_TWITTER
        }
        title={`${titlePrefix}Twitter`}
      />
      <IconButton
        {...iconButtonProps}
        icon={LinkedinIcon}
        href={
          sharePostUrl
            ? `${ExternalRoutes.SOCIAL_LINKEDIN_SHARE}${sharePostUrl}`
            : ExternalRoutes.SOCIAL_LINKEDIN
        }
        title={`${titlePrefix}Linkedin`}
      />
      <IconButton
        {...iconButtonProps}
        icon={InstagramIcon}
        href={ExternalRoutes.SOCIAL_INSTAGRAM}
        title="Follow us on Instagram"
      />
      {shouldShowRSS && (
        <IconButton
          {...iconButtonProps}
          icon={RssIcon}
          href={ExternalRoutes.SOCIAL_RSS_FEED}
          title="Follow our RSS feed"
        />
      )}
    </>
  );

  if (space === 'between') {
    return (
      <Box
        display="flex"
        flexDirection={orientation === 'horizontal' ? 'row' : 'column'}
        justifyContent="space-between"
      >
        {links}
      </Box>
    );
  }

  if (orientation === 'horizontal') {
    return <Inline spaceX={space}>{links}</Inline>;
  }

  return <Stack spaceY={space}>{links}</Stack>;
};
