import {
  Box,
  Columns,
  Heading,
  PresentationLink,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import { fontSize, fontWeight, lineHeight } from '@spaceship-fspl/styles';
import {
  productIcons,
  SuperPrismicPortfolio,
  VoyagerPrismicPortfolio,
} from 'helpers/products';
import Link from 'next/link';
import * as React from 'react';
import styled from 'styled-components';

import { Card, CardShadowPosition } from '../../card';

interface PortfolioCardProps {
  variant: 'voyager' | 'super';
  index?: number;
  icon?: React.ReactNode;
  title: string;
  subtitle: string;
  description: string;
  productSlug: string;
  type: VoyagerPrismicPortfolio | SuperPrismicPortfolio;
  numOfPortfolios: number;
}

const PortfolioTitle = styled(Heading)`
  ${fontSize({ md: '28px', lg: '32px' })}
  ${lineHeight({ md: '40px', lg: '46px' })}
`;

const PortfolioDescription = styled(Text)`
  ${fontSize({ md: '20px', lg: '24px' })}
  ${fontWeight({ md: 500 })}
  ${lineHeight({ md: '32px', lg: '38px' })}
`;

export const PortfolioCard: React.FC<
  React.PropsWithChildren<PortfolioCardProps>
> = ({
  variant,
  index = 0,
  title,
  subtitle,
  description,
  productSlug,
  type,
  numOfPortfolios = 1,
}) => {
  const isVoyager = variant === 'voyager';
  const PortfolioIcon = productIcons[type];

  if (numOfPortfolios > 2) {
    return (
      <Card variant="light" padding={{ xs: 'md', md: 'lg', lg: 'xl' }}>
        <Columns
          spaceY={{ xs: 'sm', md: 'none', lg: 'md' }}
          spaceX={{ xs: 'none', md: 'md', lg: 'none' }}
        >
          <Columns.Column width={{ xs: 1, md: 1 / 5, lg: 1 }}>
            <Box display="flex" justifyContent={{ xs: 'center', md: 'left' }}>
              <PortfolioIcon
                color="indigo.070"
                size={{
                  xs: 'xxl',
                  md: 'xxxl',
                }}
              />
            </Box>
          </Columns.Column>

          <Columns.Column width={{ xs: 1, md: 4 / 5, lg: 1 }}>
            <Stack
              spaceY={{ xs: 'sm', md: 'xs', lg: 'lg' }}
              alignX={{ xs: 'center', md: 'left' }}
            >
              <Stack spaceY={{ xs: 'sm', md: 'xxs', lg: 'sm' }}>
                <PortfolioTitle
                  align={{ xs: 'center', md: 'left' }}
                  variant={4}
                >
                  {title}
                </PortfolioTitle>
                <Box
                  minHeight={{
                    xs: 150,
                    md: 0,
                    lg: 300,
                  }}
                >
                  <PortfolioDescription
                    variant={2}
                    color="neutral.085"
                    align={{ xs: 'center', md: 'left' }}
                  >
                    {description}
                  </PortfolioDescription>
                </Box>
              </Stack>
              <Link
                href={`/${productSlug}/[portfolio]`}
                as={`/${productSlug}/${type.replace(/_/g, '-')}`}
                passHref={true}
                legacyBehavior
              >
                <PresentationLink size="md" icon="arrow" color="indigo.070">
                  Learn more
                </PresentationLink>
              </Link>
            </Stack>
          </Columns.Column>
        </Columns>
      </Card>
    );
  }

  return (
    <Card
      variant="light"
      padding={{ xs: 'md', md: 'lg', lg: 'xl' }}
      shadow={
        isVoyager
          ? {
              position: {
                xs: undefined,
                md:
                  index === 0
                    ? CardShadowPosition.topLeft
                    : CardShadowPosition.bottomRight,
              },
              color: 'indigo.020',
            }
          : undefined
      }
    >
      <Stack
        spaceY={{ xs: 'sm', lg: 'md' }}
        alignX={{
          xs: isVoyager ? 'center' : 'left',
          md: 'left',
        }}
      >
        <Stack spaceY={{ xs: 'xs', md: 'sm', lg: 'md' }}>
          <Stack
            spaceY="xxs"
            alignX={{
              xs: isVoyager ? 'center' : 'left',
              md: 'left',
            }}
          >
            <PortfolioIcon
              color="indigo.070"
              size={{
                xs: 'xxxl',
                lg: isVoyager ? 'xxxxl' : 'xxxl',
              }}
            />
            <PortfolioTitle
              align={{ xs: isVoyager ? 'center' : 'left', md: 'left' }}
              variant={4}
            >
              {title}
            </PortfolioTitle>
            {subtitle && (
              <Text
                variant={2}
                align={{ xs: isVoyager ? 'center' : 'left', md: 'left' }}
              >
                {subtitle}
              </Text>
            )}
          </Stack>
          <PortfolioDescription
            variant={2}
            color="neutral.085"
            align={{ xs: isVoyager ? 'center' : 'left', md: 'left' }}
          >
            {description}
          </PortfolioDescription>
        </Stack>

        <Link
          href={`/${productSlug}/[portfolio]`}
          as={`/${productSlug}/${type.replace(/_/g, '-')}`}
          passHref={true}
          legacyBehavior
        >
          <PresentationLink size="md" icon="arrow" color="indigo.070">
            Learn more
          </PresentationLink>
        </Link>
      </Stack>
    </Card>
  );
};
