import {
  backgroundColor,
  borderRadius,
  Color,
  linearGradient,
  map,
  PaddingProps,
  paddingProps,
  ResponsiveValue,
  zIndex,
} from '@spaceship-fspl/styles';
import { createProps, createVariant } from '@spaceship-fspl/substance-style';
import * as React from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { dottedBackground } from '../dotted-circle';

export enum CardShadowPosition {
  top = 'top',
  bottom = 'bottom',
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

export interface CardShadowProps {
  position: ResponsiveValue<CardShadowPosition>;
  color?: Color;
}

export const shadowStyle: {
  [key in CardShadowPosition]: CSSObject;
} = {
  [CardShadowPosition.top]: {
    bottom: '1.375em',
    left: '2.5%',
    top: 'auto',
    right: 'auto',
    width: '95%',
  },
  [CardShadowPosition.topLeft]: {
    bottom: '1.375em',
    right: '1.5em',
    top: 'auto',
    left: 'auto',
    width: '100%',
  },
  [CardShadowPosition.topRight]: {
    bottom: '1.375em',
    left: '1.5em',
    top: 'auto',
    right: 'auto',
    width: '100%',
  },
  [CardShadowPosition.bottom]: {
    top: '1.375em',
    left: '2.5%',
    bottom: 'auto',
    right: 'auto',
    width: '95%',
  },
  [CardShadowPosition.bottomLeft]: {
    top: '1.375em',
    right: '1.5em',
    bottom: 'auto',
    left: 'auto',
    width: '100%',
  },
  [CardShadowPosition.bottomRight]: {
    top: '1.375em',
    left: '1.5em',
    bottom: 'auto',
    right: 'auto',
    width: '100%',
  },
};

export const cardPaddingStyle: {
  [key in CardShadowPosition]: CSSObject;
} = {
  [CardShadowPosition.top]: {
    paddingTop: shadowStyle[CardShadowPosition.top].bottom,
    paddingBottom: 0,
    paddingX: 0,
  },
  [CardShadowPosition.topLeft]: {
    paddingTop: shadowStyle[CardShadowPosition.topLeft].bottom,
    paddingLeft: shadowStyle[CardShadowPosition.topLeft].right,
    paddingBottom: 0,
    paddingRight: 0,
  },
  [CardShadowPosition.topRight]: {
    paddingTop: shadowStyle[CardShadowPosition.topRight].bottom,
    paddingRight: shadowStyle[CardShadowPosition.topRight].left,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  [CardShadowPosition.bottom]: {
    paddingBottom: shadowStyle[CardShadowPosition.bottom].top,
    paddingTop: 0,
    paddingX: 0,
  },
  [CardShadowPosition.bottomLeft]: {
    paddingBottom: shadowStyle[CardShadowPosition.bottomLeft].top,
    paddingLeft: shadowStyle[CardShadowPosition.bottomLeft].right,
    paddingTop: 0,
    paddingRight: 0,
  },
  [CardShadowPosition.bottomRight]: {
    paddingBottom: shadowStyle[CardShadowPosition.bottomRight].top,
    paddingRight: shadowStyle[CardShadowPosition.bottomRight].left,
    paddingTop: 0,
    paddingLeft: 0,
  },
};

const CardWrapper = styled.div<{
  includeShadowPadding: boolean;
  shadowPosition?: CardShadowProps['position'];
  fullHeight?: boolean;
  fullWidth?: boolean;
}>`
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      display: flex;
      height: 100%;
    `}

  ${({ includeShadowPadding, shadowPosition }) =>
    includeShadowPadding && shadowPosition
      ? map(
          shadowPosition,
          (index) => cardPaddingStyle[CardShadowPosition[index]],
        )
      : ''}
`;

export interface StyledCardProps extends PaddingProps {
  shadowColor?: CardShadowProps['color'];
  shadowPosition?: CardShadowProps['position'];
  fullWidth?: boolean;
  variant: CardVariant;
}

const variant = createVariant({
  light: css`
    ${backgroundColor('neutral.000')}
  `,
  indigo: css`
    ${backgroundColor('indigo.090')}
  `,
  dark: css`
    ${backgroundColor('indigo.100')}
  `,
  blueGradient: css`
    background: ${linearGradient({
      angle: '76.52deg',
      colorStops: [
        ['indigo.050', '-39.71%'],
        ['blue.050', '159.62%'],
      ],
    })};
  `,
  indigoGradient: css`
    background: ${linearGradient({
      angle: '31.9deg',
      colorStops: [
        ['indigo.050', '-2.95%'],
        ['red.020', '108.13%'],
      ],
    })};
  `,
  indigo100Gradient: css`
    background: ${linearGradient({
      angle: '45deg',
      colorStops: [
        ['indigo.100', '0%'],
        ['indigo.050', '100%'],
      ],
    })};
  `,
  neutral030: css`
    ${backgroundColor('neutral.030')}
  `,
  neutral050: css`
    ${backgroundColor('neutral.050')}
  `,
});

const StyledCard = styled.div<StyledCardProps>`
  ${borderRadius('sm')}
  ${zIndex(0)}
  position: relative;

  :after {
    ${borderRadius('sm')}
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    ${zIndex(-1)}
    background: inherit;
    background-color: inherit;
  }
  ${paddingProps}
  ${createProps({
    variant,
  })}
  ${({ shadowColor }) => {
    return css`
      :before {
        ${dottedBackground({ color: shadowColor || 'neutral.085' })}
      }
    `;
  }}
  ${({ shadowPosition }) =>
    shadowPosition
      ? css`
          :before {
            ${borderRadius('sm')}
            ${zIndex(-1)}
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            ${map(
              shadowPosition,
              (index) => shadowStyle[CardShadowPosition[index]],
            )}
        `
      : undefined}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export type CardVariant =
  | 'light'
  | 'indigo'
  | 'dark'
  | 'blueGradient'
  | 'indigoGradient'
  | 'indigo100Gradient'
  | 'neutral030'
  | 'neutral050';

export interface CardProps extends PaddingProps {
  variant: CardVariant;
  shadow?: CardShadowProps;
  className?: string;
  includeShadowPadding?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

export const Card: React.FC<React.PropsWithChildren<CardProps>> = ({
  children,
  className,
  includeShadowPadding = true,
  fullHeight,
  fullWidth,
  shadow,
  ...props
}) => {
  return (
    <CardWrapper
      includeShadowPadding={includeShadowPadding}
      shadowPosition={shadow?.position}
      fullHeight={fullHeight}
    >
      <StyledCard
        className={className}
        shadowColor={shadow?.color}
        shadowPosition={shadow?.position}
        fullWidth={fullWidth}
        {...props}
      >
        {children}
      </StyledCard>
    </CardWrapper>
  );
};
