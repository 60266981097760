import { Nullable } from '@tryghost/content-api';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

interface OpenGraphCommon {
  url: string;
  title: string;
  image: string;
  description?: string;
  siteName?: string;
}

interface OpenGraphArticle extends OpenGraphCommon {
  type: 'article';
  tag?: string | string[];
  author?: string | string[];
  publishedAt?: number;
  modifiedAt?: number;
}

interface OpenGraphProfile extends OpenGraphCommon {
  type: 'profile';
  username?: string;
  firstName?: string;
  lastName?: string;
}

interface OpenGraphWebsite extends OpenGraphCommon {
  type: 'website';
}

type OpenGraphMetaProps =
  | OpenGraphArticle
  | OpenGraphProfile
  | OpenGraphWebsite;

interface TwitterMetaProps {
  card: 'summary' | 'summary_large_image';
  title: string;
  description?: Nullable<string>;
  image?: Nullable<string>;
}

export interface MetadataProps {
  title: string;
  description?: string;
  opengraph?: Omit<OpenGraphMetaProps, 'url'>;
  twitter?: TwitterMetaProps;
  robots?: string;
}

function getURL(pathname: string): string {
  return `${process.env.WEBSITE_ORIGIN || ''}${process.env.WEBSITE_PATH || ''}${
    pathname.endsWith('/') ? pathname : `${pathname}/`
  }`;
}

function toArray<T>(val: T | T[]): T[] {
  if (Array.isArray(val)) {
    return val;
  } else {
    return [val];
  }
}

const OpenGraphMetadata: React.FC<
  React.PropsWithChildren<OpenGraphMetaProps>
> = (props) => {
  const common = (
    <>
      <meta property="og:type" content={props.type} />
      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={props.title} />
      <meta property="og:image" content={props.image} />
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}
      {props.siteName && (
        <meta property="og:site_name" content={props.siteName} />
      )}
    </>
  );
  switch (props.type) {
    case 'article':
      return (
        <>
          {common}
          {props.author &&
            toArray(props.author).map((author, index) => (
              <meta
                key={`author.${index}`}
                property="article:author"
                content={author}
              />
            ))}
          {props.tag &&
            toArray(props.tag).map((tag, index) => (
              <meta key={`tag.${index}`} property="article:tag" content={tag} />
            ))}
          {props.publishedAt && (
            <meta
              property="article:published_time"
              content={new Date(props.publishedAt).toISOString()}
            />
          )}
          {props.modifiedAt && (
            <meta
              property="article:modified_time"
              content={new Date(props.modifiedAt).toISOString()}
            />
          )}
        </>
      );
    case 'profile':
      return (
        <>
          {common}
          {props.username && (
            <meta property="profile:username" content={props.username} />
          )}
          {props.firstName && (
            <meta property="profile:first_name" content={props.firstName} />
          )}
          {props.lastName && (
            <meta property="profile:last_name" content={props.lastName} />
          )}
        </>
      );
    case 'website':
      return common;
  }
};

const TwitterMetadata: React.FC<React.PropsWithChildren<TwitterMetaProps>> = (
  props,
) => (
  <>
    {props.card && <meta property="twitter:card" content={props.card} />}
    {props.title && <meta property="twitter:title" content={props.title} />}
    {props.description && (
      <meta property="twitter:description" content={props.description} />
    )}
    {props.image && <meta property="twitter:image" content={props.image} />}
    <meta property="twitter:site" content="@spaceshipau" />
  </>
);

export const Metadata: React.FC<React.PropsWithChildren<MetadataProps>> = ({
  title,
  description,
  opengraph,
  twitter,
  robots,
}) => {
  const router = useRouter();
  const url = getURL(router.asPath.split('?')[0] ?? '');
  return (
    <Head>
      <title key="title">{title}</title>
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={url} />
      {opengraph && <OpenGraphMetadata url={url} {...opengraph} />}
      {twitter && <TwitterMetadata {...twitter} />}
      {robots && <meta name="robots" content={robots} />}
    </Head>
  );
};
