import { EmergencyMessage, Footer, Reset } from '@spaceship-fspl/components';
import { backgroundColor, minHeight } from '@spaceship-fspl/styles';
import {
  TrackingProvider,
  useIdentify,
  useTrack,
} from '@spaceship-fspl/tracking';
import { getEmergencyMessage, RichText } from 'data/prismic';
import { FeatureFlagKeys, useFeatureFlag } from 'data/spaceship/dynamic-config';
import { GetStaticProps, GetStaticPropsResult } from 'next';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Link } from '../../link';
import { usePageTracking } from '../use-page-tracking';

const GlobalStyle = createGlobalStyle`
  body {
    ${backgroundColor('neutral.100')}
  }
`;

const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  ${backgroundColor('neutral.000')}
  ${minHeight({ xs: 800, md: 660, lg: 680 })}
`;

export interface PageLayoutProps {
  emergencyMessage?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content?: any;
  };
}

export const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = ({
  emergencyMessage,
  children,
}) => {
  const track = useTrack();
  const identify = useIdentify();
  const isFirstHomeEnabled = useFeatureFlag(FeatureFlagKeys.FIRST_HOME_ENABLED);
  usePageTracking();

  return (
    <>
      <Reset />
      <GlobalStyle />
      <TrackingProvider identify={identify} track={track}>
        {emergencyMessage?.content && (
          <EmergencyMessage>
            <RichText render={emergencyMessage.content} />
          </EmergencyMessage>
        )}
        <Content>{children}</Content>
        <Footer LinkComponent={Link} isFirstHomeEnabled={isFirstHomeEnabled} />
      </TrackingProvider>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasProps<P>(value: any): value is { props: P } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value as any).props !== undefined;
}
interface WithPageLayoutProps {
  layout: PageLayoutProps;
}

export const getPageLayoutStaticProps = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends Record<string, any>,
  Q extends ParsedUrlQuery = ParsedUrlQuery,
>(
  fn: GetStaticProps<P, Q>,
): GetStaticProps<P & WithPageLayoutProps, Q> => {
  return async (context) => {
    const [result, emergencyMessage] = await Promise.all([
      await fn(context),
      await getEmergencyMessage(),
    ]);
    if (hasProps(result)) {
      const output: GetStaticPropsResult<P & WithPageLayoutProps> = {
        ...result,
        props: {
          ...result.props,
          layout: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(result.props as any).layout,
          },
        },
      };
      if (emergencyMessage) {
        output.props.layout.emergencyMessage = emergencyMessage;
      }
      return output;
    } else {
      return result;
    }
  };
};

export function withPageLayout() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ComponentProps extends Record<string, any>>(
    Component: React.ComponentType<React.PropsWithChildren<ComponentProps>>,
  ) => {
    return function WithPageLayout(
      props: ComponentProps & WithPageLayoutProps,
    ): React.ReactElement {
      return (
        <PageLayout {...props.layout}>
          <Component {...props} />
        </PageLayout>
      );
    };
  };
}
