import { EmergencyMessage, Reset } from '@spaceship-fspl/components';
import { RichText } from 'data/prismic';
import React from 'react';

import { SignUpPanel, SpaceshipProduct } from '../../sign-up-panel';
import {
  getPageLayoutStaticProps,
  PageLayout,
  PageLayoutProps,
} from '../page-layout';

interface HeroPageLayoutProps extends PageLayoutProps {
  signupProduct?: SpaceshipProduct;
  showSignUpPanel?: boolean;
}

export const HeroPageLayout: React.FC<
  React.PropsWithChildren<HeroPageLayoutProps>
> = ({
  emergencyMessage,
  signupProduct,
  showSignUpPanel = true,
  children,
  ...otherProps
}) => {
  return (
    <>
      <Reset />
      <PageLayout {...otherProps}>
        {emergencyMessage?.content && (
          <EmergencyMessage>
            <RichText render={emergencyMessage.content} />
          </EmergencyMessage>
        )}
        {children}
        {showSignUpPanel && (
          <SignUpPanel
            title="Invest in your future, so you can live the life you want to live"
            tagline="Get started in five minutes."
            product={signupProduct}
          />
        )}
      </PageLayout>
    </>
  );
};

interface WithHeroPageLayoutProps {
  layout: HeroPageLayoutProps;
}

export const getHeroPageLayoutStaticProps = getPageLayoutStaticProps;

export function withHeroPageLayout(
  options: {
    product?: SpaceshipProduct;
    showSignUpPanel?: boolean;
  } = {},
) {
  return <ComponentProps,>(
    Component: React.ComponentType<React.PropsWithChildren<ComponentProps>>,
  ) => {
    return function WithHeroPageLayout(
      props: ComponentProps & WithHeroPageLayoutProps,
    ): JSX.Element {
      return (
        <HeroPageLayout
          {...props.layout}
          signupProduct={options.product}
          showSignUpPanel={options.showSignUpPanel}
        >
          <Component {...props} />
        </HeroPageLayout>
      );
    };
  };
}
